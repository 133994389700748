import { Component, Input, OnInit } from '@angular/core';
import { AttachmentType } from './model/attachmentType';
import { PreregApplicationService } from './service/prereg.service';

@Component({
  selector: 'app-final-declaration-application',
  templateUrl: './finalDeclarationApplication.component.html',
  styleUrls: ['./application.scss']
})
export class FinalDeclarationApplicationComponent implements OnInit {
  @Input() application;
  attachmentType = AttachmentType;
  grade: number;
  name: string;
  preregNumber: number;
  submittedAt: any;
  progressReport: any;
  countersignerComment: any;
  confirmedTempRegistration: boolean;
  tutorRegNumber;
  constructor(
    private service: PreregApplicationService
  ) { }

  ngOnInit() {
    if(this.application && this.application.progressReport) {
      this.grade = this.application.grade;
      this.name = this.application.name;
      this.preregNumber = this.application.preregNumber;
      this.tutorRegNumber = this.application.progressReport.registrationNumber;
      this.submittedAt = this.application.submittedDate;
      this.progressReport = this.application.progressReport;
      this.confirmedTempRegistration = this.application.confirmedTempRegistration;
      this.countersignerComment = this.progressReport.countersignerComment;
    }
  }

}

