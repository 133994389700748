
import {fromEvent as observableFromEvent,  Observable } from 'rxjs';

import {debounceTime} from 'rxjs/operators';
import { Component, Input, Output, EventEmitter,
  OnChanges, OnInit, AfterViewInit, ElementRef, NgZone } from '@angular/core';
import { LogService } from '../core/service/log.service';
import isEqual from 'lodash.isequal';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['carousel.component.scss']
})
/*
  *
  * @param() items - List of items to belong in carousel
  * @param() width - Size of window(view) to show
  * @param() $prev - Template for previous button
  * @param() $next - Template for next button
  * @param() $item - Template for the item
*/
export class CarouselComponent implements OnChanges, OnInit, AfterViewInit {
  @Input() items = [];
  @Input() margin = 1;
  @Input() $prev;
  @Input() $next;
  @Input() $item;
  @Output() onSelectedItem: EventEmitter<any> = new EventEmitter();
  @Output() onScrollStart: EventEmitter<any> = new EventEmitter();
  @Output() onScrollEnd: EventEmitter<any> = new EventEmitter();

  @Input() set selectedIndex(value) {
    if (value === this.childIndex) { return; }
    const diff = value - this.childIndex;
    for (let i = 0; i < Math.abs(diff); i++) {
      this.scroll(diff > 0);
    }
  }
  @Input() set layoutChange(value) {
    // this.setWidth();
  }

  childIndex = 0;
  amount = 0;
  startPress = 0;
  lastX = 0;
  container: any;
  width;

  slideEnabled = false;

  constructor(private zone: NgZone, private elementRef: ElementRef, private log: LogService) { }

  ngOnInit() {

  }

  ngAfterViewInit() {

    const element = this.elementRef.nativeElement;
    this.container = element.parentNode;
    this.setWidth();

    observableFromEvent(window, 'resize').pipe(
      debounceTime(100))
      .subscribe(this.setWidth.bind(this));

  }

  setWidth() {
    this.log.info('this.container', this.container);
    if (!this.container) { return; }

    setTimeout(() => {

      const width = this.getBoundingClientRectValue(this.container, 'width');
      this.log.info('this.container width', width);
      /*
      const style = this.container.currentStyle || window.getComputedStyle(this.container);
      this.log.info('this.container style', style);
      if (style && style.marginLeft) {
        this.width = width + (parseFloat(style.marginLeft) + parseFloat(style.marginRight) +
        - parseFloat(style.paddingLeft) - parseFloat(style.paddingRight));

      } else {
        this.width = width;
      }*/
      this.width = width;
      this.log.info('this.width', this.width);

      this.snap();

    }, 10);

  }

  private getBoundingClientRectValue(element: any, property: string): number {
    let result = 0;
    if (element.getBoundingClientRect) {
      const rect = element.getBoundingClientRect();
      result = (typeof rect[property] !== 'undefined') ? rect[property] : 0;
    }
    return result;
  }

  onMousedown(e: MouseEvent) {
    if (!this.slideEnabled) { return; }
    if (e.which === 1) {
      this.startPress = e.clientX;
      this.lastX = this.amount;
    }
  }
  onTouchdown(e: TouchEvent) {
    if (!this.slideEnabled) { return; }
    if (navigator.userAgent.indexOf('Android') >= 0) { e.preventDefault(); }
    this.startPress = e.targetTouches[0].clientX;
    this.lastX = this.amount;
  }

  onMousemove(e: MouseEvent, maxWidth: number) {
    if (!this.slideEnabled) { return; }
    if (e.which === 1) {
      const amount = this.lastX - (this.startPress - e.clientX);
      if (amount > 0 || amount < -(maxWidth - this.width)) { return; }
      this.amount = amount;
    }
  }
  onTouchmove(e: TouchEvent, maxWidth: number) {
    if (!this.slideEnabled) { return; }
    if (navigator.userAgent.indexOf('Android') >= 0) { e.preventDefault() };
    const amount = this.lastX - (this.startPress - e.targetTouches[0].clientX);
    if (amount > 0 || amount < -(maxWidth - this.width)) { return };
    this.amount = amount;
  }

  onMouseup(e: MouseEvent, elem) {
    if (!this.slideEnabled) { return; }
    if (e.which === 1) {
      this.startPress = 0;
      this.snap();
    }
  }

  onTouchup(e: TouchEvent, elem) {
    if (!this.slideEnabled) { return; }
    if (navigator.userAgent.indexOf('Android') >= 0) { e.preventDefault(); }
    this.startPress = 0;
    this.snap();
  }

  snap() {
    let counter = 0;
    let lastVal = 0;
    for (let i = 0; i < this.items.length; i++) {
      /*
      const el = elem.children[i];
      const style = el.currentStyle || window.getComputedStyle(el);
      counter += el.offsetWidth + (parseFloat(style.marginLeft) + parseFloat(style.marginRight));
      */
      counter += this.width;
      if (this.amount <= lastVal && this.amount >= -counter) {
        this.amount = -lastVal;
        this.childIndex = i;
        this.onSelectedItem.emit({ item: this.items[this.childIndex], index: this.childIndex });
        return;
      }
      lastVal = counter;
    }
    return counter;
  }

  scroll(forward) {
    this.onScrollStart.emit();
    setTimeout(() => {
      this.onScrollEnd.emit();
    }, 1000)
    this.childIndex += forward ? 1 : -1;
    this.onSelectedItem.emit({
      item: this.items[this.childIndex],
      index: this.childIndex
    });
    this.amount = -(this.calcScroll());
  }

  calcScroll() {
    return this.width * this.childIndex;
    /*
        let counter = 0;
        for (let i = this.childIndex - 1; i >= 0; i--) {
          const el = elem.children[i];
          const style = el.currentStyle || window.getComputedStyle(el);
          counter += el.offsetWidth + (parseFloat(style.marginLeft) + parseFloat(style.marginRight));
        }
        counter = this.width * this.childIndex;
        return counter;
        */
  }

  ngOnChanges(changes) {
    if (changes.items && !isEqual(changes.items.previousValue, changes.items.currentValue)) {
      this.amount = 0;
    }
  }


}
