// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host.responsive > svg {
           position: absolute;
           width: 100%;
           height: 100%;
           top: 0;
           left: 0;
         }`, "",{"version":3,"sources":["webpack://./src/app/shared/round-progress/round-progress.component.ts"],"names":[],"mappings":"AAAA;WACW,kBAAkB;WAClB,WAAW;WACX,YAAY;WACZ,MAAM;WACN,OAAO;SACT","sourcesContent":[":host.responsive > svg {\n           position: absolute;\n           width: 100%;\n           height: 100%;\n           top: 0;\n           left: 0;\n         }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
