import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtcDatePipe } from '../../shared/pipe/UtcDate.pipe';
import { LayoutService } from '../../core/service/layout.service';
import { ExtCircService } from '../service/extCirc.service';
import { RegistrantStatus } from '../../accounts/model/RegistrantStatus';
import { RevalidationStatus } from '../model/RevalidationStatus';


@Component({
  selector: 'app-extCirc-granted-revalidations',
  templateUrl: './extCirc-Granted-Revalidations.component.html',
  styleUrls: ['./extCirc-Granted-Revalidations.component.scss']
})
export class ExtCircGrantedRevalidationsComponent {

  loading = false;
  searchVisible = false;
  regNo;
  selectedDate;
  heading;
  subHeading;
  displayRevalidationRecords = true;
  items = [];
  revalidations;
  revalidationDetails;
  registrantName;
  expectation;
  revalidationSelected;
  regNumber: any;
  RegistrationStatus = RegistrantStatus;
  RevalidationStatus = RevalidationStatus;
  error;

  searchCriteria = {
    regNumber: '',
    renewalDate: undefined,
    status: undefined
  };
  tempItems: any;
  RevalStatus: any;
  isSearchClicked = false;

  constructor(
    public layout: LayoutService,
    private route: ActivatedRoute,
    private service: ExtCircService,
    private utcPipe: UtcDatePipe
  ) { }

  ngOnInit(): void {
    this.heading = 'Revalidation extensions';
    this.searchCriteria.status = '';
  }

  toggleSearch() {
    this.searchVisible = !this.searchVisible;
  }

  search() {
    if (this.searchCriteria.renewalDate == null) {
      return;
    }
    this.loading = true;
    this.isSearchClicked = true;
    this.searchCriteria.status = '';
    const payload = {
      renewalDate: this.searchCriteria.renewalDate
    }
    this.service.notSubmittedRevalidation(payload).subscribe((revalidationRecords: any) => {
      this.tempItems = revalidationRecords.records;
      this.tempItems.forEach(a => a.renewalDate = a.renewalDate);
      this.items = this.tempItems;
      this.loading = false;
    }, error => {
      this.loading = false;
    });

  }

  clearSearch() {
    this.searchCriteria.regNumber = '';
    this.searchCriteria.renewalDate = '';
    this.searchCriteria.status = '';
    this.items = [];
    this.isSearchClicked = false;
  }

  dateChange(date) {
    this.utcPipe.transform(date);
  }

  filter(status: RevalidationStatus) {
    this.searchCriteria.status = status;
    this.items = this.tempItems?.filter((item) => {
      this.RevalStatus = RevalidationStatus[item.revalidationStage];
      return this.RevalStatus == status;
    });
  }

}
