// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #e5f1eb;*/
.collapsible.padded {
  padding: 40px;
  margin: -40px;
}

.over {
  position: absolute;
  width: 100%;
  z-index: 4;
}

.overlay-blue {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(62, 94, 133, 0.7);
  z-index: 3;
}

:host-context.help-block .collapsible {
  padding-left: 20px;
  padding-right: 20px;
  background: #00759b;
  font-size: 1.2em;
  color: white;
}
:host-context.help-block .collapsible ::ng-deep .help-text {
  padding: 25px 15px 20px 10px;
  color: white !important;
}
@media (max-width: 767px) {
  :host-context.help-block .collapsible {
    padding-left: 10px;
    padding-right: 10px;
    margin-left: -20px;
    border-radius: 0;
  }
}
:host-context.help-block .collapsible.open {
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/style/variables.scss","webpack://./src/app/shared/collapsible.scss"],"names":[],"mappings":"AAG0B,YAAA;ACD1B;EACI,aAAA;EACA,aAAA;AAAJ;;AAIA;EACE,kBAAA;EACA,WAAA;EACA,UAAA;AADF;;AAGA;EACE,kBAAA;EACA,MAAA;EAAQ,OAAA;EAAS,QAAA;EAAU,SAAA;EAC3B,wCAAA;EACA,UAAA;AAGF;;AAEE;EAEE,kBAAA;EACA,mBAAA;EACA,mBD1BQ;EC2BR,gBAAA;EACA,YAAA;AAAJ;AACI;EACE,4BAAA;EACA,uBAAA;AACN;AAEI;EAZF;IAaI,kBAAA;IACA,mBAAA;IACA,kBAAA;IACA,gBAAA;EACJ;AACF;AACI;EACE,mBAAA;AACN","sourcesContent":["$gphc-blue: #00759b;\r\n$gphc-aqua: darken(#58b896,2.5%);\r\n$gphc-purple: #553c73;\r\n$gphc-background: #d9e8e1;/* #e5f1eb;*/\r\n$light-grey: #ececec;\r\n$grey: #dedede;\r\n$very-light-grey: #f7f7f7;\r\n\r\n$gphc-accent-fushia: #bc1d63;\r\n$gphc-accent-pink: #e94870;\r\n$gphc-accent-orange: #ef7b0f;\r\n$gphc-accent-yellow: #fcc340;\r\n\r\n$banner-background: $gphc-aqua;\r\n\r\n$error: #c52e2e;\r\n\r\n$navbar-height: 58;\r\n\r\n$legacy-modal-text-color: #000000de;\r\n$legacy-modal-padding: 24px;\r\n$legacy-negative-modal-padding: -$legacy-modal-padding;","@import '../style/variables';\r\n\r\n.collapsible.padded {\r\n    padding: 40px;\r\n    margin: -40px;\r\n}\r\n\r\n\r\n.over {\r\n  position: absolute;\r\n  width: 100%;\r\n  z-index: 4;\r\n}\r\n.overlay-blue {\r\n  position: absolute;\r\n  top: 0; left: 0; right: 0; bottom: 0;\r\n  background-color: rgba(62, 94, 133, 0.7);\r\n  z-index:3;\r\n}\r\n\r\n\r\n:host-context.help-block {\r\n  .collapsible {\r\n    \r\n    padding-left: 20px;\r\n    padding-right: 20px;\r\n    background: $gphc-blue;\r\n    font-size: 1.2em;\r\n    color: white;\r\n    ::ng-deep .help-text {\r\n      padding:25px 15px 20px 10px;\r\n      color:white !important;\r\n    }\r\n\r\n    @media (max-width: 767px) {\r\n      padding-left: 10px;\r\n      padding-right: 10px;\r\n      margin-left: -20px;\r\n      border-radius: 0;\r\n    }\r\n    \r\n    &.open {\r\n      margin-bottom: 20px;\r\n    }\r\n  }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
