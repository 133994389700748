import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Address } from '../accounts/model/Address';
import { RegistrantStatus } from '../accounts/model/RegistrantStatus';
import { LogService } from '../core/service/log.service';
import { EnglishQualificationType, RegistrationRoute, RestorationDeclarationQuestion, ReturnToRegisterDetails } from './model/ReturnToRegisterDetails';
import { PreregApplicationService } from './service/prereg.service';
import { ActivatedRoute } from '@angular/router';
import { RevalidationItemType } from '../revalidation/model/RevalidationItemType';
import {MatTableModule} from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { RevalidationStatus } from '../revalidation/model/RevalidationStatus';
import { CrmSubmissionStatus } from '../queries/model/crmSubmissionStatus';
import { CrmRemediationStatus } from '../revalidation/model/CrmRemediationStatus';
import { CrmNoirAndNorStatus } from '../queries/model/crmNoirAndNorStatus';
import { CrmReviewAndFeedbackStatus } from '../revalidation/model/CrmReviewAndFeebackStatus';
import { ConfirmDialogComponent } from '../shared/confirmDialog.component';

export interface EntryElement{
  title: string;
  type: RevalidationItemType;
  isCompleted: string;
  isSubmitted: string;
  lastUpdatedOn: string;
}

@Component({
  selector: 'app-return-to-register-application',
  templateUrl: './returnToRegisterApplication.component.html',
  styleUrls: ['./application.scss','./returnToRegisterApplication.component.scss'],
  imports: [MatTableModule],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ReturnToRegisterComponent implements OnInit {

  @Input()application:ReturnToRegisterDetails; 
  englishCertificateOption: string;
  registrationStatus: string;
  Q10:RestorationDeclarationQuestion;
  Q11:RestorationDeclarationQuestion;
  q8Title="Question 8"
  q9Title="Question 9"
  registration: any;
  registrationNumber: any;
  Q10Address: string;
  Q11Address: string;  
  requestedTittle:string;
  requestedFirstName:string;
  requestedMiddleName:string;
  requestedLastName:string;
  revalidationDetails;
  tabsList = ['Application details','Revalidation details'];
  questionGroups = [];
  entries;
  columnsToDisplay = ['title','type','isCompleted','isSubmitted','lastUpdatedOn'];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  itemViewed: EntryElement | null;
  entryDetails;
  displayRevalidationRecords = true;
  submissionRejectedSuccessfully;
  loading: boolean = false;
  revalidationRejectDateTime;

  constructor(private dialog: MatDialog,
    private log: LogService,
    private preregService: PreregApplicationService) { }

    get fullName(){
      let first,middle,last;
      first=this.application.personalDetails.forenames!=null?this.application.personalDetails.forenames:"";
      middle=this.application.personalDetails.middleName!=null?this.application.personalDetails.middleName:"";
      last=this.application.personalDetails.surname!=null?this.application.personalDetails.surname:"";
      if(first=="" && middle=="" && last=="" ){
        return "-";
      }
      return first +" "+middle +" "+last;
     }
   
  fetchEntryDetailsByTitle(item) {
    this.entryDetails = item?.details ?  item.details : null;
    this.displayRevalidationRecords = null;
  }

  getRevalidationItemType(type) {
    return RevalidationItemType[type];
  }

  getItem(item, value) {
    let itemValue = value;
    if(item === 'type') itemValue = this.getRevalidationItemType(value);
    if(item === 'lastUpdatedOn') itemValue = this.getUTCdate(value);
    return itemValue;
  }

  getColumn(column) {
    if(column === 'isCompleted' || column === 'isSubmitted') return column+' ?';
    else return column;
  }
  
  getUTCdate(value) {
    const date = new Date(value.split('T')[0]);
    const ticks = date.getTime();
    const offset = date.getTimezoneOffset();
    const adjusted = ticks + (offset * 60 * 1000);
    const adjustedDate = new Date(adjusted);
    const year = adjustedDate.getFullYear();
    let month = (adjustedDate.getMonth() + 1).toString();
    if (month.length === 1) {
      month = '0' + month;
    }
    let day = adjustedDate.getDate().toString();
    if (day.length === 1) {
      day = '0' + day;
    }


    return `${day}/${month}/${year}`;
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  onRowClick(itemViewed, item) {
    return itemViewed === item ? null : item;
  }

  setElementSelected(elem, expElem) {
    this.questionGroups = elem?.form?.questionGroups || [];
  }

  ngOnInit(): void {  
    this.application.form.attachments.forEach((doc:any)=>{
      doc.url=doc.downloadUrl;// adding url proprty to attachments array
    });
    this.revalidationDetails = this.application.revalidationDetails;
    this.entries = this.application.revalidationDetails.entries ? this.application.revalidationDetails.entries : [];
    this.englishCertificateOption=EnglishQualificationType[this.application?.form?.returnToRegisterDetail?.englishCertificateOption]; 
    this.revalidationRejectDateTime = this.application?.form?.returnToRegisterDetail?.revalidationRejectDateTime || null;  
    this.registration=this.application.personalDetails.registration;
    if(this.registration){
     this.registrationNumber = this.registration.registrationNumber;      
     this.registrationStatus =RegistrantStatus[this.registration.registrationStatus] || "-";
    }
   this.application.form.restorationDeclarations.forEach(element => {
    if(element.questionName=="Q10"){
        this.Q10=element;     
        this.Q10Address=element.employerAddress!=null?this.toString(element.employerAddress):"-";       
    }
    else{
        this.Q11=element;
        this.Q11Address=element.employerAddress!=null?this.toString(element.employerAddress):"-";
    }
   
      this.requestedTittle=this.application?.form?.returnToRegisterDetail?.title?.name;
      this.requestedFirstName=this.application?.form?.returnToRegisterDetail?.forenames;
      this.requestedMiddleName=this.application?.form?.returnToRegisterDetail?.middleName;
      this.requestedLastName=this.application?.form?.returnToRegisterDetail?.surname;
   });
  }

  getRevalidationStatus(status) {
    return RevalidationStatus[status] || '-';
  }

  getSubmissionStatus(status) {
    return CrmSubmissionStatus[status] || '-';
  }

  getRemediationStatus(status) {
    return CrmRemediationStatus[status] || '-';
  }

  getNoirAndNorStatus(status) {
    return CrmNoirAndNorStatus[status] || '-';
  }

  getReviewAndFeedbackStatus(status) {
    return CrmReviewAndFeedbackStatus[status] || '-';
  }

  get isSubmissionRejected() {
    return !!this.revalidationRejectDateTime;
  }

  rejectSubmission() {

    let dialogMessage = '';
    this.loading = true;
    this.preregService.rejectSubmission(this.application.form.id, this.revalidationDetails.id).subscribe( 
    result=> {
      dialogMessage = 'Revalidation submission rejected successfully';
      this.submissionRejectedSuccessfully = true;
      this.openConfirmationDialog(dialogMessage);
      this.loading = false;
    }, error => {
      this.submissionRejectedSuccessfully = false;
      dialogMessage = 'Revalidation submission rejection failed. Please try again.';
      console.error(error);
      this.openConfirmationDialog(dialogMessage);
      this.loading = false;
    });
  }

  getObjectLength(obj) {
    return Object.keys(obj).length;
  }
  private concat(item: string) {
    return item ? item + ', ' : '';
  }
  
  toString(address) {
    return this.concat(address.line1) +
    this.concat(address.line2) +
    this.concat(address.line3) +
    this.concat(address.town) +
    this.concat(address.county) +
    address.country + ' ' +
    address.postcode;

  }

  get isRouteEEA(): boolean {
    return this.registration?.registrationRoute === RegistrationRoute.EEA;
  }

  askConfirmationDialog() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
            title: 'Confirm Rejection',
            message: `Are you sure you want to reject the revalidation submission?`
        }
    });
    dialogRef.afterClosed().subscribe((userResponse) => {
        if (userResponse) this.rejectSubmission();
    });
    }

    openConfirmationDialog(message){
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          allowCancel: false,
          allowNext: false,
          title: `Rejection submission status`,
          message: `${message}`
        }
      });
    }
}