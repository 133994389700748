export enum CrmRemediationStatus {
    RevalRecordRequired = 981360000,
    PartialRecordReceived = 981360015,
    FullRecordReceived = 981360016,
    AwaitingReview = 981360001,
    ReviewInProgress = 981360002,
    ReviewDiscussion = 981360003,
    MajorIssueQARequired = 981360004,
    AdverseReportIssued = 981360005,
    ReportAwaitingSignOff = 981360006,
    FeedbackReportAgreed = 981360007,
    QAInProgress = 981360008,
    QADispute = 981360009,
    QAComplete = 981360010,
    MgmtReviewInProgress = 981360011,
    MgmtDispute = 981360012,
    MgmtRevComplete = 981360013,
    FeedbackReportIssued = 981360014
}
