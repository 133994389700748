// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #e5f1eb;*/
.log.error {
  color: #c52e2e;
  font-weight: bold;
}

.log.info {
  color: #00759b;
}

pre {
  white-space: pre-wrap;
}`, "",{"version":3,"sources":["webpack://./src/app/style/variables.scss","webpack://./src/app/core/diagnostics.scss"],"names":[],"mappings":"AAG0B,YAAA;ACD1B;EACE,cDYM;ECXN,iBAAA;AAAF;;AAGA;EACE,cDRU;ACQZ;;AAGA;EACE,qBAAA;AAAF","sourcesContent":["$gphc-blue: #00759b;\r\n$gphc-aqua: darken(#58b896,2.5%);\r\n$gphc-purple: #553c73;\r\n$gphc-background: #d9e8e1;/* #e5f1eb;*/\r\n$light-grey: #ececec;\r\n$grey: #dedede;\r\n$very-light-grey: #f7f7f7;\r\n\r\n$gphc-accent-fushia: #bc1d63;\r\n$gphc-accent-pink: #e94870;\r\n$gphc-accent-orange: #ef7b0f;\r\n$gphc-accent-yellow: #fcc340;\r\n\r\n$banner-background: $gphc-aqua;\r\n\r\n$error: #c52e2e;\r\n\r\n$navbar-height: 58;\r\n\r\n$legacy-modal-text-color: #000000de;\r\n$legacy-modal-padding: 24px;\r\n$legacy-negative-modal-padding: -$legacy-modal-padding;","@import \"../style/variables\";\r\n\r\n.log.error {\r\n  color: $error;\r\n  font-weight: bold;\r\n}\r\n\r\n.log.info {\r\n  color: $gphc-blue;\r\n}\r\n\r\npre {\r\n  white-space: pre-wrap;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
