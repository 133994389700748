import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { LogService } from '../core/service/log.service';
import { CustomErrorHandler } from '../core/service/CustomErrorHandler';
import { UnlockAccountComponent } from './unlockAccount.component';
import { UserLookupComponent } from './userLookup.component';
import { UnlockAccountResultDialogComponent } from './unlockAccountResultDialog.component';
import { AccountService } from './service/account.service';
import { CoreModule} from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { AccountsComponent } from './accounts.component';
import { UserListComponent } from './userList.component';
import { AddressEditComponent } from './addressEdit.component';
import { AddressComponent } from './address.component';
import { AddressSearchComponent } from './addressSearch.component';

@NgModule({
    imports: [
        CoreModule,
        SharedModule
    ],
    declarations: [
        AccountsComponent,
        UserLookupComponent,
        UserListComponent,
        UnlockAccountComponent,
        UnlockAccountResultDialogComponent,
        AddressComponent,
        AddressEditComponent,
        AddressSearchComponent
    ],
    exports: [
        AddressComponent,
        AddressEditComponent,
        AddressSearchComponent
    ],
    providers: [
        LogService,
        AccountService,
        { provide: ErrorHandler, useClass: CustomErrorHandler }
    ]
})
export class AccountsModule { }
