import { Component, Input, Output, EventEmitter} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

@Component({
  selector: 'app-collapsible',
  moduleId: module.id,
  templateUrl: 'collapsible.component.html',
  styleUrls: ['./collapsible.scss'],
  animations: [
    trigger('openState', [
      state('open', style({
        height: '*',

        // display: 'block'
      })),
      state('closed', style({
        height: '0',
 
        // display: 'none'
      })),
      transition('open => closed', animate('250ms cubic-bezier(0, 0, 0.2, 1)')),
      transition('closed => open', animate('250ms cubic-bezier(0, 0, 0.2, 1)'))
    ])
  ]
})
export class CollapsibleComponent  {

  openState = 'closed';
  open = false;
  @Input() overlay = false;
  @Input() set trigger(value) {
    this.openState = value ? 'open' : 'closed';
    this.open = value;
  }

}
