import { Component, Input } from '@angular/core';
import { AttachmentType } from './model/attachmentType';
import { PreregApplicationService } from './service/prereg.service';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-application-supporting-docs',
    templateUrl: './applicationSupportingDocs.component.html',
    styleUrls: ['./applicationSupportingDocs.scss']
})
export class ApplicationSupportingDocsComponent {
    @Input() application;
    @Input() isStudent = false;
    attachmentType = AttachmentType;
    downloading = false;
    downloadClickCount = 0;
    attachments = [];
    @Input() isCountersignerView = false;
    @Input() isAros = false;
    @Input() isPrescriber = false;
    @Input() isRTR = false;
    @Input() isCCPS = false;
    supportingDocs=[];

    constructor(
        private service: PreregApplicationService
    ) { }
    ngOnInit() {
        if (this.isCountersignerView) {
            this.attachments = this.attachments.filter(this.removePhotoDocsFromSupportingDocs);
        }
        
        if(this.isRTR) this.supportingDocs = this.application?.form?.attachments;
        else if(this.isCCPS) this.supportingDocs = this.application?.form?.attachments;
        else this.supportingDocs = this.application?.supportingDocuments;
    }

    removePhotoDocsFromSupportingDocs(element, index, array) {
        return element.type !== AttachmentType.Photo;
    }

    getIcon(file) {
        const bits = file.filename.split('.');
        const extension = bits[bits.length - 1];
        switch (extension) {
            case 'pdf':
                return 'fa fa-file-pdf-o';
            case 'png':
            case 'jpg':
            case 'bmp':
            case 'gif':
                return 'fa fa-file-image-o';
            case 'doc':
            case 'docx':
                return 'fa fa-file-word-o';
            case 'zip':
            case 'rar':
                return 'fa fa-file-archive-o';
            default:
                return 'fa fa-file-o';
        }
    }

    download(file): void {
        this.downloading = true;
        this.downloadClickCount += 1;
        this.service.download(environment.api.root + '/' + file.url).subscribe(blob => {
            this.downloading = false;
            setTimeout(() => {
                this.downloadClickCount -= 1;
            }, 2000);
                const url = URL.createObjectURL(blob);
                const element = document.createElement('a');
                element.href = url;
                element.setAttribute('download', file.filename);
                document.body.appendChild(element); // Append the element to work in firefox
                element.click();
        });
    }
}

