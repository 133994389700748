
import { map, startWith } from 'rxjs/operators';
import { Component, Input, OnInit, Output, EventEmitter, forwardRef, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';




@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ]
})
export class AutocompleteComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  myControl: FormControl<string | null> = new FormControl();
  @Input() options;
  @Input() name;
  @Input() id;
  @Input() class;
  @Input() placeholder;
  @ViewChild('inputControl',) inputControl: ElementRef;
  hasFocus = false;
  @Input('hasFocus') set setHasFocus(hasFocus) {
    this.hasFocus = hasFocus;

    // if (hasFocus && this.inputControl) {
    //   this.inputControl.nativeElement.focus();
    // }
  }

  constructor(private cd: ChangeDetectorRef) { }
  value = '';

  filteredOptions: Observable<string[]>;

  click($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }
  ngOnInit() {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(null),
      map(val => val ? this.filter(val) : this.options.slice()));
    this.myControl.setValue(this.value);
  }

  ngAfterViewInit() {
    if (this.hasFocus && this.inputControl) {
      this.inputControl.nativeElement.focus();
      setTimeout(() => {
        this.inputControl.nativeElement.select();
      });

      this.cd.detectChanges();
    }
  }

  filter(val: string): string[] {
    return this.options.filter(
      option =>
        option.toLowerCase().indexOf(val.toLowerCase()) === 0);
  }

  trySelect() {

    const filter = this.options.filter(option => option.toLowerCase() === this.myControl.value.toLowerCase());

    if (filter.length) {
      this.value = filter[0];
      this.propagate();
    }
  }

  onOptionSelected($event) {
    const newValue = this.options.find(option => option === $event.option.value);
    if (newValue !== this.value) {
      this.value = newValue;
      this.propagate();
    }
  }

  propagate() {
    this.propagateChange(this.value);
  }

  writeValue(value: any) {
    if (value) {
      this.value = value;
      this.myControl.setValue(this.value);
    }

  }
  propagateChange = (model: any) => { };
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() { }


}
