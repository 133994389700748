export enum CrmNoirAndNorStatus {
  NoChange = 0,
  NirIssued = 981360000,
  NirSupplementaryIssued = 981360001,
  NorIssued = 981360002,
  AppealInProgress = 981360003,
  AppealUpheld = 981360004,
  AppealNotUpheld = 981360005,
  Removed = 981360006,
  AwaitingReview = 981360007
}