// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host section {
  max-width: 400px;
}
:host a {
  cursor: pointer;
}
:host button.disabled {
  cursor: not-allowed;
  pointer-events: none;
}
:host .flexi {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
:host .download-button a {
  display: flex;
}
:host .download-button i {
  font-size: 1.5em;
  margin-left: 10px;
  margin-right: 10px;
}
:host li {
  list-style-type: none;
}
:host h6 {
  color: #00759b;
  font-weight: 900;
  font-size: 1.1em;
}`, "",{"version":3,"sources":["webpack://./src/app/prereg/applicationSupportingDocs.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AAGE;EACE,eAAA;AADJ;AAKI;EACE,mBAAA;EACA,oBAAA;AAHN;AAQE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AANJ;AAUI;EACE,aAAA;AARN;AAWI;EACE,gBAAA;EACA,iBAAA;EACA,kBAAA;AATN;AAcE;EAEE,qBAAA;AAbJ;AAiBE;EACE,cAAA;EACA,gBAAA;EACA,gBAAA;AAfJ","sourcesContent":[":host {\r\n  section {\r\n    max-width: 400px;\r\n  }\r\n\r\n  a {\r\n    cursor: pointer;\r\n  }\r\n\r\n  button {\r\n    &.disabled {\r\n      cursor: not-allowed;\r\n      pointer-events: none;\r\n    }\r\n  }\r\n\r\n\r\n  .flexi {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n  }\r\n\r\n  .download-button {\r\n    a {\r\n      display: flex;\r\n\r\n    }\r\n    i {\r\n      font-size: 1.5em;\r\n      margin-left: 10px;\r\n      margin-right: 10px;\r\n    }\r\n  }\r\n\r\n\r\n  li {\r\n\r\n    list-style-type: none;\r\n\r\n  }\r\n\r\n  h6 {\r\n    color: #00759b;\r\n    font-weight: 900;\r\n    font-size: 1.1em;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
