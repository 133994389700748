import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
// import { HttpModule } from '@angular/http';
import { LogService } from './service/log.service';
import { UpdaterService } from './service/updater.service';
import { LayoutService } from './service/layout.service';
import { AuthService } from './service/auth.service';
import { BrandingComponent } from './branding.component';
import { LoginComponent } from './login.component';
import { NavComponent } from './nav.component';
import { SharedModule } from '../shared/shared.module';
import { CustomErrorHandler } from './service/CustomErrorHandler';
import { UploadService } from './service/upload.service';
import { DevService } from './service/dev.service';
import { DiagnosticsComponent } from './diagnostics.component';
import { NotFoundComponent } from './notFound.component';
import { DynamicModule } from '../dynamic/dynamic.module';
import { TooltipModule } from './tooltip/tooltip.module';
import { TooltipService } from './tooltip/tooltip.service';
import { DynamicService } from './service/dynamic.service';
import { UserService } from './service/user.service';
import { UtcDatePipe } from '../shared/pipe/UtcDate.pipe';
import { NotificationComponent } from './notification.component';
import { MaintenanceFormComponent } from './maintenance.component';
import { MaintenanceService } from './service/maintenance.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconRegistry, MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
@NgModule({ declarations: [
        BrandingComponent,
        LoginComponent,
        NavComponent,
        DiagnosticsComponent,
        NotFoundComponent,
        NotificationComponent,
        MaintenanceFormComponent
    ],
    exports: [
        BrandingComponent,
        LoginComponent,
        NavComponent,
        MaintenanceFormComponent,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule,
        SharedModule,
        TooltipModule,
        BrowserModule,
        // HttpClientModule,
        MatDatepickerModule,
    ], imports: [BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        RouterModule,
        SharedModule,
        DynamicModule,
        TooltipModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatSlideToggleModule], providers: [
        DevService,
        LogService,
        UploadService,
        UpdaterService,
        LayoutService,
        AuthService,
        MaintenanceService,
        TooltipService,
        DynamicService,
        CustomErrorHandler,
        UserService,
        UtcDatePipe,
        { provide: ErrorHandler, useClass: CustomErrorHandler },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class CoreModule { }
