import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { LayoutService } from '../core/service/layout.service';
import { AccountService } from '../accounts/service/account.service';
import { NotificationType } from '../accounts/model/NotificationType';
import { CustomErrorHandler } from '../core/service/CustomErrorHandler';

enum SearchOption {
  RegNumber,
  PreEntryNumber
}

@Component({
  moduleId: module.id,
  templateUrl: './notification.component.html',
  styleUrls: ['../revalidation/list.scss']
})
export class NotificationComponent implements AfterViewInit {

  NotificationType = NotificationType;
  loading = false;
  searchVisible = true;
  searchCriteria = {
    regNumber: '',
    preEntryNumber: ''
  };

  SearchOption = SearchOption;
  searchBy = SearchOption.RegNumber;
  notifications;
  notificationOwner;

  @ViewChild('regNumber') regNumberRef: ElementRef;

  constructor(
    public layout: LayoutService,
    public account: AccountService,
    public error: CustomErrorHandler
  ) { }

  search() {
    this.loading = true;
    switch (this.searchBy) {
      case SearchOption.PreEntryNumber:
        this.account.getNotificationsByPreEntryNumber(this.searchCriteria.preEntryNumber).subscribe(notificationsData => {
          this.notificationOwner = notificationsData.recipient.fullName;
          this.notifications = notificationsData.notifications.data;
          this.loading = false;
        });
        break;
      case SearchOption.RegNumber:
        this.account.getNotificationsByRegNumber(this.searchCriteria.regNumber).subscribe(
          notificationsData => {
            this.notificationOwner = notificationsData.recipient.fullName;
            this.notifications = notificationsData.notifications.data;
            this.loading = false;
          }
        );
        break;
      default:
        throw new Error('Neither pre-entry number nor registration number selected');
    }
  }

  ngAfterViewInit() {
    this.setFocus();
  }


  get searchByRegNumber() {
    return this.searchBy === SearchOption.RegNumber;
  }

  get searchByPreEntryNumber() {
    return this.searchBy === SearchOption.PreEntryNumber;
  }

  setFocus() {
    if (this.searchVisible) {
      setTimeout(() => {
        this.regNumberRef.nativeElement.focus();
      });
    }
  }

  showSearch() {
    this.searchVisible = !this.searchVisible;
  }

  clearSearch() {
    this.searchCriteria.regNumber = '';
    this.searchCriteria.preEntryNumber = '';
    this.notifications = '';
    this.loading = false;
  }

}
