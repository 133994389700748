import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  moduleId: module.id,
  templateUrl: './notFound.component.html'
})
export class NotFoundComponent  {

  constructor(private _router: Router) {
  }

}
