import { Injectable } from '@angular/core';
import { ServiceBase } from '../../core/service/service.base';
import { AuthService } from '../../core/service/auth.service';
import { LogService } from '../../core/service/log.service';
import { CustomErrorHandler } from '../../core/service/CustomErrorHandler';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Review } from '../model/Review';
import { Feedback } from '../model/Feedback';
import { map } from 'rxjs/operators';
import { ReviewAllocationSplitType } from '../model/RevalAllocationSplitType';


@Injectable()
export class ReviewService extends ServiceBase {

  constructor(http: HttpClient, auth: AuthService, log: LogService, errorHandler: CustomErrorHandler) {
    super(http, auth, log, errorHandler);
  }

  initializeCohort(renewalDate, percentage) {
    return super.post('v1.0/admin/reviews/cohort/initialize', { renewalDate, percentage });
  }


  assignReviews() {

    /*

    *Assign review to reviewers* 
    A call to this endpoint should assign all the reviews to reviewers for the given renewalDate (cohort),  (TBC) commit the assignments and send out notifications to the respective reviewers

    POST v1.10/admin/reviews/assign
    payload: { renewalDate: '2019-07-31' }

    returns:
    {
      totalReviews: 470,
      reviewsAssigned: 470 (not sure if its necessary to confirm that all reviews were assigned)
    }

    */
  }

  publishReviews() {

    /*

*Publish reviews*
A call to this endpoint should send feedback to all the registrants for all the reviews in the cohort given by the renewalDate, with the exception of any reviews marked 'excludeFromPublish'. If there are no reviews marked 'excludeFromPublish' this should also set the RevalidationReviewCohortStatus to Completed

POST v1.10/admin/reviews/assign
payload: { renewalDate: '2019-07-31' }

returns:
{
  totalReviews: 470,
  excludedFromPublish: 2,
  published: 468
}

*/
  }

  excludeFromBatch(id, filters) {
    return super.post(`/v1.0/admin/reviews/cohort/${id}/exclude`, filters, true);
  }
  includeInBatch(id, filters) {
    return super.post(`/v1.0/admin/reviews/cohort/${id}/include`, filters, true);
  }

  sendToRemediation(id) {
    return super.post(`v1.0/admin/review/${id}/remediate`, null, true);
  }

  excludedFromPublish(reviewId, excludeFromPublish) {
    /*
        *Exclude a review from publish*
        A call to this endpoint should set the excludeFromPublish flag to true/false for the given reviewId
        POST v1.10/admin/reviews/excludeFromPublish
        payload: { reviewId:, excludeFromPublish: true }
        returns 200 if successful (no data required to be sent back)

        */
    return super.post('/v1.0/admin/reviews/excludeFromPublish', { reviewId, excludeFromPublish }, true);
  }

  getReviewers() {
    /*
    *Get list of reviewers for filtering on*
    
 
 
    GET v1.10/admin/reviews/reviewers

 
    returns
    
    data: [
      {
        userId: 123456,
        name: 'Mr Lead Reviewer',
        registrantType: 1,2,undefined=lay reviewer
      }
    ]
 
    */
    return super.get('v1.0/admin/reviews/reviewers');
  }

  getCohortSummary() {

    /*

    Get review summary

    GET v1.0/admin/reviews/summary

    returns:
    {
      data: [
       {
    'renewalDate': '2018-10-31',
    'revalidationSubmissionCount': 42029,
    'revalidationReviewCohortId': <guid>,
    'reviewDeadline': '2019-06-31',
    'reviews': 0,
    'inExternalReviewStage': 0,
    'inQualityAssuranceStage': 0,
    'qualityAssuranceCompleted': 0,
    'awaitingApprovalByQualityAssuranceManager': 0,
    'approvedByQualityAssurerManager': 0,
    'feedbackSent': 0,
    'qualityAssurerApproved': 0,
    'qualityAssuranceManagerApproved': 0,
    'excludedFromPublish': 0
    'revalidationReviewCohortStatus': <see below>
  }
      ]
    }
    managerPending = (10% of total) - managerApproved (will be computed on the front end)
    */
    return this.get('v1.0/admin/reviews/cohort/summary');
  }

  getBacklogSummary() {
    return this.get('/v1.0/admin/reviews/backlog/summary');
  }

  getBacklog(params, pager) {
    return this.get('/v1.0/admin/reviews/backlog/list', params, pager);
  }

  getSamplings() {
    return super.get('/v1.0/admin/reviews/sampling/summary');
  }

  generateSamplings(renewalDate, percentage) {
    return super.post('v1.0/admin/reviews/sampling/create', { renewalDate, percentage });
  }

  createReviewCohort(cohortSize, reviewDeadline, PharmacistAllocation, TechnicianAllocation, ReviewAllocationSplitType ) {
    return super.post('v1.0/admin/reviews/cohort/create', { cohortSize, reviewDeadline, PharmacistAllocation, TechnicianAllocation, ReviewAllocationSplitType });
  }

  approveFeedback(reviewId, feedbackId) {
    return super.post(`v1.0/admin/revalidation/review/${reviewId}/feedback/${feedbackId}/approval`, { approve: true }, true);

  }

  rejectFeedback(reviewId, feedbackId, reason) {
    return super.post(`v1.0/admin/revalidation/review/${reviewId}/feedback/${feedbackId}/approval`, { approve: false, reason }, true);

  }

  publishFeedback(batchId) {
    return super.post(`v1.0/admin/reviews/cohort/publish`, { revReviewCohortId: batchId }, true);
  }

  saveFeedback(reviewId, feedback: Feedback) {
    // commenting this condition, because it will always return 'false' since the types 'PerformanceIndicator' and '2' have no overlap
    // if (feedback.performanceIndicator === 2) {
    //   feedback.performanceIndicator = 3;
    // }
    const payload = {
      submit: false,
      feedback: {
        performanceIndicator: feedback.performanceIndicator,
        plannedCpdFeedback: feedback.plannedCpdFeedback,
        unplannedCpdFeedback: feedback.unplannedCpdFeedback,
        peerDiscussionFeedback: feedback.peerDiscussionFeedback,
        reflectiveAccountFeedback: feedback.reflectiveAccountFeedback
      }
    };
    return super.post(`v1.0/admin/revalidation/review/${reviewId}/feedback`, payload);
  }

  getCohortExternalReview(params, pager): Observable<{ reviews: Array<Review> }> {

    return this.get('v1.0/admin/reviews/cohortExternalReview', params, pager).pipe(
      map(data => {
        data.reviews = data.reviews.map(item => {

          if (item.reviewId) {
            item.id = item.reviewId;
          }
          item.reviewId = item.id;
          return item;

        });
        return data;

      })
    )


  }

  getCohortQa(params, pager): Observable<{ reviews: Array<Review> }> {

    return this.get('v1.0/admin/reviews/cohortQa', params, pager).pipe(
      map(item => {
        if (item.reviewId) {
          item.id = item.reviewId;
        }
        item.reviewId = item.id;

        return item;
      }));


  }

  changeReviewers(reviewId, leadReviewerUserId, secondaryReviewerUserId) {
    return this.post(`v1.0/admin/reviews/reassignReviewers`,
      {
        reviewId,
        leadReviewerId: leadReviewerUserId,
        reviewerId: secondaryReviewerUserId
      }, true);
  }

  getReview(id: string): Observable<Review> {
    return this.get(`v1.0/admin/review/${id}`).pipe(map(data => new Review(data)));
  }

  commitCohort(cohortId) {
    return this.post(`v1.0/admin/reviews/cohort/commit`, { cohortId });
  }


  download(url: string) {
    return this.getFile(url);
  }

  assignItem(id, userId) {
    return this.post(`v1.0/admin/reviews/${id}/assign`,
      {
        ObjectId: userId
      });
  }



  saveRecommendation(payload) {
    return this.post(`v1.0/user/extenuatingcircumstances/${payload.id}/review`, payload);
  }

  saveDecision(payload) {
    return this.post(`v1.0/user/extenuatingcircumstances/${payload.id}/decision`, payload);
  }

  submitDecision(id) {
    return this.post(`v1.0/user/extenuatingcircumstances/${id}/submit`, {});
  }


  search(regNumber) {
    return this.get(`v1.0/user/extenuatingcircumstances?registrationNumber=${regNumber}`);
  }

  searchUniqueName(title) {
    // const mockResults = [
    //   {
    //     'id': 'bd33c43d-5b20-4a9e-b84d-877dd1339c6e',
    //     'renewalDate': '2019-04-14',
    //     'reviewDeadline': '2019-04-14',
    //     'title': 'P2KXZ',
    //     'registrationNumber': '5010956',
    //     'registrantName': 'JoH1123259 LeH1123259',
    //     'registrantType': 2,
    //     'reviewStage': 1,
    //     'excludedFromPublish': false,
    //     'failedCoreCriteria': false,
    //     'leadReviewerUserId': 'c5a2e83e-c77b-e911-9449-0050568500fa',
    //     'leadReviewerDateAssigned': '2019-07-31',
    //     'leadReviewerName': 'We123456 We123456',
    //     'leadReviewerIndicator': 0,
    //     'secondaryReviewerUserId': 'cae2ead5-7aaf-e411-80d8-00505685383b',
    //     'secondaryReviewerDateAssigned': '2019-07-31',
    //     'secondaryReviewerName': 'HeH1082581 KnH1082581',
    //     'secondaryReviewerIndicator': 0,
    //     'assignedTo': null,
    //     'qualityAssurerApproved': false,
    //     'qualityAssuranceManagerApproved': false,
    //     'finalAssessment': 0,
    //     'reviewerReallocationStatus': 0,
    //     'registrantUnderInvestigation': false,
    //     'daysLate': 15,
    //     'inRemediationThisYear': false,
    //     'excludedFromReview': false,
    //     'lastUpdated': '2019-07-31 08:08'
    // }
    // ];
    return super.get(`v1.0/admin/review/title/${title}`);
    // return of(mockResults);
  }

  filter(status) {
    return this.get(`v1.0/user/extenuatingcircumstances?status=${status}`);
  }


  getRemediationSummary() {
    return super.get(`v1.0/revalidation/getRegistrantSummary`);
  }


  getRegistrantsInRemediation(submissionDeadline, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInRemediation?SubmissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}`);
  }

  getRegistrantsInNoir(submissionDeadline, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInNoIR?submissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}`);
  }

  getRegistrantsInNor(submissionDeadline, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInNoR?submissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}`);
  }

  issueNoir(regNumber) {
    // TODO: implement call to API
    return of('');
  }

  moveRegistrantToNOR(revalidationId) {
    return super.post(`v1.0/admin/revalidation/updateStage?revalidationId=${revalidationId}&stage=4`, {});
  }

  moveRegistrantToNoir(revalidationId) {
    return super.post(`v1.0/admin/revalidation/updateStage?revalidationId=${revalidationId}&stage=3`, {});
  }

  issueSupplementaryNoir(revalidationId) {
    return super.post(`v1.0/admin/revalidation/issueSupplementaryNoIR/?revalidationId=${revalidationId}`, {});
  }

  issueNorLetter(revalidationId, date) {
    return super.post(`v1.0/admin/revalidation/issueNoRLetter`, { revalidationId, NoRLetterIssuedAt: date });
  }

  searchRegistrationNumberNor(submissionDeadline, regNumber, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInNoR/?SubmissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}&registrationNumber=${regNumber}`);
  }

  searchRegistrationNumberNoir(submissionDeadline, regNumber, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInNoIR/?SubmissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}&registrationNumber=${regNumber}`);
  }

  searchRegistrationNumberRemediation(submissionDeadline, regNumber, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInRemediation/?SubmissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}&registrationNumber=${regNumber}`);
  }

  filterByManuallyEditedNor(submissionDeadline, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInNoR/?SubmissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}&isManual=true`);
  }

  filterByManuallyEditedNir(submissionDeadline, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInNoIR/?SubmissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}&isManual=true`);
  }

  filterByManuallyEditedRemediation(submissionDeadline, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInRemediation/?SubmissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}&isManual=true`);
  }

  filterByNorIssued(submissionDeadline, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInNoR/?SubmissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}&isNoRIssued=true`);
  }
}
