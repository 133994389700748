
import { of as observableOf, Observable, of, Subject } from 'rxjs';
import { Component, Injectable } from '@angular/core';
import { filter, map, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { MockNotifications } from '../model/mockNotifications';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AccountInfo, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';

@Injectable()
export class AuthService {

  private _accessToken;
  private readonly _destroying$ = new Subject<void>();
  loginSuccessTime;

  user: any;
  constructor(private msalService: MsalService, private router: Router,
    private msalBroadcastService: MsalBroadcastService) { }
  public getToken(): Observable<string> {
    return of(this._accessToken);

  }

  public init() {
    this.msalBroadcastService.msalSubject$.pipe(
      filter((msg:EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
    ).subscribe((result: EventMessage) => {
      this.loginSuccessTime = new Date();
      this._accessToken = result.payload['accessToken'];
      this.router.navigate(['/accounts']);

      this.msalBroadcastService.msalSubject$
     .pipe(filter((msalSubject: EventMessage) => msalSubject.eventType === EventType.ACQUIRE_TOKEN_START))
     .subscribe(() => {      
        
        if(this.loginSuccessTime) {
          const oneHourPostLogin = this.loginSuccessTime.setHours(this.loginSuccessTime.getHours() + 1);
          const activeAccount = this.getActiveAccount();
          if (activeAccount?.idTokenClaims?.exp && new Date(oneHourPostLogin).getTime() && activeAccount?.idTokenClaims?.exp > new Date(oneHourPostLogin).getTime() / 1000) {
            this.logout();
          }
        }        
    });
    })
  }

  public logout() {
    this.msalService.logoutRedirect();
  }

  public login() {
    this.msalService.instance.handleRedirectPromise().then((tokenResponse) => {
      if(!tokenResponse) {
        const accounts = this.msalService.instance.getAllAccounts();
        if(accounts.length === 0) {
          // No user signed in 
          this.msalService.instance.loginRedirect();
        } else {

        }
      }
    }).catch( error => console.error(error));

    this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this._destroying$)
    )
    .subscribe(() => {
      this.setLoginDisplay();
    })
  }

  public loggedIn(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  public redirect() {
    if (this.loggedIn()) {
      this.router.navigate(['/accounts']);
    } else {
      this.router.navigate(['/signin']);
    }
  }

  getNotifications() {
    return observableOf(MockNotifications).pipe(map(res => res));
  }

  setLoginDisplay() {
    this.user = this.msalService.instance.getAllAccounts()[0];
  }

  getActiveAccount(): AccountInfo {
    const activeAccount = this.msalService.instance.getActiveAccount();
    return activeAccount || (this.msalService.instance.getAllAccounts().length ? this.msalService.instance.getAllAccounts()[0] : null);
  }

}
