import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CrmSubmissionStatus } from '../../queries/model/crmSubmissionStatus';
import { LayoutService } from '../../core/service/layout.service';
import { RevalidationStatus } from '../model/RevalidationStatus';
import { SubmissionEnquiryService } from '../service/submissionEnquiry.service';
import { CrmRemediationStatus } from '../model/CrmRemediationStatus';
import { CrmNoirAndNorStatus } from '../../queries/model/crmNoirAndNorStatus';
import { CrmReviewAndFeedbackStatus } from '../model/CrmReviewAndFeebackStatus';
import { RevalidationItemType } from '../model/RevalidationItemType';
import { AnswerType } from '../../dynamic/model/AnswerType';
import { CompletionStatus } from '../model/CompletionStatus';
import { ExtenuatingCircumstanceReviewDecision } from '../../queries/model/ExtenuatingCircumstanceReviewDecision';
import { ExtCircType } from '../model/ExtCircType';

interface revalidationYearlyDetail{
  year: string,
  revalidationSubmitted: boolean,
  submissionDate: string,
  lastDateEntriesSaved: string,
  submissionStatus: string,
  completionStatus: string,
  savedEntries: Array<entry>
}

interface entry{
  title: string,
  link: string
}

@Component({
  selector: 'app-revalidation-details',
  templateUrl: './revalidation-details.component.html',
  styleUrls: ['./revalidation-details.component.scss']
})
export class RevalidationDetailsComponent implements OnInit {

  @ViewChild('regNumber',) regNumberRef: ElementRef;

  loading = false;
  searchCriteria = {
    regNumber: '',
    date: undefined,
    status: undefined
  };
  regNo;
  selectedDate;
  heading;
  subHeading;
  displayRevalidationRecords = true;
  entries = [];
  revalidations;
  revalidationDetails;
  registrantName;
  expectation;
  revalidationSelected;
 
  AnswerType = AnswerType;
  exceptionalCircumstance = [];
  CompletionStatus = CompletionStatus;
  ExtenuatingCircumstanceReviewDecision = ExtenuatingCircumstanceReviewDecision;
  ExtCircType = ExtCircType;
  entryDetails;


  constructor(
    public layout: LayoutService,
    private route: ActivatedRoute,
    private service: SubmissionEnquiryService
  ) { }

  ngOnInit(): void {
    this.heading = 'Submission enquiry';
    this.setSubHeading();      
  }

  search(regNum) {
      this.loading = true;
      this.service.getRevlidationSubmissions(this.searchCriteria.regNumber).subscribe((details: any) => {
        this.revalidationDetails = details;
        this.revalidations = details.revalidations;
        this.registrantName = details.registrantName;   
        this.loading = false;
      });
      this.regNo = this.searchCriteria.regNumber;
      this.displayRevalidationRecords = true;
  }

  getRevalidationStatus(status) {
    return RevalidationStatus[status];
  }

  getSubmissionStatus(status) {
    return CrmSubmissionStatus[status];
  }

  getRemediationStatus(status) {
    return CrmRemediationStatus[status];
  }

  getNoirAndNorStatus(status) {
    return CrmNoirAndNorStatus[status];
  }

  getReviewAndFeedbackStatus(status) {
    return CrmReviewAndFeedbackStatus[status];
  }

  getRevalidationItemType(type) {
    return RevalidationItemType[type];
  }

  clearSearch() {
    this.displayRevalidationRecords = true;
    this.searchCriteria.regNumber = this.registrantName =  '';
    this.searchCriteria.date = '';
    this.revalidations = [];
  }

  setYearSelected(date) {
    this.selectedDate = date;
    this.revalidationSelected = this.revalidations.filter( revalidation => revalidation.renewalDate === date)[0];
    this.entries = this.revalidations.filter( revalidation => revalidation.renewalDate === date)[0].entries;
    this.expectation = this.revalidations.filter( revalidation => revalidation.renewalDate === date)[0].expectation;
    this.exceptionalCircumstance = this.revalidations.filter(revalidation =>revalidation.renewalDate === date)[0].exceptionalCircumstance;
    this.fetchEntriesDetails();
  }

  fetchEntriesDetails() {
    this.displayRevalidationRecords = false;
    this.setSubHeading();    
  }

  onBackBtnClick(){
    this.displayRevalidationRecords = true;
    this.search(this.regNo);
  }

  setSubHeading() {
    this.subHeading = (this.displayRevalidationRecords === true) ? 'View revalidation records': (this.displayRevalidationRecords === false ? 'View revalidation record details': '');
  }

  fetchEntryDetailsByTitle(item) {
  this.entryDetails = {questionGroups : item.form.questionGroups, title : item.title};
  this.displayRevalidationRecords = null;
  }
  onEntriesBackBtnClick() {
   this.fetchEntriesDetails();
  }
}
