import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: 'confirmDialog.html',
})
export class ConfirmDialogComponent {

  defaultMessage = 'The action is irreversible - are you sure?';
  defaultTitle = 'Delete item';
  allowCancel = true;
  dateDialog = false;
  allowNext = true;

  get message(): string {
    if (this.data && this.data.message) {
      return this.data.message;
    }
    // return this.defaultMessage;
  }

  get title(): string {
    if (this.data && this.data.title) {
      return this.data.title;
    }
    // return this.defaultTitle;
  }

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

    if (data.allowCancel === false) {
      this.allowCancel = false;
    }

    if (data.allowNext === false) {
      this.allowNext = false;
    }

    if (data.dateDialog) {
      this.dateDialog = true;
    }
  }

}
