import { QueriesComponent } from './queries/queries.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './core/login.component';
import { LoggedInOnlyGuard } from './guard/LoggedInOnly.guard';
import { CanDeactivateGuard } from './guard/CanDeactivate.guard';
import { DiagnosticsComponent } from './core/diagnostics.component';
import { NotFoundComponent } from './core/notFound.component';
import { AccountsComponent } from './accounts/accounts.component';
import { ExtCircListComponent } from './revalidation/exceptionalCircumstances/extCircList.component';
import { ExtCircItemComponent } from './revalidation/exceptionalCircumstances/extCircItem.component';
import { ApplicationsComponent } from './prereg/applications.component';
import { NotificationComponent } from './core/notification.component';
import { RevalidationComponent } from './revalidation/revalidation.component';
import { RemediationDashboardComponent } from './revalidation/remediation/remediationDashboard.component';
import { QaCohortComponent } from './revalidation/review/qaCohort.component';
import { ReviewSummaryComponent } from './revalidation/review/reviewSummary.component';
import { RemediationListComponent } from './revalidation/remediation/remediationList.component';
import { ExternalReviewCohortComponent } from './revalidation/review/externalReviewCohort.component';
import { ReviewComponent } from './revalidation/review/detail/review.component';
import { ReviewBacklogComponent } from './revalidation/review/reviewBacklog.component';

import { MaintenanceFormComponent } from './core/maintenance.component';
import { RevalidationDetailsComponent } from './revalidation/details/revalidation-details.component';
import { ExtCircGrantedRevalidationsComponent } from './revalidation/extCircGrantedRevalidations/extCirc-granted-revalidations.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/accounts',
    pathMatch: 'full'
  },
  {
    path: 'accounts',
    component: AccountsComponent,
    canActivate: [LoggedInOnlyGuard]
  },
  {
    path: 'revalidation',
    component: RevalidationComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Revalidation' }
  },
  {
    path: 'revalidation/extenuating-circumstances',
    component: ExtCircListComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Exceptional circumstances' }
  },
  {
    path: 'revalidation/extenuating-circumstances/:id',
    component: ExtCircItemComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Exceptional circumstances' }
  },
  {
    path: 'revalidation/remediation',
    component: RemediationDashboardComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Remediation dashboard' }
  },
  {
    path: 'revalidation/remediation/list/:revalidationStatus/:submissionDeadline',
    component: RemediationListComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Remediation list' }
  },
  {
    path: 'revalidation/review/qaCohort/:cohortId',
    component: QaCohortComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Review management - QA stage', fullscreen: true }
  },
  {
    path: 'revalidation/review/qaCohort',
    component: QaCohortComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Review management - QA stage', fullscreen: true }
  },
  {
    path: 'revalidation/review/externalReviewCohort/:cohortId',
    component: ExternalReviewCohortComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Review management - External review stage', fullscreen: true }
  },
  {
    path: 'revalidation/review/externalReviewCohort',
    component: ExternalReviewCohortComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Review management - External review stage', fullscreen: true }
  },
  {
    path: 'revalidation/review/backlog',
    component: ReviewBacklogComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Review backlog', fullscreen: true }
  },
  {
    path: 'revalidation/review/:id',
    component: ReviewComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Review detail', fullscreen: true }
  },
  {
    path: 'revalidation/review',
    component: ReviewSummaryComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Review management' }
  },
  {
    path: 'revalidation/submission-enquiry',
    component: RevalidationDetailsComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Submission enquiry' }
  },
  {
    path: 'revalidation/submission-enquiry',
    component: RevalidationDetailsComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Submission enquiry' }
  },
  {
    path: 'notifications',
    component: NotificationComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Notifications' }
  },
  {
    path: 'maintenanceForm',
    component: MaintenanceFormComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Maintenance' }
  },
  {
    path: 'prereg/applications',
    component: ApplicationsComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Applications' }
  },
  {
    path: 'queries',
    // component: QueriesComponent,
    canActivate: [LoggedInOnlyGuard],
    //loadChildren: './queries/queries.module#QueriesModule',
    loadChildren: () => import('./queries/queries.module').then(m => m.QueriesModule),
    data: { title: 'Queries' }
  },
  {
    path: 'revalidation/granted-revalidations',
    component: ExtCircGrantedRevalidationsComponent,
    canActivate: [LoggedInOnlyGuard],
    data: { title: 'Granted revalidations' }
  },
  { path: 'signin', component: LoginComponent, data: { title: 'Sign in' } },
  { path: 'diagnostics', component: DiagnosticsComponent, data: { title: 'Diagnostics' } },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [
    LoggedInOnlyGuard,
    CanDeactivateGuard
  ]
})
export class AppRoutingModule { }
