import { Address } from './Address';
import { Contact } from './Contact';
import { Registrant } from './Registrant';
import { RegistrantStatus } from './RegistrantStatus';
import { UserPreference } from './UserPreference';
import { SsoStatus } from './SsoStatus';
import { PortalStatus } from './PortalStatus';
import { SsoUser } from './SsoUser';

export class PortalUser {
  title: string;
  forenames: string;
  surname: string;
  dateOfBirth: string;
  personId: string;
  contact: Contact;
  address: Address;
  registrant: Registrant;
  reviewer: boolean;
  status: string;
  preference: UserPreference;
  registrationStatus: RegistrantStatus;
  showAccountTab = true;
  ssoStatus: SsoStatus;
  portalStatus: PortalStatus;
  checkingSsoStatus = false;
  checkingPortalStatus = false;
  ssoUser: SsoUser;
  isUpdateAllowed :boolean;
  constructor(user) {

    Object.assign(this, user);
    this.address = new Address(user.address);
    this.contact = new Contact(user.contact);
    this.isUpdateAllowed = user.isUpdateAllowed;
    if (user.registrant) {
      this.registrant = new Registrant(user.registrant);
      this.registrant.title = user.title;
      this.registrant.forenames = user.forenames;
      this.registrant.surname = user.surname;
      this.registrant.status = this.registrationStatus;
      this.registrant.postalTown = this.address.town;
    }
    if (user.roles && user.roles.indexOf('reviewer') > -1) {
      this.reviewer = true;
    }
  }

  toString() {
    return this.title +
        ' ' + this.forenames +
        ' ' + this.surname;
  }

  preferencesNotSet() {
    return !this.preference || !this.preference.comms ||
    (this.preference.comms.email === null || this.preference.comms.email === undefined ||
      this.preference.comms.text === null || this.preference.comms.text === undefined);
  }
}
