import { Component, Input} from '@angular/core';
import { AttachmentType } from './model/attachmentType';
import { PreregApplicationService } from './service/prereg.service';
import { environment } from '../../environments/environment';
import { FormValidationService } from '../dynamic/service/formValidationService';
import { FormValidator } from '../dynamic/service/FormValidator';

@Component({
    selector: 'app-application-ftp',
    templateUrl: './applicationFtp.component.html',
    providers: [
      FormValidationService,
      { provide: FormValidator, useExisting: FormValidationService }
    ],
    styleUrls: ['./applicationFtp.scss']
})
export class ApplicationFtpComponent {
    @Input() application;
    @Input() applicationType;

    constructor(
        private service: PreregApplicationService
    ) {}
}

