import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ServiceBase } from './service.base';
import { LogService } from './log.service';
import { Observable } from 'rxjs';
import { Http, RequestOptions, Headers } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomErrorHandler } from './CustomErrorHandler';



@Injectable()
export class UploadService extends ServiceBase {


  constructor(http: HttpClient, auth: AuthService, log: LogService, errorHandler: CustomErrorHandler) {
    super(http, auth, log, errorHandler);
  }

  extenuatingCircumstanceProof(formData: FormData, sessionId: string) {
    const url = `v1.0/ExtenuatingCircumstanceProof/uploads/${sessionId}`;
    return this.post(url, formData);
  }

  deleteFile(url) {
    return this.delete(url);
  }

}
