// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
           display: block;
           position: relative;
           overflow: hidden;
         }`, "",{"version":3,"sources":["webpack://./src/app/shared/round-progress/round-progress.component.ts"],"names":[],"mappings":"AAAA;WACW,cAAc;WACd,kBAAkB;WAClB,gBAAgB;SAClB","sourcesContent":[":host {\n           display: block;\n           position: relative;\n           overflow: hidden;\n         }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
