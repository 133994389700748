export class Contact {
  email: string;
  awaitingEmailConfirmation: boolean;
  mobilePhone: string;
  telephone1: string;
  telephone2: string;
  dateOfBirth: Date;
  constructor(data) {
    Object.assign(this, data);
  }
/*
  toString() {
    return [this.homePhone,
      this.workPhone,
      this.mobilePhone].filter(item => !!item)
      .join(' / ');
  }
  */
}
