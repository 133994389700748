import { Pipe, PipeTransform } from '@angular/core';
import { ExtCirc } from '../../revalidation/model/ExtCirc';

@Pipe({
  name: 'extCircSearch'
})
export class ExtCircSearchPipe implements PipeTransform {

  transform(extCircumstanceList: ExtCirc[], term: any): any {
    if (term === undefined) {
      return extCircumstanceList;
    }

    const isRegNumber = Number(term);
    if (isRegNumber) {
      return this.filterByRegNumber(extCircumstanceList, term);
    } else {
      return this.filterByFullName(extCircumstanceList, term);
    }

  }

  filterByRegNumber(extList, term) {
    return extList.filter((extCirc: ExtCirc) => {
      return extCirc.registrationNumber.includes(term)
    });
  }

  filterByFullName(extList, term) {
    return extList.filter((extCirc: ExtCirc) => {
      return extCirc.fullName.toLowerCase().includes(term)
    });
  }


}
