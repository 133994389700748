import { Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UnlockAccountResult } from './model/UnlockAccountResult';

@Component({
  templateUrl: 'unlockAccountResultDialog.component.html',
  styleUrls: ['../style/dialog.scss', './unlockAccount.scss']
})
export class UnlockAccountResultDialogComponent {

  result: UnlockAccountResult;
  request;
  messages: Array<string> = [];
  requestDetails;
  showServerResponse = false;


  constructor(public dialogRef: MatDialogRef<UnlockAccountResultDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {

    this.result = data.result;
    this.request = data.request;

    if (data.request.email && data.request.registrationNumber) {
      this.requestDetails = 'registration number / email address';
    } else if (data.request.email) {
      this.requestDetails = 'email address';
    } else if (data.request.registrationNumber) {
      this.requestDetails = 'registration number';
    }

    // 1
    if (this.result.userFound && this.result.userLinked &&
      this.result.userWasDisabled && !this.result.userIsDisabled) {
      this.messages.push('The myGPhC account for this registrant has been successfully unlocked.');
    }

    // 2
    if (this.result.userFound && !this.result.userWasDisabled && !this.result.userLinked) {
      this.messages.push(`Unable to unblock this account as there is no record of this registration number
      being associated with a MyGPhC account.
      Please obtain the email address from the registrant and try again by using the email field on this screen.`);
    }

    // 3
    if (this.result.userFound && !this.result.userLinked && this.result.userWasDisabled && !this.result.userIsDisabled) {
      this.messages.push(`The myGPhC account for this registrant has been successfully unlocked.
      You must now obtain the email address from the registrant
      and repeat the unlock process for their Azure account as otherwise they will not be able to complete the initial sign on process.`);
    }

    // 4
    if (this.result.activeDirectoryUserFound && this.result.activeDirectoryUserLinked &&
      this.result.activeDirectoryUserWasDisabled && !this.result.activeDirectoryUserIsDisabled) {
      this.messages.push(`The account for this registrant has been successfully unlocked`);
    }

    // 5
    if (this.result.activeDirectoryUserFound && !this.result.activeDirectoryUserLinked &&
      !this.result.activeDirectoryUserWasDisabled && !this.result.activeDirectoryUserIsDisabled) {
      this.messages.push(`The Azure account linked to this email address has been successfully unblocked.
        This email is not currently linked to a MyGPhC account.`);
    }

    // 6
    if (this.result.activeDirectoryUserFound && !this.result.activeDirectoryUserLinked &&
      this.result.activeDirectoryUserWasDisabled && !this.result.activeDirectoryUserIsDisabled) {
      this.messages.push(`The Azure account for this registrant has been successfully unlocked.
        You must now obtain the registration number from the registrant and
        repeat the unlock process for their myGPhC account otherwise they will not be able to complete the initial sign on process.`);
    }
  }


  close() {
    this.dialogRef.close();
  }

}
