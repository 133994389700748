import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/service/auth.service';
import { LayoutService } from '../core/service/layout.service';
import { DevService } from '../core/service/dev.service';
import { PortalUser } from './model/PortalUser';
import { AccountService } from './service/account.service';
import { SsoStatus } from './model/SsoStatus';
@Component({
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.scss']
})
export class AccountsComponent implements OnInit{
  portalUser;
  ssoUser;
  loading = false;
  preregUser;
  subHeading;
  constructor( public layout: LayoutService) { }

  ngOnInit() {
    this.SubHeading;
  }

  onBackBtnClick() {
    this.preregUser = !this.preregUser;
    this.SubHeading;
}

get SubHeading() {
return  this.subHeading = (!this.preregUser) ? 'Lookup up user accounts and perform actions to activate, deactivate or unlock accounts' :
  'Lookup student details';
}


}
