import {
  Component, Input, OnInit, Output,
  EventEmitter, ViewChild, ElementRef
} from '@angular/core';
// import { Http, RequestOptions } from '@angular/http';
import { environment } from '../../environments/environment';
import { AuthService } from '../core/service/auth.service';
import { UploadService } from '../core/service/upload.service';


@Component({
  selector: 'app-file-upload',
  templateUrl: './fileUpload.component.html',
  styleUrls: ['fileUpload.scss']
})
export class FileUploadComponent {

  @Input() sessionId: string;
  @Output() uploaded = new EventEmitter<any>();
  @ViewChild('fileInput',) fileInput: ElementRef;
  authToken: string;
  files = [];
  constructor(private auth: AuthService, private upload: UploadService) { }

  delete(upload) {
    upload.status = 'pending';
    this.upload.deleteFile(upload.meta.deleteUrl).subscribe(() => {
      const idx = this.files.indexOf(upload);
      this.files.splice(idx, 1);
      this.uploaded.emit(this.uploadedFiles);
      this.fileInput.nativeElement.value = null;
    }, error => {

    });
  }

  get uploadedFiles() {
    return this.files.filter(f => f.status === 'success');
  }

  fileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList && fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        const file: File = fileList[i];
        const uploadInfo = { file, status: 'pending', error: null, meta: null };
        this.files.push(uploadInfo);
        const formData: FormData = new FormData();
        formData.append(file.name, file, file.name);
        this.upload.extenuatingCircumstanceProof(formData, this.sessionId).subscribe(
          data => {
            uploadInfo.status = 'success';
            uploadInfo.meta = data[0];
            this.uploaded.emit(this.uploadedFiles);
          },
          error => {
            uploadInfo.status = 'error';
            uploadInfo.error = error;
          }
        );
      }
    }
  }


}
