import { Component, OnInit, HostBinding, Inject } from '@angular/core';
import { ExtCircService } from '../service/extCirc.service';
import { LayoutService } from '../../core/service/layout.service';
import { ExtCirc } from '../model/ExtCirc';
import { ActivatedRoute, Router } from '@angular/router';
import { AnswerType } from '../../dynamic/model/AnswerType';

import { ExtCircApproveRejectDialogComponent } from './extCircApproveRejectDialog.component';
import { ReviewStatus } from '../model/ReviewStatus';
import { Decision } from '../model/Decision';
import { FormValidationService } from '../../dynamic/service/formValidationService';
import { FormValidator } from '../../dynamic/service/FormValidator';
import { DynamicService } from '../../core/service/dynamic.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ExtCircRequest } from '../model/ExtCircRequest';
import { ExtCircType } from '../model/ExtCircType';
import { ExtCircReview } from '../model/ExtCircReview';
import { UserService } from '../../core/service/user.service';
import { UtcDatePipe } from '../../shared/pipe/UtcDate.pipe';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-ext-circ',
  templateUrl: './extCircItem.component.html',
  styleUrls: ['./extCircItem.scss', './extCirc.scss'],
  providers: [
    FormValidationService,
    { provide: FormValidator, useExisting: FormValidationService }
  ]
})
export class ExtCircItemComponent implements OnInit {


  loading = false;
  downloading = false;
  assigning = false;
  savingRecommendation = false;
  savingDecision = false;
  submittingDecision = false;
  item: ExtCirc;
  submissionDate: string;
  id: string;
  type = AnswerType;
  subHeading = '';
  ReviewStatus = ReviewStatus;
  isManager = true;

  review: ExtCircReview = {
    messageToRegistrant: '',
    reviewer: this.newRequest(),
    manager: this.newRequest()
  };
  previousECRecord;

  newRequest() {
    return {
      extensionDate: undefined,
      expectations: {
        totalCpds: 0,
        maxUnplanned: 0,
        peerDiscussion: false,
        reflectiveAccount: false
      },
      decision: undefined
    };
  }

  constructor(private service: ExtCircService, private userService: UserService, private layout: LayoutService,
    private route: ActivatedRoute, private router: Router,
    private utcDatePipe: UtcDatePipe, private dialog: MatDialog,
    public validator: FormValidationService, private dynamic: DynamicService) {
    this.id = this.route.snapshot.params['id'];
  }

  exit() {
    this.router.navigate(['/revalidation/extenuating-circumstances']);
  }
  ngOnInit() {
    this.layout.setFullscreen(true);
    this.loading = true;
    this.userService.getUser().subscribe(user => {
      this.isManager = user.roles && user.roles.indexOf('Managers') > -1;

      this.load();
    });

  }

  get showDecision() {
    return (this.isManager && this.item.review.status === ReviewStatus.ManagerReviewRequired) ||
      this.item.review.status === ReviewStatus.Completed;
  }

  get completed() {
    return this.item.review.status === ReviewStatus.Completed;
  }

  get validRecommendation() {
    return this.review.reviewer.decision > 1;
  }
  get validDecision() {
    return this.review.manager.decision > 1;
  }

  getReviewPayload() {

    const reviewer = this.review.reviewer;
    const payload: any = {
      id: this.item.id,
      recommendation: reviewer.decision,
      reasoning: reviewer.reasoning,
      messageToRegistrant: this.review.messageToRegistrant
    };
    if (this.item.type === ExtCircType.Extension) {
      payload.recommendedSubmissionDeadline = reviewer.extensionDate;
    }
    if (this.item.type === ExtCircType.Reduction) {
      payload.recommendedTotalCpds = reviewer.expectations.totalCpds;
      payload.recommendedMaxUnplanned = reviewer.expectations.maxUnplanned;
      payload.recommendedPeerDiscussions = reviewer.expectations.peerDiscussion ? 1 : 0;
      payload.recommendedReflectiveAccounts = reviewer.expectations.reflectiveAccount ? 1 : 0;
    }

    return payload;
  }

  getManagerPayload() {
    const manager = this.review.manager;
    const payload: any = {
      id: this.item.id,
      managersDecision: manager.decision,
      managersReasoning: manager.reasoning,
      messageToRegistrant: this.review.messageToRegistrant
    };
    if (this.item.type === ExtCircType.Extension) {
      payload.amendedSubmissionDeadline = manager.extensionDate;
    }
    if (this.item.type === ExtCircType.Reduction) {
      payload.amendedTotalCpds = manager.expectations.totalCpds;
      payload.amendedMaxUnplanned = manager.expectations.maxUnplanned;
      payload.amendedPeerDiscussions = manager.expectations.peerDiscussion ? 1 : 0;
      payload.amendedReflectiveAccounts = manager.expectations.reflectiveAccount ? 1 : 0;
    }
    return payload;
  }

  saveRecommendation() {
    const payload = this.getReviewPayload();
    this.savingRecommendation = true;
    this.service.saveRecommendation(payload).subscribe(() => {
      this.savingRecommendation = false;
      this.load();
    }, error => {
      this.savingRecommendation = false;
      console.error(error);
    });
  }

  saveDecision() {
    const payload = this.getManagerPayload();
    this.savingDecision = true;
    this.service.saveDecision(payload).subscribe(() => {
      this.savingDecision = false;
    }, error => {
      this.savingDecision = false;
      console.error(error);
    });
  }

  submitDecision() {
    const payload = this.getManagerPayload();
    this.submittingDecision = true;
    this.service.saveDecision(payload).subscribe(() => {

      this.service.submitDecision(this.id).subscribe(() => {
        this.submittingDecision = false;
        this.exit();
      }, error => {
        this.submittingDecision = false;
        console.error(error);
      });

    }, error => {
      this.submittingDecision = false;
      console.error(error);
    });

  }

  get form() {
    return this.validator.forms[0];
  }

  load() {
    this.loading = true;
    this.service.getOne(this.id).subscribe(data => {
      this.item = data;
      this.previousECRecord = this.item.anyPreviousECSubmissions;
      const createdAt = this.utcDatePipe.transform(this.item.submittedAt);
      const renewalDate = this.utcDatePipe.transform(this.item.renewalDate);
      this.subHeading = `Received from ${this.item.fullName} (${this.item.registrationNumber}) on  ${createdAt} for renewal ${renewalDate}`;

      this.dynamic.getFormTemplateById(data.dynamicFormId).subscribe(template => {
        this.validator.setForm(template);
        this.validator.loadAnswers(this.form, data.answers);

        const extendUntil = this.validator.getQuestionByShortname(this.form, 'extend_until');
        this.item.type = extendUntil.answer ? ExtCircType.Extension : ExtCircType.Reduction;

        if (data.review.status === ReviewStatus.Received) {
          this.newRecommendation();
        } else {
          this.populateReview(data.review);
        }
      });

    }, error => {
      this.loading = false;
    });
  }

  populateReview(review) {
    this.loading = false;
    const reviewer = this.review.reviewer;
    const manager = this.review.manager;

    if (this.item.type === ExtCircType.Extension) {
      reviewer.extensionDate = review.recommendedSubmissionDeadline;
      manager.extensionDate = review.amendedSubmissionDeadline || review.recommendedSubmissionDeadline;
    } else {
      reviewer.expectations.maxUnplanned = review.recommendedMaxUnplanned;
      reviewer.expectations.totalCpds = review.recommendedTotalCpds;
      if (review.recommendedPeerDiscussions !== null) {
        reviewer.expectations.peerDiscussion = review.recommendedPeerDiscussions > 0 ? true : false;
      }
      if (review.recommendedReflectiveAccounts !== null) {
        reviewer.expectations.reflectiveAccount = review.recommendedReflectiveAccounts > 0 ? true : false;
      }

      if (review.amendedPeerDiscussions === null) {
        manager.expectations.peerDiscussion = reviewer.expectations.peerDiscussion;
      } else {
        manager.expectations.peerDiscussion = review.amendedPeerDiscussions > 0 ? true : false;
      }

      if (review.amendedReflectiveAccounts === null) {
        manager.expectations.reflectiveAccount = reviewer.expectations.reflectiveAccount;
      } else {
        manager.expectations.reflectiveAccount = review.amendedReflectiveAccounts > 0 ? true : false;
      }

      if (review.amendedMaxUnplanned === null) {
        manager.expectations.maxUnplanned = reviewer.expectations.maxUnplanned;
      } else {
        manager.expectations.maxUnplanned = review.amendedMaxUnplanned;
      }

      if (review.amendedTotalCpds === null) {
        manager.expectations.totalCpds = reviewer.expectations.totalCpds;
      } else {
        manager.expectations.totalCpds = review.amendedTotalCpds;
      }

    }

    reviewer.decision = review.recommendation;
    reviewer.reasoning = review.reasoning;
    manager.decision = review.managersDecision;
    manager.reasoning = review.managersReasoning;
    this.review.messageToRegistrant = review.messageToRegistrant;

    this.loading = false;
  }

  newRecommendation() {

    const request = this.review.reviewer;
    if (this.item.type === ExtCircType.Extension) {
      const extendUntil = this.validator.getQuestionByShortname(this.form, 'extend_until');
      request.extensionDate = extendUntil.answer;
    } else {
      const totalCpds = this.validator.getQuestionByShortname(this.form, 'total_cpds');
      if (totalCpds && totalCpds.answer) {
        request.expectations.totalCpds = parseInt(totalCpds.answer, 10);
      }
      const maxUnplanned = this.validator.getQuestionByShortname(this.form, 'max_unplanned');
      if (maxUnplanned && maxUnplanned.answer) {
        request.expectations.maxUnplanned = parseInt(maxUnplanned.answer, 10);
      }
      const peerDiscussion = this.validator.getQuestionByShortname(this.form, 'include_peer_discussion');
      if (peerDiscussion && peerDiscussion.answer) {
        const peerDiscussionAnswer = peerDiscussion.alternatives.find(a => a.id === peerDiscussion.answer);
        if (peerDiscussionAnswer) {
          request.expectations.peerDiscussion = peerDiscussionAnswer.name === 'Y' ? true : false;
        }
      }
      const reflectiveAccount = this.validator.getQuestionByShortname(this.form, 'include_reflective_account');
      if (reflectiveAccount && reflectiveAccount.answer) {
        const reflectiveAccountAnswer = reflectiveAccount.alternatives.find(a => a.id === reflectiveAccount.answer);
        if (reflectiveAccountAnswer) {
          request.expectations.reflectiveAccount = reflectiveAccountAnswer.name === 'Y' ? true : false;
        }
      }
    }
    this.loading = false;

  }

  print() {
    window.print();
  }

  download(file) {
    this.downloading = true;
    this.service.download(file.url).subscribe(blob => {
      this.downloading = false;
   
        const url = URL.createObjectURL(blob);
        const element = document.createElement('a');
        element.href = url;
        element.setAttribute('download', file.filename);
        document.body.appendChild(element); // Append the element to work in firefox
        element.click();
    
    });
  }

  getIcon(file) {
    const bits = file.filename.split('.');
    const extension = bits[bits.length - 1];
    switch (extension) {
      case 'pdf':
        return 'fa fa-file-pdf-o';
      case 'png':
      case 'jpg':
      case 'bmp':
      case 'gif':
        return 'fa fa-file-image-o';
      case 'doc':
      case 'docx':
        return 'fa fa-file-word-o';
      case 'zip':
      case 'rar':
        return 'fa fa-file-archive-o';
      default:
        return 'fa fa-file-o';
    }
  }

  approve() {
    this.launchDialog({
      approve: true,
      id: this.id
    });
  }

  reject() {
    this.launchDialog({
      reject: true,
      id: this.id
    });
  }

  launchDialog(data) {
    const dialogRef = this.dialog.open(ExtCircApproveRejectDialogComponent, { data });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && !result.cancelled) {
        this.exit();
      }
    });
  }

}
