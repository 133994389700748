export enum RegistrantStatus {
  Unknown = 0,
  PreRegistrationTrainee = 717750000,
  PreRegistrationTraineeS = 717750014,
  OSPAPAssessment = 717750001,
  OSPAPClassList = 717750002,
  MPharmClassList = 717750003,
  MPharmClassListS = 717750013,
  Applicant = 717750004,
  IneligibleToRegister = 717750005,
  ReadyForRegistration = 717750015,

  Registered = 717750006,
  Erased = 717750007,
  Suspended = 717750008,
  Terminated = 717750016,
  RemovedFailedToRenew = 717750009,
  RemovedCPDNonCompliance = 717750010,
  RemovedVoluntary = 717750011,
  Deleted = 717750012,
  TemporaryRegistration = 981360000,
  RemovedRevalNonCompliance = 981360003
}
