import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { AccountService } from './service/account.service';
import { PortalUserLookup } from './model/PortalUserLookup';
import { PortalUser } from './model/PortalUser';
import { SsoUser } from './model/SsoUser';
import { SsoStatus } from './model/SsoStatus';
import { PreregUsers } from './model/preregUsers';

@Component({
  selector: 'app-user-lookup',
  templateUrl: './userLookup.component.html',
  styleUrls: ['./userLookup.scss']
})
export class UserLookupComponent {

  lookupModel: PortalUserLookup = {
    registrationNumber: '',
    email: '',
    personId: '',
    gphcId: '',
    PreEntryNumber: ''
  };
  loading = false;
  loadingStudent = false;
  checkingPortalMessage = 'Looking for user...';
  checkingPreregMessage = 'Looking for student...';
  checkingADB2CMessage = 'Checking ADB2C...';
  loadingMessage = this.checkingPortalMessage;
  loadingStudentMessage = this.checkingPreregMessage;
  errors = [];
  studentErrors = [];
  @Output() gotPortalUser = new EventEmitter<PortalUser>();
  @Output() gotSsoUser = new EventEmitter<SsoUser>();
  @Output() loadingChange = new EventEmitter<boolean>();
  @Output() loadingStudentChange = new EventEmitter<boolean>();
  portalUsers: Array<PortalUser> = [];
  preregUsers: Array<PreregUsers> = [];
  @Output() gotPreregUser = new EventEmitter<PreregUsers>();
  constructor(private service: AccountService) { }

  get allowSubmit() {
    return this.lookupModel.registrationNumber ||
      this.lookupModel.email ||
      this.lookupModel.gphcId ||
      this.lookupModel.personId

  }
  get allowStudentSubmit() {
    return this.lookupModel.PreEntryNumber;
  }

  lookup() {
    this.loadingMessage = this.checkingPortalMessage;
    if (this.loading) { return; }
    if (!this.allowSubmit) { return; }
    const emailOnly = this.lookupModel.email && !this.lookupModel.registrationNumber
      && !this.lookupModel.personId && !this.lookupModel.gphcId;
    this.setLoading(true);
    this.setStudentLoading(false);
    this.errors = [];
    this.preregUsers = [];
    this.service.portalUserLookup(this.lookupModel).subscribe(result => {
      this.setLoading(false);
      this.gotPortalUser.emit(new PortalUser(result));
      this.clearForm();
    }, (err) => {
      this.setLoading(false);
      if (err.message) {
        if (emailOnly && err.message.toLowerCase().indexOf('user could not be found') > -1) {
          this.checkIfSsoAccountExists(this.lookupModel.email);
        } else {
          this.errors = [err.message];
        }
      }
      if (err.validationErrors) {
        this.errors = err.validationErrors;
      }
    });
  }

  lookupStudent() {
    this.loading= false;
    this.setLoading(false);
    this.loadingStudentMessage = this.checkingPreregMessage;
    if (this.loadingStudent) { return; }
    if (!this.allowStudentSubmit) { return; }
    const emailOnly = !this.lookupModel.PreEntryNumber;
    this.setStudentLoading(true);
    this.studentErrors = [];
    this.portalUsers = [];
    this.service.portalUserLookup(this.lookupModel).subscribe(result => {
      this.setStudentLoading(false);
      this.gotPreregUser.emit(new PreregUsers(result));
      this.clearForm();
    }, (error) => {
      this.setStudentLoading(false);
      if (error.message) {
        if (emailOnly && error.message.toLowerCase().indexOf('user could not be found') > -1) {
          this.checkIfSsoAccountExists(this.lookupModel.email);
        } else {
          this.studentErrors = [error.message];
        }
      }
      if (error.validationErrors) {
        this.studentErrors = error.validationErrors;
      }
    });
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    this.loadingChange.emit(loading);
  }

  setStudentLoading(loadingStudent: boolean) {
    this.loadingStudent = loadingStudent;
    this.loadingStudentChange.emit(loadingStudent);
  }

  clearForm() {
    this.lookupModel = {
      registrationNumber: '',
      email: '',
      personId: '',
      gphcId: '',
      PreEntryNumber: ''
    };
  }

  checkIfSsoAccountExists(email) {
    this.setLoading(true);
    this.setStudentLoading(true);
    this.loadingMessage = this.checkingADB2CMessage;
    this.loadingStudentMessage = this.checkingADB2CMessage;
    this.service.getAccountStatus(null, email).subscribe(result => {
      const ssoUser: SsoUser = result.adb2cAccountStatusModel;
      if (ssoUser.isDisabled) {
        ssoUser.ssoStatus = SsoStatus.Locked;
      } else {
        ssoUser.ssoStatus = SsoStatus.Created;
      }
      this.setLoading(false);
      this.setStudentLoading(false);
      this.gotSsoUser.emit(ssoUser);
      this.clearForm();
    }, error => {
      if (error.message && error.message.indexOf('account not found') > -1) {
        this.errors = ['Neither myGPhC user nor azure user found'];
      } else {
        this.errors = ['Unknown error'];
      }
      this.setLoading(false);
      this.setStudentLoading(false);
    });
  }


}
