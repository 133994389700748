import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../core/service/auth.service';
import { LayoutService } from '../core/service/layout.service';
import { DevService } from '../core/service/dev.service';
import { PortalUser } from './model/PortalUser';
import { SsoUser } from './model/SsoUser';
import { AccountService } from './service/account.service';
import { SsoStatus } from './model/SsoStatus';
import { PortalStatus } from './model/PortalStatus';
import { ConfirmDialogComponent } from '../shared/confirmDialog.component';
import { UserService } from '../core/service/user.service';
import { MatDialog } from '@angular/material/dialog';
import { PreregUsers } from './model/preregUsers';
import { RegistrantType } from './model/RegistrantType';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-user-list',
  templateUrl: './userList.component.html',
  styleUrls: ['./userList.scss']

})
export class UserListComponent {

  portalUsers: Array<PortalUser> = [];
  ssoUsers: Array<SsoUser> = [];
  highlightedPortalUser: PortalUser;
  highlightedSsoUser: SsoUser;
  SsoStatus = SsoStatus;
  PortalStatus = PortalStatus;
  selectedPortalUser: PortalUser;
  selectedSsoUser: SsoUser;
  loading = false;
  loadingStudent = false;
  loadingMessage = 'loading';
  loadingStudentMessage = 'loading student details';
  resultMessage;
  resultStudentMessage;
  validationErrors = [];
  LoggedInUser: any;
  @Output() loadingChange = new EventEmitter<boolean>();
  @Output() loadingStudentChange = new EventEmitter<boolean>();
  preregUser: Array<PreregUsers> = [];
  highlightedPreregUser: PreregUsers;
  preregUsers: Array<PreregUsers> = [];
  type = RegistrantType;
  loadRegUser = false;
  loadPreregUser = false;

  constructor(private service: AccountService, private dialog: MatDialog, private userService: UserService,
    private route: ActivatedRoute,
    private router: Router) {
    this.userService.getUser().subscribe(user => {
      this.LoggedInUser = user;
    });
   
   
  }

  @Input('portalUser') set setPortalUser(user: PortalUser) {
    if (!user) { return; }
    this.resultMessage = '';
    this.preregUsers = [];
    const existing = this.portalUsers.filter(u => user.personId === u.personId);
    if (!existing.length) {
      this.portalUsers.push(user);
      this.getSsoStatus(user);
    }
    this.highlightedPortalUser = user;
    setTimeout(() => {
      this.highlightedPortalUser = undefined;
    }, 1100);
  }

  @Input('ssoUser') set setSsoUser(user: SsoUser) {
    if (!user) { return; }
    this.resultMessage = '';
    this.preregUsers = [];
    const existing = this.ssoUsers.filter(u => user.email === u.email);
    if (!existing.length) {
      this.ssoUsers.push(user);
    }
    this.highlightedSsoUser = user;
    setTimeout(() => {
      this.highlightedSsoUser = undefined;
    }, 1100);
  }

  @Input('preregUser') set setPreregUser(user: PreregUsers) {
    if (!user) { return; }
    this.resultStudentMessage = '';
    this.portalUsers = [];
    this.ssoUsers = [];
    const existing = this.preregUsers.filter(u => user.personId === u.personId);
    if (!existing.length) {
      this.preregUsers.push(user);
      this.getSsoStatus(user);
    }
  }

  toggleSelectedPortalUser(user: PortalUser) {
    this.resultMessage = '';
    if (this.selectedPortalUser === user) {
      this.selectedPortalUser = undefined;
    } else {
      this.selectedPortalUser = user;
    }
  }

  clearUserList() {
    this.portalUsers = [];
    this.ssoUsers = [];
    this.preregUsers = [];
    this.selectedSsoUser = undefined;
    this.selectedPortalUser = undefined;
    this.resultMessage = '';
  }
  clearStudentList() {
    this.resultStudentMessage = '';
    this.preregUsers = [];
    this.portalUsers = [];
    this.ssoUsers = [];
    this.selectedSsoUser = undefined;
    this.selectedPortalUser = undefined; 
  }

  toggleSelectedSsoUser(user: SsoUser) {
    this.resultMessage = '';
    if (this.selectedSsoUser === user) {
      this.selectedSsoUser = undefined;
    } else {
      this.selectedSsoUser = user;
    }
  }

  setLoading(loading: boolean) {
    if (loading) {
      this.resultMessage = '';
    }
    this.loading = loading;
    this.loadingChange.emit(loading);
  }

  setStudentLoading(loadingStudent: boolean) {
    if (loadingStudent) {
      this.resultStudentMessage = '';
    }
    this.loadingStudent = loadingStudent;
    this.loadingStudentChange.emit(loadingStudent);
  }

  getSsoStatus(user: PortalUser | PreregUsers) {

    if (!user.contact?.email) {
      user.ssoStatus = SsoStatus.NotLinked;
    }

    user.checkingPortalStatus = true;
    this.service.getAccountStatus(user.personId, user.contact?.email).subscribe(result => {
      if (result.userAccountStatusModel) {
        if (result.userAccountStatusModel.isDisabled) {
          user.portalStatus = PortalStatus.Locked;
        } else {
          user.portalStatus = PortalStatus.Activated;
        }
      }

      if (result.adb2cAccountStatusModel) {
        if (result.adb2cAccountStatusModel.isDisabled) {
          user.ssoStatus = SsoStatus.Locked;
        } else {
          user.ssoStatus = SsoStatus.Linked;
        }
      } else {
        user.ssoStatus = SsoStatus.NotLinked;
        if (user.portalStatus !== PortalStatus.Locked) {
          user.portalStatus = PortalStatus.PendingActivation;
        }
      }

      user.checkingPortalStatus = false;
    }, error => {
      if (error.message && error.message.indexOf('account not found') > -1) {
        user.portalStatus = PortalStatus.PendingActivation;
        this.checkIfSsoAccountExists(user);
      } else if (error.message && error.message.indexOf('account mismatch') > -1) {
        user.portalStatus = PortalStatus.Mismatched;
      } else {
        user.portalStatus = PortalStatus.Error;
      }
      user.checkingPortalStatus = false;
    });

  }

  checkIfSsoAccountExists(user: PortalUser | PreregUsers) {
    if (!user.contact?.email) {
      user.ssoStatus = SsoStatus.NotLinked;
      return;
    }
    user.checkingSsoStatus = true;
    this.service.getAccountStatus(null, user.contact?.email).subscribe(result => {
      if (result.adb2cAccountStatusModel) {
        if (result.adb2cAccountStatusModel.isDisabled) {
          user.ssoStatus = SsoStatus.Locked;
        } else {
          user.ssoStatus = SsoStatus.Created;
        }
      }
      user.checkingSsoStatus = false;
    }, error => {
      if (error.message && error.message.indexOf('account not found') > -1) {
        user.ssoStatus = SsoStatus.NotLinked;
      } else {
        user.ssoStatus = SsoStatus.Error;
      }
      user.checkingSsoStatus = false;
    });
  }

  warn(title, message) {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title,
          message
        }
      });
      dialogRef.afterClosed().subscribe(confirmed => {
        if (confirmed) {
          resolve(true);
        }
      });
    });

  }

  linkSelected() {
    const doit = () => {
      this.setLoading(true);
      this.setStudentLoading(false);
      this.loadingMessage = 'Attempting to link account...';
      this.service.linkAccount(this.selectedPortalUser.personId, this.selectedSsoUser.email).subscribe(result => {
        this.resultMessage = result;
        this.loadingMessage = '';
        const pidx = this.portalUsers.indexOf(this.selectedPortalUser);
        this.portalUsers.splice(pidx, 1);
        const sidx = this.ssoUsers.indexOf(this.selectedSsoUser);
        this.ssoUsers.splice(sidx, 1);
        this.loadPortalUser(this.selectedPortalUser.personId);
      }, error => {
        console.error(error);
        this.setLoading(false);
        this.setStudentLoading(false);
      });
    };

    if (this.selectedPortalUser.isUpdateAllowed) {
      this.warn('Link accounts', `Are you sure you want to link ${this.selectedPortalUser} to
    ${this.selectedSsoUser.email}`)
        .then(doit);
    } else {
      if (this.LoggedInUser.roles.includes('Super-Admins')) {
        this.warn('Link accounts', `This is a restricted record.  Please click on OK to 
        confirm that you have checked the reason for the restriction on CRM and 
        want to update this record`)
          .then(doit);

      } else {
        this.warn('Link accounts', `The record that you are trying to update is restricted.  
        Please ask your manager or a senior member of staff to carry out this action instead`);
      }
    }

  }


  unlinkSelected() {
    const doit = () => {
      this.setLoading(true);
      this.setStudentLoading(false);
      this.loadingMessage = 'Attempting to unlink account...';
      this.service.unlinkAccount(this.selectedPortalUser.personId, this.selectedPortalUser.contact?.email).subscribe(result => {
        this.resultMessage = result;
        this.loadingMessage = '';
        const pidx = this.portalUsers.indexOf(this.selectedPortalUser);
        this.portalUsers.splice(pidx, 1);
        this.loadPortalUser(this.selectedPortalUser.personId);
      }, error => {
        console.error(error);
        this.setLoading(false);
        this.setStudentLoading(false);
      });
    };

    if (this.selectedPortalUser.isUpdateAllowed) {
      this.warn('Unlink accounts', `Are you sure you want to unlink ${this.selectedPortalUser} from
    ${this.selectedPortalUser.contact?.email}`)
        .then(doit);
    } else {
      if (this.LoggedInUser.roles.includes('Super-Admins')) {
        this.warn('Unlink accounts', `This is a restricted record.  Please click on OK to 
        confirm that you have checked the reason for the restriction on CRM and 
        want to update this record`)
          .then(doit);
      } else {
        this.warn('Unlink accounts', `The record that you are trying to update is restricted.  
        Please ask your manager or a senior member of staff to carry out this action instead`);
      }
    }

  }

  loadPortalUser(personId) {
    this.service.portalUserLookup({ personId }).subscribe(result => {
      
      this.setLoading(false);
      this.setStudentLoading(false);
      this.selectedPortalUser = undefined;
      this.selectedSsoUser = undefined;
      const user = new PortalUser(result);
      this.portalUsers.push(user);
      this.getSsoStatus(user);
    });


  }

  get canUnlockSelectedPortalUser() {
    return this.selectedPortalUser && !this.selectedSsoUser &&
      (this.selectedPortalUser.ssoStatus === SsoStatus.Locked || this.selectedPortalUser.portalStatus === PortalStatus.Locked);
  }

  get canUnlockSelectedSsoUser() {
    return this.selectedSsoUser && !this.selectedPortalUser && this.selectedSsoUser.isDisabled;
  }

  get canLockSelectedSsoUser() {
    return !this.selectedPortalUser && this.selectedSsoUser &&
      this.selectedSsoUser.ssoStatus !== SsoStatus.Locked;
  }

  get canLockSelectedPortalUser() {
    return !this.selectedSsoUser && this.selectedPortalUser &&
      this.selectedPortalUser.portalStatus !== PortalStatus.Locked &&
      this.selectedPortalUser.registrant &&
      this.selectedPortalUser.ssoStatus !== SsoStatus.Locked;
  }

  unlockSelectedPortalUser() {
    const doit = () => {
      this.loadingMessage = 'Attempting to unlock account...';
      this.setLoading(true);
      this.setStudentLoading(false);
      this.validationErrors = [];
      const registrationNumber = this.selectedPortalUser.registrant ? this.selectedPortalUser.registrant.registrationNumber : undefined;
      this.service.unlock({
        registrationNumber,
        email: this.selectedPortalUser.contact?.email, active: true
      }).subscribe(result => {
        this.resultMessage = this.getUnlockResponseMessage(result);
        this.loadingMessage = '';
        const pidx = this.portalUsers.indexOf(this.selectedPortalUser);
        this.portalUsers.splice(pidx, 1);
        this.loadPortalUser(this.selectedPortalUser.personId);
      }, (err) => {
        this.setLoading(false);
        this.setStudentLoading(false);
        if (err.validationErrors) {
          this.validationErrors = err.validationErrors;
        }
      });
    }
    if (this.selectedPortalUser.isUpdateAllowed) {
      this.warn('Unlock account', `Are you sure you want to unlock ${this.selectedPortalUser}`)
        .then(doit);
    } else {
      if (this.LoggedInUser.roles.includes('Super-Admins')) {
        this.warn('Unlock accounts', `This is a restricted record.  Please click on OK to 
        confirm that you have checked the reason for the restriction on CRM and 
        want to update this record`)
          .then(doit);
      } else {
        this.warn('Unlock accounts', `The record that you are trying to update is restricted.  
        Please ask your manager or a senior member of staff to carry out this action instead`);
      }
    }
  }

  lockSelectedPortalUser() {
    const doit = () => {
      this.loadingMessage = 'Attempting to lock account...';
      this.setLoading(true);
      this.setStudentLoading(false);
      this.validationErrors = [];
      this.service.unlock({
        registrationNumber: this.selectedPortalUser.registrant.registrationNumber,
        email: this.selectedPortalUser.contact?.email, active: false
      }).subscribe(result => {
        this.resultMessage = 'Account locked successfully';
        this.loadingMessage = '';
        const pidx = this.portalUsers.indexOf(this.selectedPortalUser);
        this.portalUsers.splice(pidx, 1);
        this.loadPortalUser(this.selectedPortalUser.personId);
      }, (err) => {
        this.setLoading(false);
        this.setStudentLoading(false);
        if (err.validationErrors) {
          this.validationErrors = err.validationErrors;
        }
      });
    };

    if (this.selectedPortalUser.isUpdateAllowed) {
      this.warn('Lock account', `Are you sure you want to lock ${this.selectedPortalUser}`)
        .then(doit);
    } else {
      if (this.LoggedInUser.roles.includes('Super-Admins')) {
        this.warn('Lock accounts', `This is a restricted record.  Please click on OK to 
        confirm that you have checked the reason for the restriction on CRM and 
        want to update this record`)
          .then(doit);
      } else {
        this.warn('Lock accounts', `The record that you are trying to update is restricted.  
        Please ask your manager or a senior member of staff to carry out this action instead`);
      }
    }

  }

  unlockSelectedSsoUser() {
    this.loadingMessage = 'Attempting to unlock account...';
    this.setLoading(true);
    this.setStudentLoading(false);
    this.validationErrors = [];
    this.service.unlock({
      registrationNumber: undefined,
      email: this.selectedSsoUser.email, active: true
    }).subscribe(result => {
      this.resultMessage = this.getUnlockResponseMessage(result);
      this.loadingMessage = '';
      const sidx = this.ssoUsers.indexOf(this.selectedSsoUser);
      this.ssoUsers.splice(sidx, 1);
      this.loadSsoUser(this.selectedSsoUser.email);

    }, (err) => {
      this.setLoading(false);
      this.setStudentLoading(false);
      if (err.validationErrors) {
        this.validationErrors = err.validationErrors;
      }
    });
  }

  lockSelectedSsoUser() {
    const doit = () => {
      this.loadingMessage = 'Attempting to lock account...';
      this.setLoading(true);
      this.setStudentLoading(false);
      this.validationErrors = [];
      this.service.unlock({
        registrationNumber: undefined,
        email: this.selectedSsoUser.email, active: false
      }).subscribe(result => {
        this.resultMessage = 'Account locked successfully';
        this.loadingMessage = '';
        const sidx = this.ssoUsers.indexOf(this.selectedSsoUser);
        this.ssoUsers.splice(sidx, 1);
        this.loadSsoUser(this.selectedSsoUser.email);
      }, (err) => {
        this.setLoading(false);
        this.setStudentLoading(false);
        if (err.validationErrors) {
          this.validationErrors = err.validationErrors;
        }
      });
    };

    this.warn('Lock account', `Are you sure you want to lock ${this.selectedSsoUser.email}`)
      .then(doit);

  }

  loadSsoUser(email) {
    this.service.getAccountStatus(null, email).subscribe(result => {
      const ssoUser: SsoUser = result.adb2cAccountStatusModel;
      if (ssoUser.isDisabled) {
        ssoUser.ssoStatus = SsoStatus.Locked;
      } else {
        ssoUser.ssoStatus = SsoStatus.Created;
      }
      this.selectedSsoUser = undefined;
      this.ssoUsers.push(ssoUser);
      this.setLoading(false);
      this.setStudentLoading(false);
    });
  }


  getUnlockResponseMessage(result) {
    const messages = [];
    // 1
    if (result.userFound && result.userLinked &&
      result.userWasDisabled && !result.userIsDisabled) {
      messages.push('The myGPhC account for this registrant has been successfully unlocked.');
    }

    // 2
    if (result.userFound && !result.userWasDisabled && !result.userLinked) {
      messages.push(`Unable to unblock this account as there is no record of this registration number
      being associated with a MyGPhC account.
      Please obtain the email address from the registrant and try again by using the email field on this screen.`);
    }

    // 3
    if (result.userFound && !result.userLinked && result.userWasDisabled && !result.userIsDisabled) {
      messages.push(`The myGPhC account for this registrant has been successfully unlocked.
      You must now obtain the email address from the registrant
      and repeat the unlock process for their Azure account as otherwise they will not be able to complete the initial sign on process.`);
    }

    // 4
    if (result.activeDirectoryUserFound && result.activeDirectoryUserLinked &&
      result.activeDirectoryUserWasDisabled && !result.activeDirectoryUserIsDisabled) {
      messages.push(`The Azure account for this registrant has been successfully unlocked.`);
    }

    // 5
    if (result.activeDirectoryUserFound && !result.activeDirectoryUserLinked &&
      !result.activeDirectoryUserWasDisabled && !result.activeDirectoryUserIsDisabled) {
      messages.push(`The Azure account linked to this email address has been successfully unblocked.
        This email is not currently linked to a MyGPhC account.`);
    }

    // 6
    if (result.activeDirectoryUserFound && !result.activeDirectoryUserLinked &&
      result.activeDirectoryUserWasDisabled && !result.activeDirectoryUserIsDisabled) {
      messages.push(`The Azure account for this registrant has been successfully unlocked.
        You must now obtain the registration number from the registrant and
        repeat the unlock process for their myGPhC account otherwise they will not be able to complete the initial sign on process.`);
    }
    return messages.join(' ');
  }


}

