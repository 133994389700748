import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceBase } from '../../core/service/service.base';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '../../core/service/auth.service';
import { LogService } from '../../core/service/log.service';
import { CustomErrorHandler } from '../../core/service/CustomErrorHandler';
import { RegApplication } from '../model/RegApplication';
import { map } from 'rxjs/operators';

@Injectable()
export class TechnicianService extends ServiceBase {

    constructor(
        http: HttpClient,
        auth: AuthService,
        log: LogService,
        errorHandler: CustomErrorHandler
    ) {
        super(http, auth, log, errorHandler);
    }

    
  getQualificationList(qualificationType: number) {
    return super.get(`v1.0/qualification/?QualificationType=${qualificationType}`)
      .pipe(map(data => data.qualifications));
  }

}
