import { Pipe, PipeTransform } from '@angular/core';
import { getLocaleDayNames } from '../../../../node_modules/@angular/common';

@Pipe({ name: 'utcDate' })
export class UtcDatePipe implements PipeTransform {
  transform(value: string): any {

    if (value === undefined) {
      return undefined;
    }
    if (value === null) {
      return null;
    }

    const date = new Date(value.split('T')[0]);
    const ticks = date.getTime();
    const offset = date.getTimezoneOffset();
    const adjusted = ticks + (offset * 60 * 1000);
    const adjustedDate = new Date(adjusted);
    const year = adjustedDate.getFullYear();
    let month = (adjustedDate.getMonth() + 1).toString();
    if (month.length === 1) {
      month = '0' + month;
    }
    let day = adjustedDate.getDate().toString();
    if (day.length === 1) {
      day = '0' + day;
    }


    return `${day}/${month}/${year}`;

  }
}
