import { Component, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { LayoutService } from '../../core/service/layout.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReviewService } from '../service/review.service';
import { ReviewAllocationSplitType } from '../model/RevalAllocationSplitType';

enum SearchOption {
  InputByPercentage,
  InputByNumber
}

@Component({
  templateUrl: './reviewCohortCreate.component.html',
  selector: 'app-review-cohort-create',
  styleUrls: ['../list.scss', './review.scss']
})


export class ReviewCohortCreateComponent implements OnInit{

  constructor(private router: Router, public layout: LayoutService, private service: ReviewService) { }

  ngOnInit(): void {
    }

  generating = false;
  cohortSize;
  reviewDeadline;
  noResults = false;

  @Output() created = new EventEmitter();

 //SearchOption = SearchOption;
  searchBy = ReviewAllocationSplitType.Default;
  pharmacistsByNumber;
  techniciansByNumber;
  pharmacistsByPercentage;
  technicianByPercentage;
  ReviewAllocationSplitType = ReviewAllocationSplitType;
  PharmacistAllocation;
  TechnicianAllocation;
 
  
  get inputByNumber() {
    return this.searchBy === ReviewAllocationSplitType.Absolute;
  }

  get inputByPercentage() {
    return this.searchBy === ReviewAllocationSplitType.Percentage;
  }

  get inputByDefault() {
    return this.searchBy === ReviewAllocationSplitType.Default;
  }
  get NumberOfRegistrantsAlloted() {
    return (this.inputByNumber && (this.PharmacistAllocation ==='' || this.TechnicianAllocation ===''));
  }
  get NumberOfRegistrantsLesserThanAllocated() {
    return (this.PharmacistAllocation + this.TechnicianAllocation) !== this.cohortSize && 
    (this.PharmacistAllocation !=='' && this.TechnicianAllocation !=='') ;
  }
  get NumberOfRegistrantsAllotedByPercent() {
    return (this.inputByPercentage && (this.PharmacistAllocation ==='' || this.TechnicianAllocation ===''));
  }

  generate() {
    this.generating = true;
    this.service.createReviewCohort(this.cohortSize, this.reviewDeadline, this.PharmacistAllocation,
      this.TechnicianAllocation, this.searchBy).subscribe(() => {
      this.generating = false;
      this.created.emit();
    },
      error => {
        this.generating = false;
        this.noResults = (error.status === 400 || error.status === 404 || error.status === 500);
      });
  }

  setFocus() {
     this.PharmacistAllocation = '';
     this.TechnicianAllocation = '';
  }


  updateSetting(event) {
    if(event.value===0){
this.PharmacistAllocation = '';
this.TechnicianAllocation = '';
    }else{
      this.PharmacistAllocation = event.value;
      this.TechnicianAllocation = 100 - this.PharmacistAllocation;
    }
   
  }


}