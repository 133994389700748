import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Review } from '../../model/Review';
import { ReviewStage } from '../../model/ReviewStage';
import { FeedbackConfirmation } from '../../model/FeedbackConfirmation';
import { PerformanceIndicator } from '../../model/PerformanceIndicator';
import { revalidationItemTypes } from '../../model/revalidationItemTypes';
import { ReviewService } from '../../service/review.service';
import { UserService } from '../../../core/service/user.service';
import { Feedback } from '../../model/Feedback';


@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.scss']
})
export class AssessmentComponent implements OnInit {

  @Input() review: Review;
  @Input() editing = false;
  @Output() feedbackEdited = new EventEmitter();
  ReviewStage = ReviewStage;
  FeedbackConfirmation = FeedbackConfirmation;
  PerformanceIndicator = PerformanceIndicator;
  helpVisible = false;
  title: string;
  text: string;
  coreCriteriaFeedbackRequired = false;
  feedback;
  sendingToRemediation = false;
  sentToRemediation = false;
  canSendToRemediation = false;
  revalidationReviewQAManager;

  constructor(private service: ReviewService, private userService: UserService) { }


  ngOnInit() {
    this.title = 'Assessment';
    this.feedback = this.review.feedbackEdit;
    this.sentToRemediation = this.review.inRemediation;
    this.userService.getUser().subscribe(user => {
      this.revalidationReviewQAManager = user.roles && user.roles.indexOf('RevalidationReviewQAManagers') > -1;
      this.canSendToRemediation = this.review.stage === ReviewStage.CoreCriteriaNotMet && this.revalidationReviewQAManager;
    });
  }

  toggleHelp() {
    this.helpVisible = !this.helpVisible;
  }

  setAssessment(key) {
    this.feedback.performanceIndicator = key;
    this.feedbackEdited.emit();
  }

  remediate() {
    this.sendingToRemediation = true;
    this.service.sendToRemediation(this.review.id).subscribe(() => {
      this.sendingToRemediation = false;
      this.sentToRemediation = true;
    }, error => {
      this.sendingToRemediation = false;
    });
  }

  isCoreCriteriaFeedbackRequired() {
    return +this.feedback.performanceIndicator === +PerformanceIndicator.CoreCriteriaNotMet ||
      +this.feedback.performanceIndicator === +PerformanceIndicator.ReviewNextYear;
  }

  updateCoreCriteriaFeedback(content) {
    this.feedback.coreCriteriaFeedback = content;
    this.feedbackEdited.emit();
  }
}

