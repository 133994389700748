import { Component, OnInit} from '@angular/core';


@Component({
  templateUrl: './revalidation.component.html',
  styleUrls: ['../style/dashboard.scss']
})
export class RevalidationComponent implements OnInit {


  ngOnInit() { }


}
