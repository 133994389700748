
import { tap, map, catchError, mergeMap } from 'rxjs/operators';
import { Observable, ObservableInput } from 'rxjs';
import { AuthService } from './auth.service';
import { LogService } from './log.service';
import { Http, RequestOptions, Headers, URLSearchParams, ResponseType } from '@angular/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { CustomErrorHandler } from './CustomErrorHandler';
import { ResponseContentType } from '@angular/http';

export abstract class ServiceBase {

  mock: any;
  protected log: LogService;

  constructor(
    protected http: HttpClient,
    private auth: AuthService,
    log: LogService,
    private errorHandler: CustomErrorHandler
  ) {
    this.log = log;
  }

  private handleError(err, caught) {
    //  if (err.status !== 200) {
    return this.errorHandler.handleServerError(err, caught);
    //  } else {
    //    return Observable.of();
    //  }
  }


  protected get(url: string, queryParams?: any, pager?: { totalCount: number }): Observable<any> {

    return this.auth.getToken().pipe(
      mergeMap(token => {
        const endpoint = `${environment.api.root}/${url}`;
        const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
        let params = new HttpParams();
        if (queryParams) {
          for (const key in queryParams) {
            if (queryParams.hasOwnProperty(key) && queryParams[key] !== '' && queryParams[key] !== undefined && queryParams[key] !== null) {
              params = params.append(key, queryParams[key]);
            }
          }
        }

        return this.http.get(endpoint, { headers, params }).pipe(
          map(response => {
            if (pager) {
              pager.totalCount = (<any>response).totalCount;
            }
            return (<any>response).data;
          }),
          tap(() => {
            this.log.isOffline = false;
          }),
          catchError(this.handleError.bind(this)));
      }),
      catchError(this.handleError.bind(this)));
  }

  protected put(url, payload): Observable<any> {
    return this.auth.getToken().pipe(
      mergeMap(token => {
        const endpoint = `${environment.api.root}/${url}`;
        const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
        return this.http.put(endpoint, payload, { headers });
      }),
      catchError(this.handleError.bind(this)));
  }
  protected post(url, payload, observeResponse = false): Observable<any> {
    return this.auth.getToken().pipe(
      mergeMap(token => {
        const endpoint = `${environment.api.root}/${url}`;
        const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });

        if (observeResponse) {

          return this.http.post(endpoint, payload, { headers, observe: 'response', responseType: 'text' }).pipe(
            catchError(this.handleError.bind(this)));

        } else {

          return this.http.post(endpoint, payload, { headers }).pipe(
            map(response => {
              const json = <any>response;
              if (json.data) {
                return json.data;
              } else {
                return json;
              }
            }),
            catchError(this.handleError.bind(this)));

        }

      }),
      catchError(this.handleError.bind(this)));
  }

  protected patch(url, payload): Observable<any> {
    return this.auth.getToken().pipe(
      mergeMap(token => {
        const endpoint = `${environment.api.root}/${url}`;
        const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
        //   const options = new RequestOptions({ headers: headers });
        return this.http.patch(endpoint, payload, { headers }).pipe(
          tap(response => console.log(response)),
          catchError(this.handleError.bind(this)));
      }),
      catchError(this.handleError.bind(this)));
  }

  protected delete(url): Observable<any> {
    return this.auth.getToken().pipe(
      mergeMap(token => {
        const endpoint = `${environment.api.root}/${url}`;
        const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
        return this.http.delete(endpoint, { headers }).pipe(
          tap(response => console.log(response)),
          catchError(this.handleError.bind(this)));
      }),
      catchError(this.handleError.bind(this)));
  }

  protected getFile(url): Observable<any> {

    return this.auth.getToken().pipe(
      mergeMap(token => {
        const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
        return this.http.get(url, { headers, responseType: 'blob' });
      }),
      catchError(this.handleError.bind(this)));
  }

  protected getCSVFile(url, queryParams?: any): Observable<any> {

    return this.auth.getToken().pipe(
      mergeMap(token => {
        const endpoint = `${environment.api.root}/${url}`;
        const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
        let params = new HttpParams();
        if (queryParams) {
          for (const key in queryParams) {
            if (queryParams.hasOwnProperty(key) && queryParams[key] !== '' && queryParams[key] !== undefined && queryParams[key] !== null) {
              params = params.append(key, queryParams[key]);
            }
          }
        } else {
          return this.http.get(endpoint, { headers, responseType: 'blob' }).pipe(
            map(response => {
              const json = <any>response;
              //<--- Check log for content disposition
              // var contentDisposition = headers.get('content-disposition');          
              if (json.data) {
                return json.data;
              } else {
                return json;
              }
            }),
            catchError(this.handleError.bind(this)));
        }
      }),
      catchError(this.handleError.bind(this)));
  }
}
