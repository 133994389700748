
import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';

import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FormTemplate } from '../../dynamic/model/FormTemplate';
import { FormType } from '../../dynamic/model/FormType';
import { ServiceBase } from './service.base';
import { AuthService } from './auth.service';
import { LogService } from './log.service';
import { CustomErrorHandler } from './CustomErrorHandler';



import { HttpClient } from '@angular/common/http';


@Injectable()
export class UserService extends ServiceBase {


  constructor(http: HttpClient, auth: AuthService, log: LogService, errorHandler: CustomErrorHandler) {
    super(http, auth, log, errorHandler);
  }

  user$ = new BehaviorSubject(undefined);

  getUser() {
    if (this.user$.value) {
      return observableOf(this.user$.value);
    } else {

      return super.get(`/v1.0/adminusers/current`).pipe(tap(user => {
        this.user$.next(user);
      }));
    }

  }

}
