import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExtCircService } from '../service/extCirc.service';

@Component({
  templateUrl: 'extCircApproveRejectDialog.component.html',
  styleUrls: ['./extCircApproveRejectDialog.scss']
})
export class ExtCircApproveRejectDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ExtCircApproveRejectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private service: ExtCircService) {
  }

  reason: string;
  header = '';
  reasonPlaceholder = '';


  ngOnInit () {
    this.header = this.data.approve ? 'Approve' : 'Reject';
    this.reasonPlaceholder = 'Please provide a reason for the ' + (this.data.approve ? 'approval' : 'rejection');

  }

  approve() {
    const result = { approved: true, reason: this.reason };
    this.service.approve(this.data.id, this.reason).subscribe(() => {
      this.close(result);
    }, error => {

    });

  }

  reject() {
    const result = { rejected: true, reason: this.reason };
    this.service.reject(this.data.id, this.reason).subscribe(() => {
      this.close(result);
    }, error => {

    });

  }

  close(result?) {
    this.dialogRef.close(result || { cancelled: true });
  }



}
