import { Component, Input, OnInit } from '@angular/core';
import { AttachmentType } from './model/attachmentType';
import { PreregApplicationService } from './service/prereg.service';

@Component({
  selector: 'app-assessment-registration-application',
  templateUrl: './assessmentRegistration.component.html',
  styleUrls: ['./application.scss']
})
export class AssessmentRegistrationApplicationComponent implements OnInit {
  @Input() application;
  attachmentType = AttachmentType;

  name: string;
  submittedAt: any;
  preentryNumber: number;


  constructor(
    private service: PreregApplicationService
  ) { }

  ngOnInit() {

    this.name = this.application.name;
    this.preentryNumber = this.application.preregNumber;
    this.submittedAt = this.application.submittedDate;

  }

}

