import { Component, OnInit, Input } from '@angular/core';
import { Decision } from '../model/Decision';
import { ExtCircRequest } from '../model/ExtCircRequest';
import { ExtCircType } from '../model/ExtCircType';

@Component({
  selector: 'app-ext-circ-request',
  moduleId: module.id,
  templateUrl: './extCircRequest.component.html'
})
export class ExtCircRequestComponent implements OnInit {

  @Input() type: ExtCircType;
  @Input() request: ExtCircRequest;
  @Input() isDecision = true;
  @Input() readonly = false;
  Decision = Decision;
  ExtCircType = ExtCircType;

  constructor() {

  }

  ngOnInit() {

  }

  dateChange(date) {
    console.log(date);
  }




}
