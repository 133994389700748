export enum CrmReviewAndFeedbackStatus { // CrmReviewAndFeedbackStatus
        NoChange = 0,
        AwaitingReview = 981360000,
        ReviewInProgress = 981360001,
        ReviewInDiscussion = 981360002,
        MajorIssueQARequired = 981360003,
        ReportAwaitingSignOff = 981360004,
        AdverseReportIssued = 981360005,
        QAInProgress = 981360006,
        QADispute = 981360007,
        MgmtReviewInProgress = 981360008,
        MgmtDispute = 981360009,
        MgmtReviewComplete = 981360010,
        FeedbackReportIssued = 981360011,
        QAComplete = 981360012,
        ReportSignedOffAwaitingQA = 981360013,
        // https://mygphc.atlassian.net/browse/RP2-344
        NotEligibleForReview = 981360014,
}
