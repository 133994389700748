import { Injectable } from '@angular/core';
import { ServiceBase } from './service.base';
import { CustomErrorHandler } from './CustomErrorHandler';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { LogService } from './log.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class MaintenanceService extends ServiceBase {


    constructor(http: HttpClient, auth: AuthService, log: LogService, errorHandler: CustomErrorHandler) {
        super(http, auth, log, errorHandler);
    }

    saveCurrentMessage(value) {
        return this.http.post(`${environment.maintenanceAPIURL}/maintenance-message`, { value });
    }

    getCurrentMessage() {
        return this.http.get(`${environment.maintenanceAPIURL}/maintenance-message`);
    }

}
