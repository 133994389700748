// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #e5f1eb;*/
.feedback-section {
  padding-left: 15px;
  margin-left: -20px;
  margin-right: -20px;
  padding-right: 10px;
  padding-top: 5px;
  border-left: 6px solid transparent;
  background: white !important;
}
.feedback-section .section-header {
  color: #00759b;
  font-weight: bold;
  font-size: 1.4em;
}
.feedback-section.selected {
  border-left: 6px solid #00759b;
  /*   .section-header {
     color: \$gphc-accent-fushia;
     }*/
}
.feedback-section.selected:hover {
  cursor: default;
}

::ng-deep .section-header .edited {
  color: #bc1d63;
}
::ng-deep .section-header button {
  color: #00759b;
  margin-left: 20px;
}

.root-collapsible > .collapsible {
  margin-left: -15px;
  padding-left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/style/variables.scss","webpack://./src/app/revalidation/review/detail/feedbackSections.scss"],"names":[],"mappings":"AAG0B,YAAA;ACD1B;EACE,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;EACA,kCAAA;EACA,4BAAA;AAAF;AAIE;EACE,cDdQ;ECeR,iBAAA;EACA,gBAAA;AAFJ;AAIE;EACE,8BAAA;EACH;;OAAA;AAAD;AAGI;EACE,eAAA;AADN;;AAWE;EACE,cD3BiB;ACmBrB;AAWE;EACE,cDvCQ;ECwCR,iBAAA;AATJ;;AAgBE;EACE,kBAAA;EACA,kBAAA;AAbJ","sourcesContent":["$gphc-blue: #00759b;\r\n$gphc-aqua: darken(#58b896,2.5%);\r\n$gphc-purple: #553c73;\r\n$gphc-background: #d9e8e1;/* #e5f1eb;*/\r\n$light-grey: #ececec;\r\n$grey: #dedede;\r\n$very-light-grey: #f7f7f7;\r\n\r\n$gphc-accent-fushia: #bc1d63;\r\n$gphc-accent-pink: #e94870;\r\n$gphc-accent-orange: #ef7b0f;\r\n$gphc-accent-yellow: #fcc340;\r\n\r\n$banner-background: $gphc-aqua;\r\n\r\n$error: #c52e2e;\r\n\r\n$navbar-height: 58;\r\n\r\n$legacy-modal-text-color: #000000de;\r\n$legacy-modal-padding: 24px;\r\n$legacy-negative-modal-padding: -$legacy-modal-padding;","@import \"../../../style/variables\";\r\n\r\n.feedback-section {\r\n  padding-left: 15px;\r\n  margin-left: -20px;\r\n  margin-right: -20px;\r\n  padding-right: 10px;\r\n  padding-top: 5px;\r\n  border-left: 6px solid transparent;\r\n  background: white !important;\r\n\r\n \r\n\r\n  .section-header {\r\n    color: $gphc-blue;\r\n    font-weight: bold;\r\n    font-size: 1.4em;\r\n  }\r\n  &.selected {\r\n    border-left: 6px solid $gphc-blue;\r\n /*   .section-header {\r\n    color: $gphc-accent-fushia;\r\n    }*/\r\n    &:hover {\r\n      cursor: default;\r\n    }\r\n}\r\n\r\n}\r\n\r\n\r\n\r\n::ng-deep\r\n.section-header {\r\n  .edited {\r\n    color: $gphc-accent-fushia;\r\n  }\r\n  \r\n  button {\r\n    color: $gphc-blue;\r\n    margin-left:20px;\r\n  \r\n  }\r\n}\r\n\r\n\r\n.root-collapsible {\r\n  > .collapsible {\r\n    margin-left: -15px;\r\n    padding-left: 15px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
