// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #e5f1eb;*/
.help-text {
  padding: 20px;
  color: #4fb490;
  font-weight: bold;
}

/*
label.error, h2.error {
  color: \$error;
}
*/
.input-group-addon {
  background-color: #ececec;
  color: white;
  font-weight: bold;
}

::ng-deep .question.input-group input {
  width: auto;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/app/style/variables.scss","webpack://./src/app/dynamic/followupQuestion.scss"],"names":[],"mappings":"AAG0B,YAAA;ACA1B;EACE,aAAA;EAEA,cDLU;ECKS,iBAAA;AADrB;;AAGA;;;;CAAA;AAMC;EACG,yBDXS;ECYT,YAAA;EACA,iBAAA;AADJ;;AAMI;EACE,WAAA;EACA,YAAA;AAHN","sourcesContent":["$gphc-blue: #00759b;\r\n$gphc-aqua: darken(#58b896,2.5%);\r\n$gphc-purple: #553c73;\r\n$gphc-background: #d9e8e1;/* #e5f1eb;*/\r\n$light-grey: #ececec;\r\n$grey: #dedede;\r\n$very-light-grey: #f7f7f7;\r\n\r\n$gphc-accent-fushia: #bc1d63;\r\n$gphc-accent-pink: #e94870;\r\n$gphc-accent-orange: #ef7b0f;\r\n$gphc-accent-yellow: #fcc340;\r\n\r\n$banner-background: $gphc-aqua;\r\n\r\n$error: #c52e2e;\r\n\r\n$navbar-height: 58;\r\n\r\n$legacy-modal-text-color: #000000de;\r\n$legacy-modal-padding: 24px;\r\n$legacy-negative-modal-padding: -$legacy-modal-padding;","@import \"../style/variables\";\r\n\r\n\r\n.help-text {\r\n  padding: 20px;\r\n  \r\n  color: $gphc-aqua; font-weight:bold;\r\n}\r\n/*\r\nlabel.error, h2.error {\r\n  color: $error;\r\n}\r\n*/\r\n\r\n .input-group-addon {\r\n    background-color: $light-grey;\r\n    color: white;\r\n    font-weight: bold;\r\n}\r\n\r\n::ng-deep {\r\n  .question.input-group {\r\n    input {\r\n      width: auto;\r\n      border: none;\r\n    }\r\n  }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
