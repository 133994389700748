// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #e5f1eb;*/
:host .pager {
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background: #eee;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1) inset;
  color: #00759b;
  display: flex;
  align-items: center;
}
:host .pager .info {
  flex-grow: 1;
}
:host .pager button {
  box-shadow: none !important;
  animation: none !important;
  transition: all 200ms linear;
  font-size: 1.2em;
  color: #00759b;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
:host .pager button:hover {
  background: white;
  color: #553c73;
}
:host .pager button:disabled {
  opacity: 0.3;
}
@media (max-width: 767px) {
  :host {
    padding-top: 10px;
    padding-left: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/style/variables.scss","webpack://./src/app/shared/pager.scss"],"names":[],"mappings":"AAG0B,YAAA;ACCxB;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,iDAAA;EAEF,cDXU;ECYR,aAAA;EACA,mBAAA;AAHJ;AAKI;EACE,YAAA;AAHN;AAKI;EACE,2BAAA;EACA,0BAAA;EACA,4BAAA;EAKA,gBAAA;EACA,cD3BM;EC4BN,WAAA;EACA,YAAA;EACA,kBAAA;AAPN;AADM;EACE,iBAAA;EACA,cDtBM;ACyBd;AAIM;EACE,YAAA;AAFR;AAQE;EApCF;IAqCI,iBAAA;IACA,kBAAA;EALF;AACF","sourcesContent":["$gphc-blue: #00759b;\r\n$gphc-aqua: darken(#58b896,2.5%);\r\n$gphc-purple: #553c73;\r\n$gphc-background: #d9e8e1;/* #e5f1eb;*/\r\n$light-grey: #ececec;\r\n$grey: #dedede;\r\n$very-light-grey: #f7f7f7;\r\n\r\n$gphc-accent-fushia: #bc1d63;\r\n$gphc-accent-pink: #e94870;\r\n$gphc-accent-orange: #ef7b0f;\r\n$gphc-accent-yellow: #fcc340;\r\n\r\n$banner-background: $gphc-aqua;\r\n\r\n$error: #c52e2e;\r\n\r\n$navbar-height: 58;\r\n\r\n$legacy-modal-text-color: #000000de;\r\n$legacy-modal-padding: 24px;\r\n$legacy-negative-modal-padding: -$legacy-modal-padding;","@import '../style/variables.scss';\r\n\r\n:host {\r\n\r\n  .pager {\r\n    padding: 10px;\r\n    padding-left: 20px;\r\n    padding-right: 20px;\r\n    background: #eee;\r\n    box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1) inset;\r\n\r\n  color: $gphc-blue;\r\n    display: flex;\r\n    align-items: center;\r\n\r\n    .info {\r\n      flex-grow: 1;\r\n    }\r\n    button {\r\n      box-shadow: none !important;\r\n      animation: none !important;\r\n      transition: all 200ms linear;\r\n      &:hover {\r\n        background: white;\r\n        color: $gphc-purple;\r\n      }\r\n      font-size: 1.2em;\r\n      color: $gphc-blue;\r\n      width: 30px;\r\n      height: 30px;\r\n      border-radius: 50%;\r\n      &:disabled {\r\n        opacity: 0.3;\r\n      }\r\n    }\r\n  }\r\n\r\n\r\n  @media(max-width: 767px) {\r\n    padding-top: 10px;\r\n    padding-left: 10px;\r\n  }\r\n\r\n \r\n\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
