import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './service/auth.service';
import { LayoutService } from './service/layout.service';
@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.scss']
})
export class BrandingComponent  {

  @Input() showRings = true;
  @Input() appVersion;

  constructor(private router: Router, private authService: AuthService, private layout: LayoutService) {
  }

  goHome() {
    if (!this.authService.user) {
      this.router.navigate(['/signin']);
    } else {
      this.router.navigate(['/']);
    }
  }

  public signout() {
    this.authService.logout();
  }
  get username(): string {
    if (this.authService.user) {
      return this.authService.user?.userName;
    } else {
      return '';
    }
  }

}
