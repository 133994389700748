import { Injectable, EventEmitter } from '@angular/core';
import { LayoutState } from '../model/LayoutState';
import { BannerState } from '../model/BannerState';

@Injectable()
export class LayoutService {

  public state: LayoutState = {
    bannerState: BannerState.default,
    fullscreen: false,
    showBranding: true,
    navbarOpen: false,
    accountMode: false,
    width: 0,
    height: 0,
    xs: false,
    md: false,
    lg: false,
    overlay: false
  };

  public onChange = new EventEmitter<LayoutState>();

  public setBannerState(bannerState: BannerState) {
    // if (hasBanner === this.state.hasBanner ) { return; }
    setTimeout(() => {
      this.state = Object.assign(this.state, { bannerState });
      this.onChange.emit(this.state);
    });
  }

  public setOverlay(overlay: boolean) {
    setTimeout(() => {
      this.state = Object.assign(this.state, { overlay });
      this.onChange.emit(this.state);
    });
  }

  public setAccountMode(accountMode: boolean) {
    setTimeout(() => {
      this.state = Object.assign(this.state, { accountMode });
      this.onChange.emit(this.state);
    });
  }




  public setNavbarOpen(navbarOpen: boolean) {
    // if (hasBanner === this.state.hasBanner ) { return; }
    setTimeout(() => {
      this.state = Object.assign(this.state, { navbarOpen });
      this.onChange.emit(this.state);
    });
  }

  public toggleNavbar() {
    this.setNavbarOpen(!this.state.navbarOpen);
  }

  public setBranding(showBranding: boolean) {
   // if (showBranding === this.state.showBranding ) { return; }
    setTimeout(() => {
      this.state = Object.assign(this.state, { showBranding });
      this.onChange.emit(this.state);
    });
  }

  public toggleFullscreen() {
    setTimeout(() => {
      this.state = Object.assign(this.state, { fullscreen: !this.state.fullscreen });
      this.onChange.emit(this.state);
    });
  }

  public setFullscreen(fullscreen) {
 //   if (fullscreen === this.state.fullscreen ) { return; }
    setTimeout(() => {
      this.state = Object.assign(this.state, { fullscreen });
      this.onChange.emit(this.state);
    });
  }

  public setSize(width: number, height: number) {
    let xs, md, lg;
    if (width < 767) {
      xs = true;
      md = false;
      lg = false;
    }
    if (width > 767) {
      xs = false;
      md = true;
      lg = true;
    }

    if (width > 1024) {
      lg = true;
      md = false;
      xs = false;
    }
     setTimeout(() => {
      this.state = Object.assign(this.state, { width, height, xs, md, lg });
      this.onChange.emit(this.state);
    });
  }


  public reset(fullscreen = false) {
    this.setBannerState(BannerState.default);
    this.setFullscreen(fullscreen);
    this.setBranding(true);
    this.setNavbarOpen(false);
    this.setOverlay(false);
    this.setAccountMode(false);
    // TODO: window should be abstracted
    window.scrollTo(0, 0);
  }
}
