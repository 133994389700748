export enum NotificationType {
    Unknown = 0,
    RenewalReminder = 1,
    RevalidationReminder = 2,
    RevalidationReviewReminder = 3,
    RevalidationReviewBlockingReminder = 4,
    RevalidationReviewMessageReminder = 5,
    EmailChangeRequestNotification = 6,

    // RP2-496
    RenewalWindowOpening = 10,

    /// <summary>
    /// RP2-21 - Message for the Author of the Revalidation that it's been Selected
    /// to be Reviewed.
    /// </summary>
    RevalidationSelectedForReview = 20,
    /// <summary>
    /// RP2-124 - When all the Reviews has been handled this Notification will be
    /// sent to the Reviewee.
    /// </summary>
    RevalidationReviewFeedbackSent = 21,
    /// <summary>
    /// RP2-586 - This is the notification that goes out to the Reviewers; this
    /// should not be generic, and should contain deadline information.
    /// </summary>
    RevalidationReviewAvailable = 22,
    /// <summary>
    /// RP2-585 - When the QA Rejects the External Reviewer's feedback the External
    /// Reviewers has to be notified.
    /// </summary>
    RevalidationReviewFeedbackRejected = 23,
    /// <summary>
    /// See Above
    /// </summary>
    //RevalidationReviewReminder = 3,

    // ROS-252
    ApplicationApprovedPendingAssessmentNotification = 50,
    // ROS-256
    IneligibleBasedOnAssessmentNotification = 51,
    // ROS-157
    RegistrationPaymentRequiredNotification = 52,
    // ROS-255
    RegistryEntryNotification = 53,

    // ROS-249 - New Countersignature waiting for Pharmacist
    RegistrationFormCountersignatureRequested = 60,
    // ROS-250 - The countersigner countersigned.
    RegistrationFormCountersignatureApproved = 61,
    // ROS-516 - The countersigner refused to approve or reject for one reason or another.
    RegistrationFormCountersignatureRefused = 62,
    // ROS-251 - The countersigner rejected.
    RegistrationFormCountersignatureRejected = 63,
    // ROS-448 - Trainee recalled countersignature.
    RegistrationFormCountersignatureRecalled = 64,
    // ROS-633 - First year fee successful notification
    RegistrationFormFirstYearFeeSuccessful = 65,
    // ROS-626 - Submitted application
    RegistrationFormApplicationSubmitted = 66,

    // PROS (student & tutor) - learning contract notifications 
    PreRegistrationFormLearningContractSignatureRequested = 70,
    PreRegistrationFormLearningContractApproved = 71,
    PreRegistrationFormLearningContractRefused = 72,
    PreRegistrationFormLearningContractRejected = 73,
    PreRegistrationFormLearningContractRecalled = 74,
    PreRegistrationFormPaymentRequired = 75,
    // https://mygphc.atlassian.net/browse/PROS-4
    PreRegistrationFormApproved = 76,
    // https://mygphc.atlassian.net/browse/PROS-281
    PreRegistrationFormWaitingForApplicant = 77,
    // https://mygphc.atlassian.net/browse/PROS-61
    PreRegistrationFormTerminated = 78,
    // https://mygphc.atlassian.net/browse/PROS-10
    PreRegistrationTrainingRecord = 79,
    PreRegistrationFormPaymentReceivedAndSubmitted = 101,

    // RP2-157 - Submit Extenuating Circumstances
    ExtenuatingCircumstancesSent = 80,
    ExtenuatingCircumstancesApproved = 81,
    ExtenuatingCircumstancesPartiallyApproved = 82,
    ExtenuatingCircumstancesRejected = 83,

    // RP2-19
    RevalidationSubmissionSubmit = 84,

    // https://mygphc.atlassian.net/browse/RP2-181
    // Both the Renewal and Revalidation Submission are Due on the Same Date
    RenewalRevalidationReminderSameDate = 90,
    // https://mygphc.atlassian.net/browse/RP2-181
    // There's a Pending Extenuating Circumstance that hasn't been Decided Upon
    RenewalRevalidationReminderPendingExtenuatingCircumstances = 91,
    // https://mygphc.atlassian.net/browse/RP2-181
    // Extension Granted - Renewal is Due
    RenewalRevalidationReminderExtensionGrantedRenewalDue = 92,
    // https://mygphc.atlassian.net/browse/RP2-181
    // Extension Granted - Revalidation Submission is Due
    RenewalRevalidationReminderExtensionGrantedRevalidationSubmissionDue = 93,

    // Notification For When Registrant ends up in Remediation due to missing the
    // RevalidationSubmissionDeadline.
    RemediationEntered = 200,
    // Notification For When Registrant ends up in NoIR.
    NoIREntered = 201,

    // Notification for when NoR is issued on NoR dashboard
    NoRIssued = 202,

    // TROS: technician applicant notifications 
    // https://mygphc.atlassian.net/browse/TROS-36 - new Countersignature requested
    TechnicianApplicantCountersignatureRequested = 301,
    TechnicianApplicantCountersignatureApproved = 302,
    TechnicianApplicantCountersignatureRefused = 303,
    TechnicianApplicantCountersignatureRejected = 304,
    // https://mygphc.atlassian.net/browse/TROS-37
    TechnicianApplicantCountersignatureRecalled = 305,
    // https://mygphc.atlassian.net/browse/TROS-41
    TechnicianApplicantApplicationSubmitted = 306,
    // https://mygphc.atlassian.net/browse/TROS-43
    TechnicianApplicantFirstYearFeePaymentSuccessful = 307,
    // https://mygphc.atlassian.net/browse/TROS-42
    TechnicianApplicantFirstYearFeeAvailable = 308,
    // https://mygphc.atlassian.net/browse/TROS-45
    TechnicianApplicantApplicationTerminated = 309,
    // https://mygphc.atlassian.net/browse/TROS-44
    TechnicianApplicantNoticeOfEntry = 310,
    //https://mygphc.atlassian.net/browse/TROS-438
    TechnicianApplicantApplicationWaitingForApplicant = 311,

    // AROS : 39th week progress report registration
    // https://mygphc.atlassian.net/browse/AROS-82
    ProgressReportFormOpenNotification = 401,
    // https://mygphc.atlassian.net/browse/AROS-20
    ProgressReportFormCountersignatureRequest = 402,
    ProgressReportFormCountersignatureRecalled = 403,
    // https://mygphc.atlassian.net/browse/AROS-84
    ProgressReportFormCountersignatureApproved = 404,
    ProgressReportFormCountersignatureRefused = 405,
    ProgressReportFormCountersignatureRejected = 406,
    // https://mygphc.atlassian.net/browse/AROS-79
    ProgressReportThirtNineWeekResultCleared = 407,
    // https://mygphc.atlassian.net/browse/C1RC-36
    ProgressReportTempRegistrationAccepted = 408,
    ProgressReportTempRegistrationRejected = 409,

    // AROS: assessment registration application
    // https://mygphc.atlassian.net/browse/AROS-164
    AssessmentRegistrationApplicationAvailable = 420,
    // https://mygphc.atlassian.net/browse/AROS-165
    AssessmentRegistrationApplicationPaymentSuccessful = 421,
    // assessment attempt application status approved
    // https://mygphc.atlassian.net/browse/RAN-22
    AssessmentAttemptApplicationApproved = 422,
    // https://mygphc.atlassian.net/browse/RAN-2
    AssessmentAttemptResultPublished = 423,


    // 52Week Final Declaration Application
    // https://mygphc.atlassian.net/browse/ZF52-5
    FiftyTwoWeekFinalDeclarationFormOpenNotification = 501,
    // https://mygphc.atlassian.net/browse/ZF52-38
    FinalDeclarationFormCountersignatureRequest = 502,
    FinalDeclarationFormCountersignatureRecalled = 503,
    // https://mygphc.atlassian.net/browse/ZF52-53
    FinalDeclarationFormCountersignatureApproved = 504,
    FinalDeclarationFormCountersignatureRefused = 505,
    FinalDeclarationFormCountersignatureRejected = 506,
    // https://mygphc.atlassian.net/browse/ZF52-63
    FinalDeclarationFormProvisionalAccepted = 507,
    FinalDeclarationFormProvisionalRejected = 508,
    FinalDeclarationFiftyTwoWeekResultCleared = 509,
    // Independent prescriber notifications types
    IndependentPrescriberEligibleForApplication = 601,
    IndependentPrescriberCountersignatureRequested = 602,
    IndependentPrescriberCountersignatureRecalled = 603,
    IndependentPrescriberCountersignatureApproved = 604,
    IndependentPrescriberCountersignatureRejected = 605,
    IndependentPrescriberCountersignatureRefused = 606,
    //IndependentPrescriberSubmitApplication = 607,
    IndependentPrescriberApplicationAutoApproved = 607,
    IndependentPrescriberApplicationNonAutoApproved = 608,
    IndependentPrescriberApplicationApproved = 609,
    TestNotification = 9999,
    //VR notifications
    VoluntaryRemovalApplicationReceived = 701,
    VoluntaryRemovalApplicationApproved = 702,
    VoluntaryRemovalApplicationAutoApproved = 703,
    VoluntaryRemovalRegistrationStatusUpdated = 704,
    //RTR notifications
    ReturnToRegisterApplicationPendingRestorationFee = 801,
    ReturnToRegisterApplicationReceivedRevalidationRequired = 802,
    ReturnToRegisterApplicationReceivedRevalidationNotRequired = 803,
    ReturnToRegisterApplicationTerminated = 804,
    ReturnToRegisterApplicationApproved = 805,
    ReturnToRegisterRegistrationStatusUpdated = 806,
    ReturnToRegisterApplicationTerminatedNonPayment = 807,
    ReturnToRegisterApplicationFeePaymentReminder = 808,
    ReturnToRegisterRevalidationSubmitReminder = 809,
    //CPSC notifications
    CertificateProfessionalStandingApplicationSubmitted = 901,
    CertificateProfessionalStandingWaitingForApplicant = 902,
    CertificateProfessionalStandingApplicationCompleted = 903
}
