import { AttachmentType } from "./attachmentType";

export interface CCPSDetails{
        forms: [{
            form: {
                isOverallDeclarationAcknowledged: boolean,
                professionalStandingDetail: {
                    continueExistingName: boolean,
                    title: string,
                    titleValue: string,
                    forenames: string,
                    surname: string,
                    middleName: string,
                    country: string,
                    regulatoryBodyId: string,
                    contactName: string,
                    telephone: number,
                    emailAddress: string
                },
                initialRegistrantQualificationDetail: {
                    registrationRoute: string,
                    eeaPharmacistQualificationDetail: eeaPharmacistQualificationDetail,
                    ukPharmacistQualificationDetail: ukPharmacistQualificationDetail,
                    ospapPharmacistQualificationDetail: ospapPharmacistQualificationDetail,
                    irelandPharmacistQualificationDetail: irelandPharmacistQualificationDetail,
                    pharmacyTechnicianQualificationDetail: pharmacyTechnicianQualificationDetail,
                    trainingDetails: trainingDetails
                },
                appDeclaration: {
                    isQ1Confirmed: boolean,
                    isQ2Confirmed: boolean,
                    isQ3Confirmed: boolean,
                    isQ4Confirmed: boolean
                },
                equalityDiversity: {
                    ethnicity: number;
                    ethnicityOther: string;
                    nationality: string;
                    religion: number;
                    religionOther: string;
                    disabled: number;
                    disabilityDetails: string;
                    gender: number;
                    sexualOrientation: number;
                  },
                declarations: [
                    {
                      dynamicFormId: string;
                      answers: [
                        {
                          questionId: string;
                          answer: string;
                          answerText: string;
                        }
                      ];
                    }
                  ],
                qualificationDetailsExists: {
                    courseExists: boolean,
                    qualifiedExists: boolean,
                    countryExists: boolean
                },
                trainingDetailsExists: boolean,
                assessmentDetailsExists: boolean,
                id: string,
                formStatus: number,
                step: number,
                scope: number,
                attachments: [
                    {
                      certifier: {
                        companyName: null
                        date: null
                        name: null
                        number: null
                        type: null
                      },
                  deleteUrl: string,
                  expiryDate: Date
                  fileId: string,
                  filename: string,
                  filesize: number,
                  isDuplicateCopy: false
                  title: string,
                  type: AttachmentType.PharmcistCVForProfessionalStanding,
                  url: 'assets/images/rings-small.png'
                    }
                  ],
                countersignatures: [],
                createdAt: Date,
                dateApplicationSubmitted: Date
            },
            regulatoryBody: {
              id: string,
              name: string,
              addressLine1: string,
              addressLine2: string,
              addressLine3: string,
              countyState: string,
              country: string,
              postCode: string,
              standardEmail: string
            },
            ftpQuestions: {
              answer: string,
              body:string,
              title:string,
              type:number
            }
        }],
        applicantDetails: {
            gPhCId: string,
            registrationNumber: number,
            title: string,
            forenames: string,
            surname: string,
            middleName: string,
            registrationStatus: number,
            registrantType: number,
            legacyRegistrationRoute: number
        }
}

export interface eeaPharmacistQualificationDetail {
    countryQualified: string;
    eeaDirectiveRoute?: string;
    courseName?: string;
    dateStarted?: Date;
    datePassed?: Date,
    educationTrainingDetails?: string;
    countryFirstRecognized: string;
    cvUpload?: any;
    nameOfUniversity:string
  }

  export class InitialRegistrantQualificationDetail {
    registrationRoute: number;
    eeaPharmacistQualificationDetail: eeaPharmacistQualificationDetail;
    ukPharmacistQualificationDetail: ukPharmacistQualificationDetail;
    ospapPharmacistQualificationDetail:ospapPharmacistQualificationDetail;
    irelandPharmacistQualificationDetail: irelandPharmacistQualificationDetail;
    pharmacyTechnicianQualificationDetail: pharmacyTechnicianQualificationDetail;
    trainingDetails: trainingDetails[]
  }
  
  export interface pharmacyTechnicianQualificationDetail{
    degreeName1: string;
    qualifiedDate1: string;
    degreeName2: string;
    qualifiedDate2: string
  }
  
  export interface irelandPharmacistQualificationDetail{
    courseId: string,
    courseName: string,
    yearObtained: number
  }
  
  export interface ospapPharmacistQualificationDetail{
    ospapCountryQualified: string;
    ospapCourseId: string;
    ospapCourseName: string;
    degreeName: string;
    universityName: string;
    yearObtained: number;
    ospapDateQualified: string;
    assessmentYear: string
  }
  
  export interface ukPharmacistQualificationDetail {
    courseId: string;
    courseName: string;
    yearQualified: number;
    assessmentYear: string
  }
  
  export interface eeaPharmacistQualificationDetail {
    countryQualified: string;
    eeaDirectiveRoute?: string;
    courseName?: string;
    dateStarted?: Date;
    datePassed?: Date,
    educationTrainingDetails?: string;
    countryFirstRecognized: string;
    cvUpload?: any;
  }
  
  export interface PreRegTraining {
    trainingSiteName: string;
    trainingSiteAddress: string;
    startedDate: Date;
    endedDate: Date;
  }
  
  export interface trainingDetails 
    {
      trainingSiteName: string;
      trainingSiteAddress: string;
      trainingStartDate: Date;
      trainingEndDate: Date;
    }
  
    export class trainingSites
      {
        trainingSite: string;
        line1: string;
        line2: string;
        line3: string;
        town: string;
        county: string;
        country: string;
        postcode: string;
        startDate: string;
        endDate: string;
      }
  
      export enum LegacyRegistrationRoute{
              Adjudicating = 717750000,
              NonEEATechnicianApplicant = 717750001,
              Reciprocity = 717750002,
              RouteATechnicianApplicant = 717750003,
              RouteBTechnicianApplicant = 717750004
      }
  
  export enum eeaDirectiveRoute{
          Article44 = 717750000,
          Article23 = 717750001,
         //[Description("Non-Auto (EEA Qual)")]
          NonAutoEEAQual = 717750002,
        //[Description("Non-Auto (Non-EEA Qual)")]
          NonAutoNonEEAQual = 717750003,
  }

  export enum ApplicationStatus {
    NotStarted = 1,
    InProgress = 2,
    CounterSigned = 4,
    Submitted = 3,
    ReadyForCountersigning = 5,
    ApprovedPendingFirstYearFee = 6,
    Ineligible = 7,
    PendingProcessing = 10,
    Approved = 11,
    WaitingForApplicant = 12,
    Terminated = 13,
    CeasedTraining = 14,
    Refused = 15,
    ApprovedPendingRestorationFee = 16,
    RestorationFeePaidPendingApproval = 17
  }
 
  export enum RegistrationRoute {
    UK = 717750000,
    UKPlusOverseas = 717750001,
    OSPAP = 717750002,
    EEA = 717750003,
    NorthernIreland = 717750004,
    Legacy = 717750005,
  }

  export const EEACountries = [
    'Austria',
    'Belgium',
    'Bulgaria',
    'Croatia',
    'Republic of Cyprus',
    'Czech Republic',
    'Denmark',
    'Estonia',
    'Finland',
    'France',
    'Germany',
    'Greece',
    'Hungary',
    'Ireland',
    'Italy',
    'Latvia',
    'Lithuania',
    'Luxembourg',
    'Malta',
    'Netherlands',
    'Poland',
    'Portugal',
    'Romania',
    'Slovakia',
    'Slovenia',
    'Spain',
    'Sweden',
    'Iceland',
    'Liechtenstein',
    'Norway',
    'Switzerland'
  ];
  
  
  // export const EEADirectiveRoute = ['Article44', 'Article23', 'GS', 'NonAutoEEAQual', 'NonAutoNonEEAQual'];