import { Pipe, PipeTransform } from '@angular/core';
import { NotificationType } from '../../accounts/model/NotificationType';

@Pipe({
    name: 'searchPipe'
})
export class SearchPipe implements PipeTransform {

    transform(notificationType: any, term: any): any {
        // check if search term is undefined
        if (term === undefined) {
            return notificationType;
        }
        return notificationType.filter((notification) => {
            return NotificationType[notification.type].toLowerCase().includes(term.toLowerCase());
        });
    }

}
