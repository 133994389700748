import { Component, Input} from '@angular/core';
import { AttachmentType } from './model/attachmentType';
import { PreregApplicationService } from './service/prereg.service';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-prereg-application',
    templateUrl: './preRegApplication.component.html',
    styleUrls: ['./application.scss']
})
export class PreregApplicationComponent {
    @Input() application;
    attachmentType = AttachmentType;
    downloading = false;
    downloadClickCount = 0;

    constructor(
        private service: PreregApplicationService
    ) {}

}

