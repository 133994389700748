// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.important a {
  cursor: pointer;
}
.important .italics {
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/app/dynamic/formAlternative.scss"],"names":[],"mappings":"AACE;EACI,eAAA;AAAN;AAEE;EACI,kBAAA;AAAN","sourcesContent":[".important {\r\n  a {\r\n      cursor: pointer;\r\n  }\r\n  .italics {\r\n      font-style: italic;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
