import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
// import { WorkExperience } from '../../model/WorkExperience';


@Component({
  selector: 'app-work-experience',
  templateUrl: './workExperience.component.html',
  styleUrls: ['./workExperience.scss']
})
export class WorkExperienceComponent implements OnInit {

  @Input() readonly = false;
  @Input() touched = false;
  @Input() workExperience;
  @Input() index;
  @Output() changed = new EventEmitter();
  today = new Date();

  constructor() { }

  ngOnInit() {
  }


}
