import { Component, Input, OnInit, Output, EventEmitter, forwardRef, ViewChild, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';



import { LayoutService } from '../core/service/layout.service';

@Component({
  selector: 'app-utc-date-picker',
  templateUrl: './utcDatePicker.component.html',
  styleUrls: ['./utcDatePicker.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UtcDatePickerComponent),
      multi: true
    }
  ]
})
export class UtcDatePickerComponent implements OnInit, ControlValueAccessor {


  constructor(private cd: ChangeDetectorRef, public layout: LayoutService) { }

  dateValue;
  @Input() readonly = false;
  @Input() name;
  @Input() error = false;
  @Input() min;
  @Input() max;
  @Input() placeholder = 'Choose a date';

  ngOnInit() {

  }

  dateChange(date) {
    const ticks = date.getTime();
    const offset = date.getTimezoneOffset();
    const adjusted = ticks - (offset * 60 * 1000);
    const adjustedDate = new Date(adjusted);
    const year = adjustedDate.getFullYear();
    let month = (adjustedDate.getMonth() + 1).toString();
    if (month.length === 1) {
      month = '0' + month;
    }
    let day = adjustedDate.getDate().toString();
    if (day.length === 1) {
      day = '0' + day;
    }
    const adjustedDateString = `${year}-${month}-${day}`;
    this.propagateChange(adjustedDateString);
  }

  writeValue(value: string) {

    if (value) {
      this.dateValue = value.split('T')[0];
      this.cd.detectChanges();
    } else {
      this.dateValue = undefined;
    }
  }
  propagateChange = (model: any) => {};
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() {}


}
