
import {of as observableOf,  Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ServiceBase } from '../../core/service/service.base';
import { AuthService } from '../../core/service/auth.service';
import { LogService } from '../../core/service/log.service';
import { Http } from '@angular/http';
import { CustomErrorHandler } from '../../core/service/CustomErrorHandler';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PortalUserLookup } from '../model/PortalUserLookup';
import { map, tap, catchError } from 'rxjs/operators';
import { Address } from '../model/Address';
import { PreregUsers } from '../model/preregUsers';

@Injectable()
export class AccountService extends ServiceBase {

  adminUsers;
  countries;
  constructor(http: HttpClient, auth: AuthService, log: LogService, errorHandler: CustomErrorHandler) {
    super(http, auth, log, errorHandler);
  }


  getAdminUsers() {
    if (this.adminUsers) {
      return observableOf(this.adminUsers);
    }
    return this.get('v1.0/adminusers/list').pipe(
      tap(users => this.adminUsers = users)
    );
  }


  unlock(unlock) {
    return this.post('v1.0/user/accountactivation', unlock);
  }

  portalUserLookup(lookup: PortalUserLookup) {
    // v1.0/user?Email=<string>&PersonId=<string>&RegistrationNumber=<string>&GPhCId=<Guid>
    // return this.get(`v1.0/user?Email=${lookup.email}
    // &PersonId=${lookup.personId}&RegistrationNumber=${lookup.registrationNumber}&GPhCId=${lookup.gphcId}`)

    return this.get('v1.0/user', lookup);
  }

  // linkId = personId or registrationNumber
  getAccountStatus(linkId, email) {
    return this.get('v1.0/user/accountstatus', { linkId, email });
  }

  linkAccount(linkId, email) {
    return this.post('v1.0/user/linkaccount', { linkId, email });
  }
  unlinkAccount(linkId, email) {
    return this.post('v1.0/user/unlinkaccount', { linkId, email });
  }

  getNotificationsByRegNumber(registrationNumber: string, skip = 0, pageSize = 5, props = {}) {

    return this.get(`v1.0/notification?registrationNumber=${registrationNumber}&skip=${skip}&take=${pageSize}`);

  }

  getNotificationsByPreEntryNumber(preEntryNumber: string, skip = 0, pageSize = 5, props = {}) {
    return this.get(`v1.0/notification?preEntryNumber=${preEntryNumber}&skip=${skip}&take=${pageSize}`);

  }

  getCountries(): Observable<any> {
    if (this.countries) {
      return observableOf(this.countries);
    } else {
      return super.get('v1.0/lookup/countries').pipe(tap(result => {
        this.countries = result;
      }));
    }
  }

  searchAddress(countryCode: string, postcode: string): Observable<Array<any>> {
    return super.get('v1.1/address/search', { countryCode, postcode });
  }

  getAddress(id: string) {
    return super.get('v1.0/address/details', { id })
      .pipe(map(data => {
        if (data.length) {
          const address = new Address(data[0]);
          return address;
        } else {
          return null;
        }
      }));
  }


}
