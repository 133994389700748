import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { LayoutService } from '../../core/service/layout.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReviewService } from '../service/review.service';
import { RegistrantType } from '../../accounts/model/RegistrantType';

@Component({
  templateUrl: './changeReviewers.component.html',
  selector: 'app-change-reviewers',
  styleUrls: ['../list.scss', './review.scss']
})
export class ChangeReviewersComponent implements OnInit {

  constructor(private service: ReviewService) { }

  reviewers = [];
  techReviewers = [];
  pharmReviewers = [];
  layReviewers = [];
  changingReviewers;
  @Input() row;
  @Input() editing;
  @Output() changed = new EventEmitter();
  RegistrantType = RegistrantType;

  ngOnInit() {
    this.service.getReviewers().subscribe(data => {
      this.reviewers = data.map(reviewer => {

        reviewer.type = reviewer.registrantType ? (reviewer.registrantType === RegistrantType.Pharmacist ? 'Pharm' : 'Tech' ) : 'Lay';
        return reviewer;
      });
      this.techReviewers = this.reviewers
        .filter(reviewer => !reviewer.registrantType || reviewer.registrantType === RegistrantType.PharmacyTechnician);
      this.pharmReviewers = this.reviewers
        .filter(reviewer => !reviewer.registrantType || reviewer.registrantType === RegistrantType.Pharmacist);
      this.layReviewers = data.filter(reviewer => !reviewer.registrantType);


    });
  }


  validateReviewerSelection(row, event, rev) {
    this.editing[rev] = event;
    const lead = this.reviewers.find(reviewer => reviewer.userId === this.editing.lead);
    const secondary = this.reviewers.find(reviewer => reviewer.userId === this.editing.secondary);

    if (lead.registrantType && secondary.registrantType) {
      this.editing.isValid = false;
      this.editing.validationMessage = 'One of the reviewers must be a lay reviewer';
      return;
    }
    const rowRegistrantType = row.registrantType === RegistrantType.Pharmacist ? 'pharmacist' : 'technician';
    if (!lead.registrantType && !secondary.registrantType) {
      this.editing.isValid = false;
      this.editing.validationMessage = `One of the reviewers must be a ${rowRegistrantType}`;
      return;
    }

    if (row.registrantType === RegistrantType.Pharmacist) {
      if (!(lead.registrantType === RegistrantType.Pharmacist || secondary.registrantType === RegistrantType.Pharmacist)) {
        this.editing.isValid = false;
        this.editing.validationMessage = 'Pharmacist must be assigned a pharmacist reviewer';
        return;
      }
    }
    if (row.registrantType === RegistrantType.PharmacyTechnician) {
      if (!(lead.registrantType === RegistrantType.PharmacyTechnician || secondary.registrantType === RegistrantType.PharmacyTechnician)) {
        this.editing.isValid = false;
        this.editing.validationMessage = 'Pharmacy technician must be assigned a pharmacy technician reviewer';
        return;
      }
    }


    this.editing.isValid = true;
    this.editing.validationMessage = '';

  }



  get willChangeReviewers() {
    return this.editing.lead !== this.editing.row.leadReviewerUserId ||
      this.editing.secondary !== this.editing.row.secondaryReviewerUserId;
  }

  changeReviewers() {
    this.changingReviewers = true;
    this.service.changeReviewers(this.editing.row.id, this.editing.lead, this.editing.secondary).subscribe(() => {
      this.changingReviewers = false;
      this.editing.row.leadReviewerName = this.reviewers.find(reviewer => reviewer.
        userId === this.editing.lead).name;
      this.editing.row.leadReviewerUserId = this.editing.lead;
      this.editing.row.secondaryReviewerName = this.reviewers.find(reviewer => reviewer.
        userId === this.editing.secondary).name;
      this.editing.row.secondaryReviewerUserId = this.editing.secondary;

      this.changed.emit(this.editing.row);

      this.editing = { id: undefined, lead: undefined, secondary: undefined, row: undefined };

    });
  }



}
