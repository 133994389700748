import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './service/auth.service';
import { LayoutState } from './model/LayoutState';
import { LayoutService } from './service/layout.service';
import { BannerState } from './model/BannerState';

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.scss'],
  animations: [
    trigger('openState', [
      state('open', style({
        height: '*',
        display: 'block'
      })),
      state('closed', style({
        height: '0',
        display: 'none'
      })),
      transition('closed <=> open', animate('500ms cubic-bezier(.25,.8,.25,1)'))
    ])
  ]
})
export class NavComponent implements OnInit {


  openState = 'closed';
  BannerState = BannerState;
  @Input('loggedIn') loggedIn: boolean;
  @Input() direction = 'horizontal';
  @Input('isOpen') set setIsOpen(isOpen) {
    if (this.direction === 'vertical') {
      this.openState = isOpen ? 'open' : 'closed';
    }
  }

  constructor(private router: Router, public authService: AuthService, public layout: LayoutService) { }

  ngOnInit() {
    if (this.direction === 'horizontal') {
      this.openState = 'open';
    }

  }

  log(val) {
    console.log(val);
  }

  public signout() {
    this.authService.logout();
  }


}
