import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "../../core/service/auth.service";
import { CustomErrorHandler } from "../../core/service/CustomErrorHandler";
import { LogService } from "../../core/service/log.service";
import { ServiceBase } from "../../core/service/service.base";

@Injectable()
export class SubmissionEnquiryService extends ServiceBase{
    
    constructor(http: HttpClient, auth: AuthService, log: LogService, errorHandler: CustomErrorHandler) {
        super(http, auth, log, errorHandler);
    }

    getRevlidationSubmissions(regNo) {
        return this.get(`v1.0/admin/registrant/revalidations?RegistrationNo=${regNo}`);
    }
}