import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExtCircService } from '../service/extCirc.service';

@Component({
  templateUrl: 'confirmDateDialog.component.html'
})
export class ConfirmDateDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ConfirmDateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private service: ExtCircService) {
  }

  todaysDate = new Date();
  chosenDate;
  header = '';
  message = '';
  warning = '';

  ngOnInit () {
    this.header = this.data.header || 'Choose a date to issue nor letter';
    this.message = this.data.message || 'Click the dropdown';
    this.warning = this.data.warning || 'This action is irreversible, please be careful.';
  }


  save() {
    this.dialogRef.close(this.chosenDate);
  }

  cancel() {
    this.dialogRef.close(false);
  }



}
