import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../core/service/layout.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReviewService } from '../service/review.service';
import { CohortStatus } from '../model/CohortStatus';
import { DatePipe } from '@angular/common';
import { ReviewStage } from '../model/ReviewStage';
import { PerformanceIndicator } from '../model/PerformanceIndicator';
import { ReviewSelectionStatus } from '../model/ReviewSelectionStatus';
import { CaseSplitPipe } from '../../shared/pipe/CaseSplit.pipe';
import { PagerComponent } from '../../shared/pager.component';
import { RegistrantStatus } from '../../accounts/model/RegistrantStatus';

@Component({
  templateUrl: './reviewBacklog.component.html',
  styleUrls: ['../list.scss', './review.scss']
})
export class ReviewBacklogComponent implements OnInit, AfterViewInit {


  initialisingCohort = false;
  cohortId;
  renewalDate;
  randomSamplePercent = 2.5;
  ReviewSelectionStatus = ReviewSelectionStatus;
  filterParams;
  activeFilterParams;
  activeFilterParamsInfo = [];
  filterReviewSelectionStatuses = [];
  panel;
  data = [];
  @ViewChild(PagerComponent) pager: PagerComponent;
  RegistrationStatus = RegistrantStatus;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public layout: LayoutService, public service: ReviewService, private datePipe: DatePipe, private caseSplitPipe: CaseSplitPipe) {

    this.filterParams = this.emptyFilterParams();
    this.filterParams.renewalDate = activatedRoute.snapshot.queryParams['renewalDate'];
    this.activeFilterParams = this.emptyFilterParams();
  }



  ngOnInit() {
    this.filterReviewSelectionStatuses = this.getSelectionStatuses();
  }
  ngAfterViewInit() {
    this.load();
  }

  load() {
    this.pager.load(this.filterParams);
  }

  get loading() {
    if (this.pager) {
      return this.pager.loading;
    } else {
      return true;
    }

  }


  returnToSummary() {

    this.layout.setFullscreen(false);
    this.router.navigate(['./revalidation/review']);

  }

  togglePanel(panel) {
    if (this.panel === panel) {
      this.panel = undefined;
    } else {
      this.panel = panel;
    }
  }
  clearAllFilters() {
    this.clearFilterParams();
    this.load();
  }
  clearFilter(filter) {
    this.filterParams[filter] = undefined;
    if (this.hasActiveFilterParams) {
      this.load();
    }
  }


  emptyFilterParams() {
    return {
      maxDaysLate: undefined,
      renewalDate: undefined,
      inRemediationThisYear: undefined,
      reviewSelectionStatus: undefined,
      registrationNumber: undefined,
      registrationStatus: undefined
    };
  }
  clearFilterParams() {
    this.filterParams = this.emptyFilterParams();
  }

  samplingCreated(renewalDate) {
    this.clearFilterParams();
    this.filterParams.renewalDate = renewalDate;
    this.panel = undefined;
    this.load();
  }

  cohortCreated() {
    this.panel = undefined;
  }

  get filterParamsAreActive() {
    if (!this.hasActiveFilterParams) {
      return false;
    }
    for (const key in this.filterParams) {
      if (this.filterParams.hasOwnProperty(key)) {
        if (this.filterParams[key] !== undefined && this.activeFilterParams[key] === undefined) {
          return false;
        }
        if (this.activeFilterParams[key] !== undefined && this.filterParams[key] === undefined) {
          return false;
        }
        if (this.activeFilterParams[key] !== this.filterParams[key]) {
          return false;
        }
      }
    }
    return true;
  }

  get hasActiveFilterParams() {
    for (const key in this.activeFilterParams) {
      if (this.activeFilterParams[key] !== undefined && this.activeFilterParams[key] !== null && this.activeFilterParams[key] !== '') {
        return true;
      }
    }
    return false;
  }

  get hasFilterParams() {
    for (const key in this.filterParams) {
      if (this.filterParams[key] !== undefined && this.filterParams[key] !== null && this.filterParams[key] !== '') {
        return true;
      }
    }
    return false;
  }



  initializeCohort() {
    this.initialisingCohort = true;
    this.service.initializeCohort(this.renewalDate, this.randomSamplePercent).subscribe(result => {
      this.initialisingCohort = false;
      this.cohortId = result.cohortId;
      this.load();
    });
  }

  getSelectionStatuses() {
    const out = [];
    for (const enumMember in ReviewSelectionStatus) {
      if (!isNaN(parseInt(enumMember, 10))) {
        if (ReviewSelectionStatus[enumMember] !== 'Unknown' && ReviewSelectionStatus[enumMember].toLowerCase().indexOf('exempt') === -1) {
          out.push({
            key: enumMember,
            value: ReviewSelectionStatus[enumMember]
          });
        }
      }
    }
    return out;
  }



  onLoad(data) {
    this.activeFilterParams = Object.assign({}, this.filterParams);

    const formatParamValue = (key, value) => {
      switch (key) {
        case 'reviewSelectionStatus':
          return this.caseSplitPipe.transform(ReviewSelectionStatus[value]);
        case 'registrationStatus':
          return this.RegistrationStatus[value];
        case 'renewalDate':
          return 'Renewal date: ' + this.datePipe.transform(value, 'dd/MM/yyyy');
        case 'inRemediationThisYear':
          return value ? 'In remediation' : 'Not in remediation';
        case 'maxDaysLate':
          return 'Up to ' + value + ' days late';
        default:
          return this.caseSplitPipe.transform(key) + ': ' + value;
      }
    };

    this.activeFilterParamsInfo = Object.keys(this.activeFilterParams).map(key => {
      if (this.activeFilterParams[key] !== undefined) {
        return { key, value: formatParamValue(key, this.activeFilterParams[key]) };
      }
      return false;
    }).filter(v => !!v);
    if (data.length) {
      this.panel = undefined;
    }
    this.data = data;

  }




}
