import { Component, OnInit, EventEmitter } from '@angular/core';
import { LayoutService } from './service/layout.service';
import { MaintenanceService } from './service/maintenance.service';
import { ConfirmDialogComponent } from '../shared/confirmDialog.component';
import { MaintenanceMessageDetails } from './model/MaintenanceMessageDetails';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

@Component({
    templateUrl: './maintenance.component.html',
    styleUrls: ['maintenance.scss']
})
export class MaintenanceFormComponent implements OnInit {
    loading = false;
    data;

    model: MaintenanceMessageDetails = {
        messageTitle: '',
        messageBody: '',
        messageActive: false,
        showLogin: false
    };

    constructor(
        public layout: LayoutService,
        public service: MaintenanceService,
        public dialog: MatDialog
    ) {
    }

    ngOnInit() {
        this.getMessage();
    }

    getMessage() {
        this.service.getCurrentMessage().subscribe((message: any) => {
            console.table(message.value);
            this.data = true;
            const { messageTitle, messageBody,
                showLogin, messageActive } = message.value;
            this.model.messageTitle = messageTitle;
            this.model.messageBody = messageBody;
            this.model.showLogin = showLogin;
            this.model.messageActive = messageActive;
        }, error => {
            this.data = false;
            console.error(error);
        });
    }


    save(maintenanceForm: NgForm) {
        this.loading = true;
        if (maintenanceForm.value.showLogin === null) {
            maintenanceForm.value.showLogin = false;
        }

        this.service.saveCurrentMessage(maintenanceForm.value)
            .subscribe(() => {
                this.loading = false;
                this.dialog.open(ConfirmDialogComponent, {
                    data: {
                        allowCancel: false,
                        title: 'Data Saved',
                        message: `The message has been saved correctly.`,
                        confirmText: 'Done'
                    }
                });
            }, () => {
                this.loading = false;
            });
    }

    clear(maintenanceForm: NgForm) {
        this.data = false;
        maintenanceForm.reset();
    }


    userAcceptsResponsibility(maintenanceForm: NgForm) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Confirm submission',
                message: `Are you sure you want to update this message, any previously saved
                    data will be overwritten ?`
            }
        });
        dialogRef.afterClosed().subscribe((userAcceptResponsibility) => {
            if (userAcceptResponsibility) {
                // this.model.messageActive = false;
                this.save(maintenanceForm);
                this.data = true;
            } else {
                this.data = false;
            }
        });
    }


}
