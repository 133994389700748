import { Injectable } from '@angular/core';
import { ServiceBase } from '../../core/service/service.base';
import { AuthService } from '../../core/service/auth.service';
import { LogService } from '../../core/service/log.service';
import { CustomErrorHandler } from '../../core/service/CustomErrorHandler';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { NavigationExtras } from '@angular/router';

const mockRegistrantsInRemediation = [
  {
    registrationNumber: 123456,
    registeredName: 'Jeremy Corbyn',
    remediationEndDate: '2018-06-24',
    hasOutstandingExceptionalCircumstance: true,
    remediationStage: '',
    receivesEssentailEmails: true,
    receivesEssentailTexts: false,
    fullSubmissionReceivedForCurrentStage: true,
    alsoPharmacist: true,
    reviewStage: ''
  },
  {
    registrationNumber: 987654,
    registeredName: 'Teresa May',
    remediationEndDate: '2018-06-24',
    hasOutstandingExceptionalCircumstance: false,
    remediationStage: '',
    receivesEssentailEmails: true,
    receivesEssentailTexts: true,
    fullSubmissionReceivedForCurrentStage: true,
    alsoPharmacist: true,
    reviewStage: ''
  },
  {
    registrationNumber: 6543223,
    registeredName: 'Donald Trump',
    remediationEndDate: '2018-06-24',
    hasOutstandingExceptionalCircumstance: true,
    remediationStage: '',
    receivesEssentailEmails: true,
    receivesEssentailTexts: false,
    fullSubmissionReceivedForCurrentStage: true,
    alsoPharmacist: false,
    reviewStage: ''
  },
  {
    registrationNumber: 123456,
    registeredName: 'Joe Hickey',
    remediationEndDate: '2018-06-24',
    hasOutstandingExceptionalCircumstance: true,
    remediationStage: '',
    receivesEssentailEmails: true,
    receivesEssentailTexts: false,
    fullSubmissionReceivedForCurrentStage: true,
    alsoPharmacist: true,
    reviewStage: ''
  }, {
    registrationNumber: 123456,
    registeredName: 'Jeremy Corbyn',
    remediationEndDate: '2018-06-24',
    hasOutstandingExceptionalCircumstance: true,
    remediationStage: '',
    receivesEssentailEmails: true,
    receivesEssentailTexts: false,
    fullSubmissionReceivedForCurrentStage: true,
    alsoPharmacist: true,
    reviewStage: ''
  },
  {
    registrationNumber: 987654,
    registeredName: 'Teresa May',
    remediationEndDate: '2018-06-24',
    hasOutstandingExceptionalCircumstance: false,
    remediationStage: '',
    receivesEssentailEmails: true,
    receivesEssentailTexts: true,
    fullSubmissionReceivedForCurrentStage: true,
    alsoPharmacist: true,
    reviewStage: ''
  },
  {
    registrationNumber: 6543223,
    registeredName: 'Donald Trump',
    remediationEndDate: '2018-06-24',
    hasOutstandingExceptionalCircumstance: true,
    remediationStage: '',
    receivesEssentailEmails: true,
    receivesEssentailTexts: false,
    fullSubmissionReceivedForCurrentStage: true,
    alsoPharmacist: false,
    reviewStage: ''
  },
  {
    registrationNumber: 123456,
    registeredName: 'Joe Hickey',
    remediationEndDate: '2018-06-24',
    hasOutstandingExceptionalCircumstance: true,
    remediationStage: '',
    receivesEssentailEmails: true,
    receivesEssentailTexts: false,
    fullSubmissionReceivedForCurrentStage: true,
    alsoPharmacist: true,
    reviewStage: ''
  }, {
    registrationNumber: 123456,
    registeredName: 'Jeremy Corbyn',
    remediationEndDate: '2018-06-24',
    hasOutstandingExceptionalCircumstance: true,
    remediationStage: '',
    receivesEssentailEmails: true,
    receivesEssentailTexts: false,
    fullSubmissionReceivedForCurrentStage: true,
    alsoPharmacist: true,
    reviewStage: ''
  },
  {
    registrationNumber: 987654,
    registeredName: 'Teresa May',
    remediationEndDate: '2018-06-24',
    hasOutstandingExceptionalCircumstance: false,
    remediationStage: '',
    receivesEssentailEmails: true,
    receivesEssentailTexts: true,
    fullSubmissionReceivedForCurrentStage: true,
    alsoPharmacist: true,
    reviewStage: ''
  },
  {
    registrationNumber: 6543223,
    registeredName: 'Donald Trump',
    remediationEndDate: '2018-06-24',
    hasOutstandingExceptionalCircumstance: true,
    remediationStage: '',
    receivesEssentailEmails: true,
    receivesEssentailTexts: false,
    fullSubmissionReceivedForCurrentStage: true,
    alsoPharmacist: false,
    reviewStage: ''
  },
  {
    registrationNumber: 123456,
    registeredName: 'Joe Hickey',
    remediationEndDate: '2018-06-24',
    hasOutstandingExceptionalCircumstance: true,
    remediationStage: '',
    receivesEssentailEmails: true,
    receivesEssentailTexts: false,
    fullSubmissionReceivedForCurrentStage: true,
    alsoPharmacist: true,
    reviewStage: ''
  }
];




@Injectable()
export class ExtCircService extends ServiceBase {

  constructor(http: HttpClient, auth: AuthService, log: LogService, errorHandler: CustomErrorHandler) {
    super(http, auth, log, errorHandler);
  }

  getAll() {
    
    return this.get('v1.0/user/extenuatingcircumstances');
  }

  getOne(id: string) {
    return this.get(`v1.0/user/extenuatingcircumstances/${id}`);
  }



  download(url: string) {
    return this.getFile(url);
  }

  assignItem(id, userId) {
    return this.post(`v1.0/user/extenuatingcircumstances/${id}/assign`,
      {
        ObjectId: userId
      });
  }

  // legacy
  reject(id: string, reason: string) {
    return this.post(`v1.0/user/extenuatingcircumstances/${id}/reject`, { reason });
  }
  // legacy
  approve(id: string, reason: string) {
    return this.post(`v1.0/user/extenuatingcircumstances/${id}/approve`, { reason });
  }

  saveRecommendation(payload) {
    return this.post(`v1.0/user/extenuatingcircumstances/${payload.id}/review`, payload);
  }

  saveDecision(payload) {
    return this.post(`v1.0/user/extenuatingcircumstances/${payload.id}/decision`, payload);
  }

  submitDecision(id) {
    return this.post(`v1.0/user/extenuatingcircumstances/${id}/submit`, {});
  }


  search(regNumber) {
    return this.get(`v1.0/user/extenuatingcircumstances?registrationNumber=${regNumber}`);
  }

  filter(status) {
    return this.get(`v1.0/user/extenuatingcircumstances?status=${status}`);
  }


  getRemediationSummary() {
    return super.get(`v1.0/revalidation/getRegistrantSummary`);
  }


  getRegistrantsInRemediation(submissionDeadline, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInRemediation?SubmissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}`);
  }

  getRegistrantsInNoir(submissionDeadline, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInNoIR?submissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}`);
  }

  getRegistrantsInNor(submissionDeadline, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInNoR?submissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}`);
  }

  issueNoir(regNumber) {
    // TODO: implement call to API
    return of('');
  }

  moveRegistrantToNOR(revalidationId) {
    return super.post(`v1.0/admin/revalidation/updateStage?revalidationId=${revalidationId}&stage=4`, {});
  }

  moveRegistrantToNoir(revalidationId) {
    return super.post(`v1.0/admin/revalidation/updateStage?revalidationId=${revalidationId}&stage=3`, {});
  }

  issueSupplementaryNoir(revalidationId) {
    return super.post(`v1.0/admin/revalidation/issueSupplementaryNoIR/?revalidationId=${revalidationId}`, {});
  }

  issueNorLetter(revalidationId, date) {
    return super.post(`v1.0/admin/revalidation/issueNoRLetter`, { revalidationId,  NoRLetterIssuedAt: date});
  }

  searchRegistrationNumberNor(submissionDeadline, regNumber, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInNoR/?SubmissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}&registrationNumber=${regNumber}`);
  }

  searchRegistrationNumberNoir(submissionDeadline, regNumber, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInNoIR/?SubmissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}&registrationNumber=${regNumber}`);
  }

  searchRegistrationNumberRemediation(submissionDeadline, regNumber, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInRemediation/?SubmissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}&registrationNumber=${regNumber}`);
  }

  filterByManuallyEditedNor(submissionDeadline, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInNoR/?SubmissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}&isManual=true`);
  }

  filterByManuallyEditedNir(submissionDeadline, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInNoIR/?SubmissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}&isManual=true`);
  }

  filterByManuallyEditedRemediation(submissionDeadline, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInRemediation/?SubmissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}&isManual=true`);
  }

  filterByNorIssued(submissionDeadline, skip, take) {
    return super.get(`v1.0/revalidation/getRegistrantsInNoR/?SubmissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}&isNoRIssued=true`);
  }

  filterByNOIRIssued(submissionDeadline,skip, take, supNirIssued: boolean) {
    return super.get(`v1.0/revalidation/getRegistrantsInNoIR?submissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}&WasNoIRSupplementaryIssued=${supNirIssued}`);
  }

  filterByNOIRIssuedDate(submissionDeadline,skip, take, deadline) {
    return super.get(`v1.0/revalidation/getRegistrantsInNoIR?submissionDeadline=${submissionDeadline}&skip=${skip}&take=${take}&NoIRSupplementaryDeadline=${deadline}`);
  }

  //MAE-12: Endpoint: Granted extension for submission but not submitted revalidations

  notSubmittedRevalidation(payload): Observable<any> {
    return super.post('v1.0/admin/registrant/revalidations/notsubmitted', payload);
  }

}
