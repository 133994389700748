import { NgModule, ErrorHandler } from '@angular/core';
import { LogService } from '../core/service/log.service';
import { CustomErrorHandler } from '../core/service/CustomErrorHandler';
import { ExtCircItemComponent } from './exceptionalCircumstances/extCircItem.component';
import { ExtCircListComponent } from './exceptionalCircumstances/extCircList.component';
import { ExtCircService } from './service/extCirc.service';
import { ExtCircApproveRejectDialogComponent } from './exceptionalCircumstances/extCircApproveRejectDialog.component';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { ExtCircAssignComponent } from './exceptionalCircumstances/extCircAssign.component';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { DynamicModule } from '../dynamic/dynamic.module';
import { ExtCircRequestComponent } from './exceptionalCircumstances/extCircRequest.component';
import { RevalidationComponent } from './revalidation.component';
import { RemediationDashboardComponent } from './remediation/remediationDashboard.component';
import { RemediationListComponent } from './remediation/remediationList.component';
import { ConfirmDateDialogComponent } from './remediation/confirmDateDialog.component';
import { ReviewSummaryComponent } from './review/reviewSummary.component';
import { ReviewService } from './service/review.service';
import { QaCohortComponent } from './review/qaCohort.component';
import { ExternalReviewCohortComponent } from './review/externalReviewCohort.component';
import { ReviewComponent } from './review/detail/review.component';
import { FeedbackSectionsComponent } from './review/detail/feedbackSections.component';
import { DiscussionComponent } from './review/detail/discussion.component';
import { CpdEntryComponent } from './review/detail/cpd/cpdEntry.component';
import { CpdEntryAnnotationsComponent } from './review/detail/cpd/cpdEntryAnnotations.component';
import { CpdEntryAnswersComponent } from './review/detail/cpd/cpdEntryAnswers.component';
import { AssessmentComponent } from './review/detail/assessment.component';
import { ReviewBacklogComponent } from './review/reviewBacklog.component';
import { SamplingComponent } from './review/sampling.component';
import { ReviewCohortCreateComponent } from './review/reviewCohortCreate.component';
import { FeedbackSectionsReadonlyComponent } from './review/detail/feedbackSectionsReadonly.component';
import { ChangeReviewersComponent } from './review/changeReviewers.component';
import { ReviewAssignComponent } from './review/reviewAssign.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { RevalidationDetailsComponent } from './details/revalidation-details.component';
import { SubmissionEnquiryService } from './service/submissionEnquiry.service';
import { ExtCircGrantedRevalidationsComponent } from './extCircGrantedRevalidations/extCirc-granted-revalidations.component';
import { MatSliderModule } from '@angular/material/slider';

@NgModule({
    imports: [
        CoreModule,
        DynamicModule,
        SharedModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatSliderModule
    ],
    declarations: [
        ExtCircItemComponent,
        ExtCircListComponent,
        ExtCircApproveRejectDialogComponent,
        ExtCircAssignComponent,
        ExtCircRequestComponent,
        RemediationDashboardComponent,
        RevalidationComponent,
        RemediationListComponent,
        ConfirmDateDialogComponent,
        ReviewSummaryComponent,
        QaCohortComponent,
        ExternalReviewCohortComponent,
        ReviewComponent,
        FeedbackSectionsComponent,
        FeedbackSectionsReadonlyComponent,
        DiscussionComponent,
        CpdEntryComponent,
        CpdEntryAnnotationsComponent,
        CpdEntryAnswersComponent,
        AssessmentComponent,
        ReviewBacklogComponent,
        SamplingComponent,
        ReviewCohortCreateComponent,
        ChangeReviewersComponent,
        ReviewAssignComponent,
        RevalidationDetailsComponent,
        ExtCircGrantedRevalidationsComponent
    ],
    providers: [
        LogService,
        ExtCircService,
        ReviewService,
        SubmissionEnquiryService,
        { provide: ErrorHandler, useClass: CustomErrorHandler },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
    ]
})
export class RevalidationModule { }
