import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { environment } from '../environments/environment';

// this checks if the app is running on IE
export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
}

export const msalConfig = {
    auth: {
        clientId: environment.msal.auth.clientId,
        authority: environment.msal.auth.authority,
        redirectUri: environment.msal.auth.redirectUri,
        postLogoutRedirectUri: environment.msal.auth.postLogoutRedirectUri,
        navigateToLoginRequestUrl: false      
      },
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: isIE, // set to true for IE 11
      }
}

export function getClientApplication(config): IPublicClientApplication  {
    return new PublicClientApplication(config);
}

export function MSALInstanceFactory(): IPublicClientApplication  {
    return new PublicClientApplication({
        auth: environment.msal.auth,
        cache: environment.msal.cache,
        system: {
          // loggerOptions: {
          //   loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
          //       if (containsPii) {
          //           return;
          //       }
          //       switch (level) {
          //           case LogLevel.Error:
          //               console.error(message);
          //               return;
          //           case LogLevel.Info:
          //               console.info(message);
          //               return;
          //           case LogLevel.Verbose:
          //               console.debug(message);
          //               return;
          //           case LogLevel.Warning:
          //               console.warn(message);
          //               return;
          //       }
          //   }
          // }
        }
    });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set(environment.api.root, [environment.msal.auth.scope]);
  
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap,
    };
  }
  
  export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return { 
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['user.read']
      },
      loginFailedRoute: './login-failed'
    };
  }