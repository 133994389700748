// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep mat-option label {
  float: right;
  font-size: 0.8em;
  color: #999;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/autocomplete.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EAAY,gBAAA;EAAgB,WAAA;AAE9B","sourcesContent":["\r\n::ng-deep mat-option label {\r\n  float:right;font-size:0.8em;color:#999;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
