// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep h2 {
  font-family: "Open sans", sans-serif !important;
  margin-top: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/prereg/application.scss"],"names":[],"mappings":"AACE;EACE,+CAAA;EACA,gBAAA;AAAJ","sourcesContent":[":host {\r\n  ::ng-deep h2 {\r\n    font-family: 'Open sans', sans-serif !important;\r\n    margin-top: 50px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
