import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ExtCircService } from '../service/extCirc.service';
import { ExtCirc } from '../model/ExtCirc';
import { LogService } from '../../core/service/log.service';
import { AccountService } from '../../accounts/service/account.service';
import { AuthService } from '../../core/service/auth.service';
import { ReviewStatus } from '../model/ReviewStatus';
import { Review } from '../model/Review';
import { ReviewService } from '../service/review.service';

@Component({
  selector: 'app-review-assign',
  templateUrl: './reviewAssign.component.html'
})
export class ReviewAssignComponent implements OnInit {

  @Input() item: Review;

  @Input('selecting') set setSelecting(selecting) {
    this.selecting = selecting;
    if (selecting) {
      this.showUsers();
    }
  }
  @Output() assigned = new EventEmitter<Review>();
  @Output() selected = new EventEmitter<Review>();

  users;
  usernames;
  loading = false;
  saving = false;
  selecting = false;
  origValue;
  ReviewStatus = ReviewStatus;

  constructor(private service: ReviewService, private auth: AuthService, private accounts: AccountService, private log: LogService) { }

  ngOnInit() {
    this.origValue = this.item.assignedTo;
  }

  select($event) {
    this.selected.emit(this.item);
    $event.stopPropagation();
  }

  cancel() {
    this.selected.emit(undefined);
  }

  showUsers() {
    this.loading = true;
    this.accounts.getAdminUsers().subscribe(users => {
      this.users = users;
      this.usernames = users.map(user => user.displayName).sort((a, b) => {
        const aBits = a.split(' ');
        const aSurname = aBits[aBits.length - 1];
        const bBits = b.split(' ');
        const bSurname = bBits[bBits.length - 1];
        return aSurname > bSurname ? 1 : -1;
      });
      const authUser = this.auth.user;
      if (this.usernames.find(username => username === authUser?.profile?.name)) {
        this.usernames = this.usernames.filter(username => username !== authUser?.profile?.name);
        this.usernames.unshift(authUser?.profile?.name);
      }
      this.loading = false;
    });
  }

  assignItem(item: Review, username) {
    if (username === this.origValue) { this.cancel(); return; }
    this.saving = true;
    const userId = this.users.find(user => user.displayName === username).objectId;
    this.service.assignItem(item.id, userId).subscribe(() => {
      item.assignedAt = new Date();
      item.assignedTo = username;
      this.assigned.emit(item);
      this.saving = false;
      this.cancel();
    }, error => {
      this.log.flag(this.log.error(error.message));
      this.saving = false;
    });
  }

}
