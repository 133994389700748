import { Component, OnInit, Input } from '@angular/core';
import { EducationDetails } from '../model/EducationDetails';
import { QualificationType } from '../model/QualificationType';
import { TechnicianService } from '../service/technician.service';

@Component({
  selector: 'app-application-education-details',
  templateUrl: './application-education-details.component.html',
  styleUrls: ['./application-education-details.component.css']
})
export class ApplicationEducationDetailsComponent implements OnInit {
  @Input() educationDetails: EducationDetails;
  courseTypes = {};

  constructor(private service: TechnicianService) { }

  ngOnInit() {
    this.loadQualifications();
  }

  loadQualifications () {
    if(this.educationDetails.combined === null) {
      this.service.getQualificationList(QualificationType.knowledge).subscribe(qualifications => this.courseTypes[QualificationType.knowledge] = qualifications);
      this.service.getQualificationList(QualificationType.competency).subscribe(qualifications => this.courseTypes[QualificationType.competency] = qualifications);
    } else {
      this.service.getQualificationList(QualificationType.combined).subscribe(qualifications => this.courseTypes[QualificationType.combined] = qualifications);
    }       
  }

  getCourseTypes(type: QualificationType)  {
    if(!this.courseTypes) return null;
    let courses = this.courseTypes[QualificationType[type]];
    return courses || null;
  }

}
