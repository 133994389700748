import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../core/service/layout.service';
import { Router } from '@angular/router';
import { RevalidationStatus } from '../model/RevalidationStatus';
import { ExtCircService } from '../service/extCirc.service';

@Component({
  templateUrl: './remediationDashboard.component.html',
  styleUrls: ['../list.scss', './remediationDashboard.scss']
})
export class RemediationDashboardComponent implements OnInit {

  loading = false;
  data = [];
  RevalidationStatus = RevalidationStatus;

  constructor(private router: Router, public layout: LayoutService, private service: ExtCircService) { }

  ngOnInit() {
    this.load();
  }

  showRegistrants(submissionDeadline, revalidationStatus, totalCount) {
    this.router.navigate(['/revalidation/remediation/list', revalidationStatus, submissionDeadline], { queryParams: { totalCount }});
  }

  load() {
    this.loading = true;

    const process = data => data.reduce((acc, item) => {
      const row = acc.find(r => r.submissionDeadline === item.revalidationSubmissionDate);
      if (row) {
        row[RevalidationStatus[item.revalidationStatus]] = item.registrantCount;
      } else {
        const newRow = { submissionDeadline: item.revalidationSubmissionDate };
        newRow[RevalidationStatus[item.revalidationStatus]] = item.registrantCount;
        acc.push(newRow);
      }
      return acc;
    }, []);

    this.service.getRemediationSummary().subscribe(data => {
      this.data = process(data);
      this.data = this.data.sort((a, b) => {
        return new Date(a.submissionDeadline) > new Date(b.submissionDeadline) ? -1 : 1;
      });
      this.loading = false;
    });
  }



}
