import { Component, OnInit } from '@angular/core';
import { LogService } from './service/log.service';
import { Log } from './model/Log';
import { LogLevel } from './model/LogLevel';
import { DevService } from './service/dev.service';
import { AuthService } from './service/auth.service';
import { environment } from '../../environments/environment';
import { Environment } from '../../environments/model/Environment';


@Component({
  selector: 'app-diagnostics',
  moduleId: module.id,
  templateUrl: './diagnostics.component.html',
  styleUrls: ['./diagnostics.scss']
})
export class DiagnosticsComponent implements OnInit {

  loading = false;
  error = false;
  updating = false;
  environment;

  logs: Log[] = [];

  constructor(private log: LogService, private dev: DevService, private authService: AuthService) {
    this.environment = environment;
  }

  get user() {
    if (this.authService.user) {
      return this.authService.user;
    } else {
      return undefined;
    }
  }

  refreshLogs() {
    this.logs = this.log.logs
    .sort((a, b) => {
      if (a.date < b.date) { return 1; }
      if (a.date > b.date) { return -1; }
      if (a.text < b.text) { return 1; }
      return -1;
    });
  }

  ngOnInit() {
    this.refreshLogs();
  }

}
