import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountService } from './service/account.service';

import { UnlockAccountResultDialogComponent } from './unlockAccountResultDialog.component';

@Component({
  selector: 'app-unlock-account',
  templateUrl: './unlockAccount.component.html',
  styleUrls: ['./unlockAccount.scss']
})
export class UnlockAccountComponent {

  unlockModel = {
    registrationNumber: '',
    email: '',
    active: true
  };
  loading = false;
  validationErrors = [];

  constructor(private service: AccountService, private dialog: MatDialog) { }

  unlock() {
    if (this.loading) { return; }
    if (!(this.unlockModel.registrationNumber || this.unlockModel.email)) { return; }

    this.loading = true;
    this.validationErrors = [];
    this.service.unlock(this.unlockModel).subscribe(result => {
      this.loading = false;
      this.showResult(result);
    }, (err) => {
      this.loading = false;
      if (err.validationErrors) {
        this.validationErrors = err.validationErrors;
      }
    });
  }

  showResult(result) {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(UnlockAccountResultDialogComponent, {
        data: { result, request: this.unlockModel }
      });
      dialogRef.afterClosed().subscribe(() => {
        this.unlockModel = {
          registrationNumber: '',
          email: '',
          active: true
        };
      });
    });
  }

}
