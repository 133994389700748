import { Pipe, PipeTransform } from '@angular/core';
import { UserActivityReports } from '../../queries/model/UserActivityReports';

@Pipe({
  name: 'revalSubmissionQuerySearch'
})
export class RevalSubmissionQueryPipe implements PipeTransform {

  transform(revalidationSubmissionList: UserActivityReports[], term: any): any {
    if (term === undefined) {
      return revalidationSubmissionList;
    }

    const isRegNumber = Number(term);
    if (isRegNumber) {
      return this.filterByRegNumber(revalidationSubmissionList, term);
    } else {
      return this.filterByEmailAddress(revalidationSubmissionList, term);
    }

  }

  filterByRegNumber(revalSubmissionList, term) {
    return revalSubmissionList.filter((revalSubmission: UserActivityReports) => {
      return revalSubmission.user[0].registrationNumber.includes(term)
    });
  }

  filterByEmailAddress(revalSubmissionList, term) {
    return revalSubmissionList.filter((revalSubmission: UserActivityReports) => {
      return revalSubmission.user[0].email.toLowerCase().includes(term)
    });
  }

}
