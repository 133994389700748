import { Component, Input, OnInit} from '@angular/core';
import { AttachmentType } from './model/attachmentType';
import { PreregApplicationService } from './service/prereg.service';
import { environment } from '../../environments/environment';
import { WorkExperience } from './model/WorkExperience';

@Component({
    selector: 'app-technician-application',
    templateUrl: './technicianApplication.component.html',
    styleUrls: ['./application.scss']
})
export class TechnicianApplicationComponent implements OnInit{
    @Input() application;
    attachmentType = AttachmentType;
    downloading = false;
    downloadClickCount = 0;
    workExperiences: WorkExperience[];

    constructor(
        private service: PreregApplicationService
    ) {}

    ngOnInit() {
        this.application.workExperiences = this.application.workExperiences.map(experience => new WorkExperience(experience));
        this.workExperiences = this.application.workExperiences;
    }

    nth(i) {
        return i === 0 ? 'first' : (i === 1 ? 'second' : 'third');
      }

}

