import { Component, Input, Output, EventEmitter } from '@angular/core';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.scss']
})
export class PagerComponent {

  @Input() pageNum = 1;
  @Input() pageSize = 20;
  @Input() totalCount;
  @Input() datasource;
  @Output() loaded = new EventEmitter();

  loading = true;
  params;
  data = [];

  get pageTo() {
    const pageTo = this.pageNum * this.pageSize;
    return pageTo > this.totalCount ? this.totalCount : pageTo;
  }
  get pageFrom() {
    return ((this.pageNum - 1) * this.pageSize) + 1;
  }

  nextPage() {
    this.pageNum++;
    this.load();
  }

  prevPage() {
    this.pageNum--;
    this.load();
  }

  load(params?) {
    this.loading = true;
    if (params) {
      this.pageNum = 1;
      this.params = Object.assign({}, params);
    }
    this.params = this.params || {};
    this.params.skip = (this.pageNum - 1) * this.pageSize;
    this.params.take = this.pageSize;
    const pager = { totalCount: 0 }; // container to hold totalCount returned from service
    this.datasource(this.params, pager)
      .pipe(tap(() => {
        this.loading = false;
        this.totalCount = pager.totalCount;
      }))
      .subscribe(data => this.loaded.emit(data));
     

  }

}
