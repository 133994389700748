import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../core/service/layout.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RevalidationStatus } from '../model/RevalidationStatus';
import { ExtCircService } from '../service/extCirc.service';
import { CrmRemediationStatus } from '../model/CrmRemediationStatus';
import { UtcDatePipe } from '../../shared/pipe/UtcDate.pipe';
import { ConfirmDialogComponent } from '../../shared/confirmDialog.component';

import { ConfirmDateDialogComponent } from './confirmDateDialog.component';
import { Tooltip } from '../../core/tooltip/Tooltip';
import { LogService } from '../../core/service/log.service';
import { RegistrantStatus } from '../../accounts/model/RegistrantStatus';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-remediation-list',
  templateUrl: './remediationList.component.html',
  styleUrls: ['../list.scss', './remediationList.scss']
})
export class RemediationListComponent implements OnInit {

  loading = false;
  items = [];
  submissionDeadline;
  revalidationStatus;
  revalidationStatusString;
  heading;
  subHeading;
  isNIR;
  isNOR;
  isRemediation;

  moving;
  issuingNorLetter;
  issuingSupplementaryNir;
  manuallyEdited = false;
  norIssued = false;
  hasIssuedSupplimentaryNir = false;
  searchCriteria = {
    date: null
  }

  ReviewStage = CrmRemediationStatus;
  RemediationStage = RevalidationStatus;
  RegistationStatus = RegistrantStatus;

  searchVisible = false;
  regNumber;
  @ViewChild('regNumberInput',) regNumberRef: ElementRef;

  pageNum = 0;
  pageSize = 20;
  totalCount;

  manualTooltip: Tooltip = {
    id: 'manual',
    content: 'This revalidation has entered manual processing',
    width: 290,
    placement: 'top',
    order: -1
  };
  toNoirTooltip: Tooltip = {
    id: 'toNIR',
    content: 'Move this revalidation record to the NIR dashboard',
    width: 290,
    placement: 'top',
    order: -1
  };
  toNorTooltip: Tooltip = {
    id: 'toNOR',
    content: 'Move this revalidation record to the NOR dashboard',
    width: 290,
    placement: 'top',
    order: -1
  };
  supplementaryNIRTooltip: Tooltip = {
    id: 'supNOR',
    content: 'Issue a supplementary NIR for this revalidation',
    width: 290,
    placement: 'top',
    order: -1
  };
  issueNorLetterTooltip: Tooltip = {
    id: 'issueNor',
    content: 'Select a date to issue a letter of NOR',
    width: 290,
    placement: 'top',
    order: -1
  };

  helpVisible = false;
  suppNirNotIssued: boolean;

  toggleHelp() {
    this.helpVisible = !this.helpVisible;
  }

  toggleSearch() {
    this.searchVisible = !this.searchVisible;
    if (this.searchVisible) {
      setTimeout(() => {
        this.regNumberRef.nativeElement.focus();
      });
    }
  }

  constructor(public layout: LayoutService, private router: Router,
    private activatedRoute: ActivatedRoute, private service: ExtCircService,
    private utcDatePipe: UtcDatePipe, private dialog: MatDialog, private log: LogService) {
    this.submissionDeadline = this.activatedRoute.snapshot.params['submissionDeadline'];
    this.revalidationStatus = +this.activatedRoute.snapshot.params['revalidationStatus'];
    this.totalCount = this.activatedRoute.snapshot.queryParams['totalCount'];
    if (!this.totalCount) {
      // throw new Error('Total count not provided. List view must be accessed from the summary dashboard');
      this.totalCount = 1;
    }
    this.revalidationStatusString = RevalidationStatus[this.revalidationStatus];
    this.isRemediation = this.revalidationStatus === RevalidationStatus.Remediation;
    this.isNIR = this.revalidationStatus === RevalidationStatus.NIR;
    this.isNOR = this.revalidationStatus === RevalidationStatus.NOR;
  }

  getEndDate(item) {
    switch (this.revalidationStatus) {
      case RevalidationStatus.NIR:
        return item.noirEndDate;
      case RevalidationStatus.NOR:
        return item.norEndDate;
      case RevalidationStatus.Remediation:
        return item.remediationEndDate;
    }
  }



  get pageTo() {
    const pageTo = (this.pageNum + 1) * this.pageSize;
    return pageTo > this.totalCount ? this.totalCount : pageTo;
  }
  get pageFrom() {
    return (this.pageNum * this.pageSize) + 1;
  }

  nextPage() {
    this.pageNum++;
    this.load();
  }

  prevPage() {
    this.pageNum--;
    this.load();
  }

  getStage(item) {
    switch (this.revalidationStatus) {
      case RevalidationStatus.NIR:
        return item.noirStage;
      case RevalidationStatus.NOR:
        return item.norStage;
      case RevalidationStatus.Remediation:
        return item.remediationStage;
    }
  }

  ngOnInit() {
    this.heading = `Registrants in ${this.revalidationStatusString}`;
    this.subHeading = `Submission deadline ${this.utcDatePipe.transform(this.submissionDeadline)}`;
    this.load();
  }

  exit() {
    this.router.navigate(['revalidation/remediation']);
  }

  load() {
    this.loading = true;
    const skip = this.pageNum * this.pageSize;
    const take = this.pageSize;

    switch (this.revalidationStatus) {
      case RevalidationStatus.NIR:
        this.service.getRegistrantsInNoir(this.submissionDeadline, skip, take).subscribe(data => {
          this.totalCount = data.totalCount;
          data = data.records.map(item => {
            item.remediationStage = item.NOiRStage;
            return item;
          });
          this.items = data;
          this.moving = false;
          this.loading = false;
        });
        break;
      case RevalidationStatus.NOR:
        this.service.getRegistrantsInNor(this.submissionDeadline, skip, take).subscribe(data => {
          this.totalCount = data.totalCount;
          data = data.records.map(item => {
            item.remediationStage = item.NoRStage || item.NORStage; // what casing are they using ??
            return item;
          });
          this.items = data;
          this.moving = false;
          this.loading = false;
        });
        break;

      default:
        this.service.getRegistrantsInRemediation(this.submissionDeadline, skip, take).subscribe(data => {
          this.totalCount = data.totalCount;
          this.items = data.records;
          this.loading = false;
        });

    }
  }

  moveToNor(item) {
    const revalidationSubmissionId = item.revalidationSubmissionId;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Move registrant to NOR`,
        message: `Are you sure you would like to move this registrant: ${item.registeredName} to NOR,
         this registrant will be available under the NOR dashboard ?`
      }
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.moving = item;
        this.service.moveRegistrantToNOR(revalidationSubmissionId).subscribe(result => {
          // refresh the list
          this.load();
        });
      }
    });
  }

  moveToNoir(item) {
    const revalidationSubmissionId = item.revalidationSubmissionId;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Move registrant to NOIR`,
        message: `Are you sure you would like to move this registrant: ${item.registeredName} to NOIR,
        this registrant will be available under the NOIR dashboard ?`
      }
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.moving = item;
        this.service.moveRegistrantToNoir(revalidationSubmissionId).subscribe(result => {
          // refresh the list
          this.load();
        });
      }
    });
  }

  issueSupplementaryNir(item) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Issue supplementary NIR`,
        message: `Are you sure you would like to issue supplementary NIR to registrant: ${item.registeredName} ?`
      }
    });
    dialogRef.afterClosed().subscribe(confirmed => {

      if (confirmed) {
        this.issuingSupplementaryNir = item;
        this.service.issueSupplementaryNoir(item.revalidationSubmissionId)
          .subscribe(res => {
            this.issuingSupplementaryNir = false;
            item.noIRSupplementaryIssuedAt = new Date().toJSON();
          });
      }
    });
  }

  issueNorLetter(item) {
    const dialogRef = this.dialog.open(ConfirmDateDialogComponent, {
      disableClose: true,
      data: {
        header: `Set NOR date`,
        message: `Please choose a date to send this registrant a NOR letter: ${item.registeredName}`,
        warning: 'Are you sure you would like to send this registrant a letter of NOR ? If you are super sure, press save'
      }
    });
    dialogRef.afterClosed().subscribe(selectedDate => {
      if (selectedDate) {

        this.issuingNorLetter = item;

        // send chosen date to backend.
        this.service.issueNorLetter(item.revalidationSubmissionId, selectedDate)
          .subscribe(allgood => {
            this.issuingNorLetter = false;
            // display chosen date on ui
            item.noRLetterIssuedDate = selectedDate;
            item.noRStage = this.RemediationStage.NoRIssued;
          }, (error) => {
            this.log.flag(this.log.error(error));
          });
      }
    });
  }

  clearSearch() {
    this.regNumber = '';
    this.searchCriteria.date = null;
    this.load();
  }

  search(regNumber) {
    regNumber = regNumber.trim();
    const skip = this.pageNum * this.pageSize;
    const take = this.pageSize;

    switch (this.revalidationStatus) {
      case RevalidationStatus.NIR:
        this.service.searchRegistrationNumberNoir(this.submissionDeadline, regNumber, skip, take).subscribe(data => {
          this.totalCount = data.totalCount;
          data = data.records.map(item => {
            item.remediationStage = item.NOiRStage;
            return item;
          });
          this.items = data;
          this.moving = false;
          this.loading = false;
        });
        break;
      case RevalidationStatus.NOR:
        this.service.searchRegistrationNumberNor(this.submissionDeadline, regNumber, skip, take).subscribe(data => {
          this.totalCount = data.totalCount;
          data = data.records.map(item => {
            item.remediationStage = item.noRStage;
            return item;
          });
          this.items = data;
          this.moving = false;
          this.loading = false;
        });
        break;
      default:
        this.service.searchRegistrationNumberRemediation(this.submissionDeadline, regNumber, skip, take).subscribe(data => {
          this.items = data.records;
          this.totalCount = data.totalCount;
          this.moving = false;
          this.loading = false;
        });
    }
  }


  filterManuallyEdited() {
    this.manuallyEdited = !this.manuallyEdited;
    if (!this.manuallyEdited) {
      this.load();
      return false;
    }
    this.loading = true;
    const skip = this.pageNum * this.pageSize;
    const take = this.pageSize;

    switch (this.revalidationStatus) {
      case RevalidationStatus.NIR:
        this.service.filterByManuallyEditedNir(this.submissionDeadline, skip, take).subscribe(data => {
          this.items = this.sortInDateOrder(data);
          this.totalCount = data.totalCount;
          this.loading = false;
        }, error => {
          // console.error(error);
          this.loading = false;
        });
        break;
      case RevalidationStatus.NOR:
        this.service.filterByManuallyEditedNor(this.submissionDeadline, skip, take).subscribe(data => {
          this.items = this.sortInDateOrder(data);
          this.totalCount = data.totalCount;
          this.loading = false;
        }, error => {
          console.error(error);
          this.loading = false;
        });
        break;
      default:
        this.service.filterByManuallyEditedRemediation(this.submissionDeadline, skip, take).subscribe(data => {
          this.items = this.sortInDateOrder(data);
          this.totalCount = data.totalCount;
          this.loading = false;
        }, error => {
          console.error(error);
          this.loading = false;
        });
    }
  }

  filterNorIssued() {
    this.norIssued = !this.norIssued;
    if (!this.norIssued) {
      this.load();
      return false;
    }
    this.loading = true;
    const skip = this.pageNum * this.pageSize;
    const take = this.pageSize;
    this.service.filterByNorIssued(this.submissionDeadline, skip, take).subscribe(data => {
      this.items = this.sortInDateOrder(data);
      this.totalCount = data.totalCount;
      this.loading = false;
    }, error => {
      console.error(error);
      this.loading = false;
    });
  }

  filterBySupplimentaryNirIssued() {
    this.loading = true;
    const skip = this.pageNum * this.pageSize;
    const take = this.pageSize;
    this.hasIssuedSupplimentaryNir = !this.hasIssuedSupplimentaryNir;

    if (!this.hasIssuedSupplimentaryNir) {
      this.load();
      return false;
    }

    this.service.filterByNOIRIssued(this.submissionDeadline, skip, take, true).subscribe(data => {
      this.items = this.sortInDateOrder(data);
      this.totalCount = data.totalCount;
      this.loading = false;
    }, error => {
      console.log(error);
      this.loading = false;
    });
  }

  filterBySupplimentaryNirNotIssued() {
    this.loading = true;
    const skip = this.pageNum * this.pageSize;
    const take = this.pageSize;
    this.suppNirNotIssued = !this.suppNirNotIssued;

    if (!this.suppNirNotIssued) {
      this.load();
      return false;
    }


    this.service.filterByNOIRIssued(this.submissionDeadline, skip, take, false).subscribe(data => {
      this.items = this.sortInDateOrder(data);
      this.totalCount = data.totalCount;
      this.loading = false;
    }, error => {
      console.log(error);
      this.loading = false;
    });
  }


  filterByNirIssuedDate(usersDate) {
    if (!usersDate) {
      return false;
    }
    this.loading = false;
    const skip = this.pageNum * this.pageSize;
    const take = this.pageSize;
    this.service.filterByNOIRIssuedDate(this.submissionDeadline, skip, take, usersDate).subscribe(data => {
      this.items = this.sortInDateOrder(data);
      this.totalCount = data.totalCount;
      this.loading = false;
    }, error => {
      console.log(error);
      this.loading = false;
    });
  }

  sortInDateOrder(data) {
    return data.records.sort((a, b) => {
      return new Date(a.submittedAt) > new Date(b.submittedAt) ? -1 : 1;
    });
  }




}
