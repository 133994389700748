import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ProgressIndicatorComponent } from './progressIndicator.component';
import { RoundProgressModule } from './round-progress/round-progress.module';
import { KeysPipe } from './pipe/Keys.pipe';
import { ReversePipe } from './pipe/Reverse.pipe';
import { CaseSplitPipe } from './pipe/CaseSplit.pipe';
import { FriendlyBooleanPipe } from './pipe/FriendlyBoolean.pipe';
import { UtcDatePipe } from './pipe/UtcDate.pipe';
import { TimePipe } from './pipe/Time.pipe';
import { WizardStepsComponent } from './wizardSteps.component';
import { StickyComponent } from './sticky.component';
import { FormLayoutComponent } from './formLayout.component';
import { FormSectionComponent } from './formSection.component';
import { FormReviewComponent } from './formReview.component';
import { BannerComponent } from './banner.component';
import { GphcIconComponent } from './gphc-icon.component';
import { TextEditorComponent } from './textEditor.component';
import { SpinnerComponent } from './spinner.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { ConfirmDialogComponent } from './confirmDialog.component';
import { FileUploadComponent } from './fileUpload.component';
import { CollapsibleComponent } from './collapsible.component';
import { CarouselComponent } from './carousel.component';
import { CarouselNavComponent } from './carousel-nav.component';
import { ToolbarComponent } from './toolbar.component';
import { CustomErrorHandler } from '../core/service/CustomErrorHandler';
import { TooltipModule } from '../core/tooltip/tooltip.module';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AutocompleteComponent } from './autocomplete.component';
import { UtcDatePickerComponent } from './utcDatePicker.component';

import { SearchPipe } from '../shared/pipe/search.pipe';
import { PagerComponent } from './pager.component';
import { ExtCircSearchPipe } from './pipe/ext-circ-search.pipe';
import { DaterangeComponent } from './daterange.component';
import { UserActivityReportsPipe } from './pipe/UserActivityReportsPipe';
import { RevalSubmissionQueryPipe } from './pipe/RevalidationSubmissionQueries';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { TableFilterPipe } from './pipe/TableFilter.pipe';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        RoundProgressModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatMenuModule,
        MatSelectModule,
        TooltipModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatSliderModule
    ],
    declarations: [
        ProgressIndicatorComponent,
        WizardStepsComponent,
        KeysPipe,
        CaseSplitPipe,
        UtcDatePipe,
        ReversePipe,
        FriendlyBooleanPipe,
        TimePipe,
        StickyComponent,
        FormLayoutComponent,
        FormReviewComponent,
        FormSectionComponent,
        BannerComponent,
        GphcIconComponent,
        TextEditorComponent,
        SpinnerComponent,
        ConfirmDialogComponent,
        FileUploadComponent,
        CollapsibleComponent,
        CarouselComponent,
        CarouselNavComponent,
        ToolbarComponent,
        AutocompleteComponent,
        UtcDatePickerComponent,
        SearchPipe,
        PagerComponent,
        ExtCircSearchPipe,
        DaterangeComponent,
        UserActivityReportsPipe,
        RevalSubmissionQueryPipe,
        TableFilterPipe
    ],
    exports: [
        ProgressIndicatorComponent,
        WizardStepsComponent,
        KeysPipe,
        CaseSplitPipe,
        ReversePipe,
        UtcDatePipe,
        SearchPipe,
        ExtCircSearchPipe,
        FriendlyBooleanPipe,
        TimePipe,
        StickyComponent,
        FormLayoutComponent,
        FormReviewComponent,
        FormSectionComponent,
        BannerComponent,
        GphcIconComponent,
        TextEditorComponent,
        SpinnerComponent,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatSliderModule,
        ConfirmDialogComponent,
        FileUploadComponent,
        CollapsibleComponent,
        CarouselComponent,
        CarouselNavComponent,
        ToolbarComponent,
        MatDialogModule,
        MatCheckboxModule,
        MatRadioModule,
        MatProgressBarModule,
        MatTabsModule,
        MatAutocompleteModule,
        AutocompleteComponent,
        FormsModule,
        ReactiveFormsModule,
        UtcDatePickerComponent,
        PagerComponent,
        DaterangeComponent,
        UserActivityReportsPipe,
        RevalSubmissionQueryPipe,
        TableFilterPipe
    ],
    providers: [
        { provide: ErrorHandler, useClass: CustomErrorHandler },
        DatePipe,
        CaseSplitPipe,
        FriendlyBooleanPipe
    ]
})
export class SharedModule { }
