import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { LogService } from '../core/service/log.service';
import { ConfirmDialogComponent } from '../shared/confirmDialog.component';
import { VRApplication } from './model/VRApplication';
import { PreregApplicationService } from './service/prereg.service';

@Component({
  selector: 'app-voluntary-removal-application',
  templateUrl: './voluntaryRemovalApplication.component.html',
  styleUrls: ['./application.scss']
})
export class VoluntaryRemovalComponent implements OnInit, OnDestroy {

  @Input()application: VRApplication;
  name: string;
  stepTitle: string;
  isUserSI: false;
  voluntaryRemovalDetails;
  appDeclaration;
  FtPAnswers: {
    Yes?: number,
    No?: number
  } = {};
  FtPSubAnswers: {
    Yes?: number,
    No?: number
  } = {};
  index = 0;
  @Input() event: Observable<string>;
  @Output() applicationDeleted = new EventEmitter<boolean>();

  private eventSubscription: Subscription;

  constructor(private dialog: MatDialog,
    private log: LogService,
    private preregService: PreregApplicationService) { }

  ngOnInit(): void {

    this.eventSubscription = this.event.subscribe(( userID) => this.deleteApplication(userID));

    this.name = this.application?.applicantDetails ? (this.application.applicantDetails.forenames + ' ' + this.application.applicantDetails.surname) :
                    '';
    this.voluntaryRemovalDetails = this.application?.form?.voluntaryRemovalDetails ? this.application.form.voluntaryRemovalDetails : null;    
    this.appDeclaration = this.application?.form?.appDeclaration ? this.application.form.appDeclaration : null;
    this.stepTitle = this.isUserSI ? 'Date removal required, Superintendent (SI) & Reason for removal': 
                        'Date removal required & Reason for removal';
    if(this.application.ftpQuestions) {

      for(let question of this.application.ftpQuestions) {          
        if(question.body !== null) {
          if(!this.FtPAnswers[question.answer]) {
            this.FtPAnswers[question.answer] = 1;
          } else {
            this.FtPAnswers[question.answer]++;
          }
        } else {      
          if(!this.FtPSubAnswers[question.answer]) {
            this.FtPSubAnswers[question.answer] = 1;
          } else {
            this.FtPSubAnswers[question.answer]++;
          }
        }
      }
      
    }    
  }

  getObjectLength(obj) {
    return Object.keys(obj).length;
  }

  deleteApplication(userId) {

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: `Delete confirmation`,
        message: `Are you sure you want to delete the partially filled VR application?`,
      }
    });
    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        const message = 'VR application belonging to applicant with registration number ' + userId + ' is deleted successfully';
        this.preregService.deleteVRApplication(userId).subscribe(data => {
          this.applicationDeleted.emit(true);
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            disableClose: true,
            data: {
              allowCancel: false,
              confirmText: `ok`,
              title: `Application deleted`,
              message: message,
            }
          });
        }, error => {
          this.applicationDeleted.emit(false);
          this.log.error('deleting VR application failed', error);
        });
      }
    });
  }

  ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }
}