
import { fromEvent as observableFromEvent, Observable } from 'rxjs';

import { map, filter, debounceTime, mergeMap } from 'rxjs/operators';
import { Component, NgZone, OnInit, OnDestroy, HostBinding, HostListener, ErrorHandler } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LayoutState } from './core/model/LayoutState';
import { LoggedInState } from './core/model/LoggedInState';
import { LayoutService } from './core/service/layout.service';
import { TooltipService } from './core/tooltip/tooltip.service';
import { AuthService } from './core/service/auth.service';
import { LogService } from './core/service/log.service';



import { environment } from '../environments/environment';
import { UpdaterService } from './core/service/updater.service';
import { CustomErrorHandler } from './core/service/CustomErrorHandler';
import { BannerState } from './core/model/BannerState';
import { UserService } from './core/service/user.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./style/app.scss'],
  providers: [
    { provide: ErrorHandler, useClass: CustomErrorHandler }
  ]
})
export class AppComponent implements OnInit {

  showLoggedInState = false;
  LoggedInState = LoggedInState;
  initialising = false;
  checkLoggedInStateFailed = false;

  @HostBinding('style.overflow-y') overflowy = 'auto';
  @HostBinding('style.overflow-x') get overflowx() {
    return this.layout.state.fullscreen ? 'hidden' : 'auto';
  }

  constructor(public log: LogService,
    private authService: AuthService,
    public layout: LayoutService,
    public updater: UpdaterService,
    private router: Router,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private tooltip: TooltipService,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef
  ) { }

  reload() {
    window.location.reload();
  }


  ngOnInit() {

    observableFromEvent(window, 'resize').pipe(
      debounceTime(500)
    )
      .subscribe(this.setSize.bind(this));

    this.setSize();

    this.updater.startChecking();

    this.log.onNewError.subscribe((log) => {
      if (log && log['flag']) {
        window.scroll(0, 0);
      }

    });

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe(route => {
        let title = 'myGPhC';
        if (route.title) {
          title += ' - ' + route.title;
        }
        this.titleService.setTitle(title);
        this.layout.reset(route.fullscreen);
        this.log.clearRouteBlock();
        this.tooltip.close();

        // if (this.loggedIn && !this.authService.user.admin) {
        //   this.userService.getUser().subscribe(admin => {
        //     Object.assign(this.authService.user, { admin });
        //   });
        // }

      });
    /*
        this.router.events
        .filter((event) => event instanceof NavigationEnd)
        .subscribe((event) => {
          this.layout.reset();
          this.log.clearRouteBlock();
          this.tooltip.close();
        });
    */
  }
  ngAfterContentChecked() {
    this.cdRef.detectChanges();
    this.authService.init();

  }

  setSize() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    this.layout.setSize(width, height);
  }

  get loggedIn(): boolean {
    return this.authService.loggedIn();
  }

  logout() {
    this.authService.logout();
  }
}
