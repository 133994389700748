export enum AttachmentType {
    QualificationCertificate = 0,
    ProofOfIdentity = 1,
    BirthCertificate = 2,
    Photo = 3,
    Other = 4,
    SomethingToDeclareForm = 5,
    FinalDeclarationForm = 6,
    KnowledgeBasedQualification = 7,
    CompetencyBasedQualification = 8,
    OverseasQualification = 9,
    AcademicTranscript = 10,
    OverseasEligibility = 11,
    IndyPrescriberMentorConfirmation = 12,
    CombinedQualification = 13,
    OfficialDocummentConfirmingNameChange = 14,
    IELTSCertificate = 15,
    EmployerReferenceForEnglishCertificate = 16,
    PharmcistCVForProfessionalStanding = 17
  }
  