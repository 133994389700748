
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { LogService } from './log.service';
import * as StackTrace from 'stacktrace-js';

import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private log: LogService, private locationStrategy: LocationStrategy) { }

  handleServerError(errorResponse: HttpErrorResponse, caught: Observable<any>): Observable<any> {
    if ((<any>errorResponse).serverError) { return observableThrowError(errorResponse); } // already handled
    let log;
    let flag = true;
    let validationErrors = [];
    const message = errorResponse.message ? errorResponse.message : errorResponse.toString();
    const output: any = {};
    if (errorResponse.status === 0 || message.indexOf('Uncaught (in promise): 0') > -1) {
      this.log.isOffline = true;
    } else if (errorResponse.status === 500) {
      const error = errorResponse.error;
      output.status = 500;
      log = this.log.error(error, errorResponse);
    } else if (errorResponse.status === 404) {
      flag = false;
      output.status = 404;
    } else if (errorResponse.status === 400) {  // Bad request = handle validation error
      const modelState = errorResponse.error.modelState;
      if (!modelState) {
        if (errorResponse.error.message) {
          output.message = errorResponse.error.message;
          log = this.log.error(errorResponse.error.message, errorResponse);
          flag = false;
        } else {
          log = this.log.error('Server responded with \'Bad request\' but no error messages were returned', errorResponse);
        }
      } else {
        for (const fieldName in modelState) {
          if (modelState.hasOwnProperty(fieldName)) {
            validationErrors = validationErrors.concat(modelState[fieldName]);
          }
        }
        validationErrors = validationErrors.filter(e => e !== null && e !== '' && e !== undefined);
        output.validationErrors = validationErrors;
        output.status = 400;
        log = this.log.error('Server validation error', validationErrors);
        flag = false;
      }
    } else {
      output.status = errorResponse.status;
      output.statusText = errorResponse.statusText;
      output.message = errorResponse.message;
      log = this.log.error(errorResponse.message ||
        errorResponse.statusText || errorResponse.error || errorResponse.statusText, errorResponse);
    }

    if (flag && log) {
      this.log.flag(log);
    }

    output.serverError = true;
    return observableThrowError(output);
  }

  handleError(error) {
    if (error.serverError) { return; }
    let log;
    const message = error.message ? error.message : error.toString();

    if (message.indexOf('SyntaxError: Unexpected token < in JSON at position 0') > -1) {
      log = this.log.error(`Unable parse response as JSON (maybe the api url is wrong?)`, error);
      this.log.flag(log);
      return;
    }
    if (message.indexOf('Uncaught (in promise): 0') > -1) {
      this.log.offline(true);
      return;
    }

    if (error.stack) {
       log = this.log.error(error.message, error);
       this.log.flag(log);
       throw(error);
    }

    const page = this.locationStrategy instanceof PathLocationStrategy
      ? this.locationStrategy.path() : 'unknown url';

    try {
      StackTrace.fromError(error).then(stackframes => {
        const stacktrace = {
          page,
          trace: stackframes.filter(sf => sf.fileName.indexOf('src/app') > -1)
        };
        log = this.log.error(message, stacktrace);
      });
    } catch (e) {
      log = this.log.error('Error getting stack trace', e, message);
    }

    this.log.flag(log);
  }


  flag(log, message) {
    const noflag = ['user_cancelled', 'interaction_required'];
    let flag = true;
    noflag.forEach(item => {
      if (message.indexOf(item) > -1) {
        flag = false;
      }
    });
    if (flag) {
      this.log.flag(log);
    }
  }
}
