import { Address } from "./Address";
import { Contact } from "./Contact";
import { PortalStatus } from "./PortalStatus";
import { Registrant } from "./Registrant";
import { SsoStatus } from "./SsoStatus";
import { SsoUser } from "./SsoUser";

export class PreregUsers {
  title: string;
  forenames: string;
  surname: string;
  dateOfBirth: string;
  personId: string;
  contact: Contact;
  address: Address;
  ssoStatus: SsoStatus;
  portalStatus: PortalStatus;
  checkingSsoStatus = false;
  checkingPortalStatus = false;
  ssoUser: SsoUser;
  isUpdateAllowed :boolean;
  PreEntry: {
    PreEntryNumber : string;
  };
  
  constructor(user) {

    Object.assign(this, user);

  }

}
