export const MockNotifications = [
    {
        regNumber: 4191351,
        name: 'Marius Julius Caesar',
        dateOfNotification: '11/11/12',
        notificationType: 'ExtenuatingCircumstancesSent'
    },
    {
        regNumber: 4191351,
        name: 'Marius Julius Caesar',
        dateOfNotification: '12/12/12',
        notificationType: 'EmailChangeRequestNotification'
    },
    {
        regNumber: 4191351,
        name: 'Marius Julius Caesar',
        dateOfNotification: '13/12/12',
        notificationType: 'RegistrationFormCountersignatureRejected'
    },
    {
        regNumber: 4191351,
        name: 'Marius Julius Caesar',
        dateOfNotification: '14/12/12',
        notificationType: 'RegistryEntryNotification'
    },
    {
        regNumber: 4191351,
        name: 'Marius Julius Caesar',
        dateOfNotification: '06/01/18',
        notificationType: 'RegistrationFormFirstYearFeeSuccessful'
    },
    {
        regNumber: 4191351,
        name: 'Marius Julius Caesar',
        dateOfNotification: '24/10/12',
        notificationType: 'ExtenuatingCircumstancesRejected'
    },
    {
        regNumber: 4191351,
        name: 'Marius Julius Caesar',
        dateOfNotification: '25/01/17',
        notificationType: 'ExtenuatingCircumstancesApproved'
    },
];
