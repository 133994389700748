// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unregisteredPremiseName input.readonly {
  cursor: not-allowed !important;
}

.results {
  max-height: 200px;
  overflow-y: auto;
}

tr.ineligible {
  background: #f5f5f5;
  color: #b7b7b7;
}`, "",{"version":3,"sources":["webpack://./src/app/prereg/workExperience/selectPremises.component.scss"],"names":[],"mappings":"AACI;EACI,8BAAA;AAAR;;AAIA;EACI,iBAAA;EACA,gBAAA;AADJ;;AAIA;EACI,mBAAA;EACA,cAAA;AADJ","sourcesContent":[".unregisteredPremiseName {\r\n    input.readonly {\r\n        cursor: not-allowed !important ;\r\n    }\r\n}\r\n\r\n.results { \r\n    max-height: 200px; \r\n    overflow-y: auto\r\n}\r\n\r\ntr.ineligible { \r\n    background: #f5f5f5; \r\n    color: #b7b7b7; \r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
