import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Address } from '../accounts/model/Address';
import { RegistrantStatus } from '../accounts/model/RegistrantStatus';
import { LogService } from '../core/service/log.service';
import { PreregApplicationService } from './service/prereg.service';
import { ApplicationStatus, CCPSDetails, RegistrationRoute, EEACountries, eeaDirectiveRoute, LegacyRegistrationRoute } from './model/CCPSApplicationDetails';
import { RegistrantType } from '../accounts/model/RegistrantType';

@Component({
  selector: 'app-ccps-application',
  templateUrl: './CCPSApplication.component.html',
  styleUrls: ['./CCPSApplication.component.scss']
})
export class CCPSComponent implements OnInit {

  @Input()application:CCPSDetails; 
  englishCertificateOption: string;
  registrationStatus;
  Q10;
  Q11;
  q8Title="Question 8"
  q9Title="Question 9"
  registration: any;
  registrationNumber: any;
  Q10Address: string;
  Q11Address: string;  
  requestedTittle:string;
  requestedFirstName:string;
  requestedMiddleName:string;
  requestedLastName:string;
  forms = [];
  applications = []
  expandedIndex = 0;

  professionalStandingDetail;
  initQualDetail;
  selectedApplication;
  registrationRoute;
  UKdetails;
  EEADetails;
  NIDetails;
  PTDetails;
  OSPAPDetails;
  qualDetails;
  routeValue;
  userRoute;

  eeaDirectiveRoute = eeaDirectiveRoute;
  eeaCountries = EEACountries;
  ftpQuestonsObj = {
    ftpQuestions: []
  }

  confirmNameChange = '-';
  regulatoryBody;

  constructor(private dialog: MatDialog,
    private log: LogService,
    private preregService: PreregApplicationService) { }

    get fullName(){
      let first,middle,last;
      first= this.application.applicantDetails?.forenames ? this.application.applicantDetails.forenames:'';
      middle = this.application.applicantDetails?.middleName ? this.application.applicantDetails.middleName : '';
      last= this.application.applicantDetails?.surname ? this.application.applicantDetails.surname:'';
      if(first=="" && last=="" ){
        return "-";
      }
      return first +" "+middle +" "+last;
     }

     get applicantNumber() {
      return this.application.applicantDetails.registrationNumber;
     }
   
     getApplicationStatus(status) {
      return ApplicationStatus[status];
     }

  ngOnInit(): void {    
    
    this.forms = this.application.forms;
    this.applications = this.application.forms;
    this.loadForms();
    this.userRoute = this.getRegistrationRoute(this.application.forms[0]?.form?.initialRegistrantQualificationDetail?.registrationRoute);
    if(this.userRoute === 'NorthernIreland') this.userRoute = this.userRoute.replace(/([A-Z])/g, ' $1')
  }

  loadForms(){
    this.forms = [];
    this.application.forms.forEach( form => {
      this.forms.push({
        regBodyName : form.regulatoryBody?.name,
        status : form.form.formStatus,
        id: form.form.id
      })
    });
  }

  onFormIdSelect(formId){
    this.loadForm(formId);
  }

  loadForm(formId) {
    let index = this.application.forms.findIndex( form => form.form.id === formId );
    if(index>=0) {
      this.selectedApplication = this.application.forms[index];
      this.professionalStandingDetail = this.selectedApplication.form.professionalStandingDetail;
      this.confirmNameChange = (this.professionalStandingDetail.continueExistingName === null || this.professionalStandingDetail.continueExistingName === undefined) ? '-' : (this.professionalStandingDetail.continueExistingName ? 'Yes' : 'No');
      this.regulatoryBody = this.selectedApplication.regulatoryBody;
      this.initQualDetail = this.selectedApplication.form.initialRegistrantQualificationDetail;
      this.ftpQuestonsObj.ftpQuestions = this.selectedApplication.ftpQuestions;
      this.registrationStatus = this.application.applicantDetails.registrationStatus;
      if(this.selectedApplication.form.attachments && this.selectedApplication.form.attachments.length > 0) {
        this.selectedApplication.form.attachments.forEach((doc:any)=>{
          doc.url=doc.downloadUrl; 
        });
      }
      this.registrationRoute = this.selectedApplication?.form?.initialRegistrantQualificationDetail?.registrationRoute;

      if(this.isUserPharmacist) {
        this.routeValue = this.getRegistrationRoute(this.selectedApplication?.form?.initialRegistrantQualificationDetail?.registrationRoute);
        this.setQualificationDetails(this.routeValue);

      } else {
        if(this.registrationRoute === RegistrationRoute.EEA) this.routeValue = 'EEA';
        if(this.routeValue === 'EEA') {
          this.PTDetails = {
            countryQualified: this.initQualDetail.eeaPharmacistQualificationDetail?.countryQualified || '-',
            courseName: this.initQualDetail.eeaPharmacistQualificationDetail?.courseName || '-',
            datePassed: this.initQualDetail.eeaPharmacistQualificationDetail?.datePassed || '-',
            nameOfUniversity: this.initQualDetail.eeaPharmacistQualificationDetail?.nameOfUniversity || '-',
          }
        } else {
          this.PTDetails = {
            degreeName1 : this.initQualDetail?.pharmacyTechnicianQualificationDetail?.degreeName1 || '-',
            qualifiedDate1 : this.initQualDetail?.pharmacyTechnicianQualificationDetail?.qualifiedDate1,
            degreeName2 : this.initQualDetail?.pharmacyTechnicianQualificationDetail?.degreeName2 || '-',
            qualifiedDate2 : this.initQualDetail?.pharmacyTechnicianQualificationDetail?.qualifiedDate2
          }
        }
      }
    }
  }

  getRegistrationRoute(registrationRoute) {
    
        let countryQualified = this.initQualDetail?.ospapPharmacistQualificationDetail?.ospapCountryQualified;
        let routeValue;

        if(registrationRoute === RegistrationRoute.UK || registrationRoute === RegistrationRoute.UKPlusOverseas) routeValue = 'UK';
        if(registrationRoute === RegistrationRoute.EEA) routeValue = 'EEA';
        if(registrationRoute === RegistrationRoute.NorthernIreland) routeValue = 'NorthernIreland';

        if((registrationRoute === RegistrationRoute.OSPAP && !(countryQualified === 'Australia' || countryQualified === 'New Zealand' || countryQualified === 'South Africa')) || 
        (registrationRoute === RegistrationRoute.Legacy && this.application.applicantDetails?.legacyRegistrationRoute === LegacyRegistrationRoute.Adjudicating)) {
          routeValue = 'OSPAP';
        }

        if ((registrationRoute === RegistrationRoute.Legacy && this.application.applicantDetails?.legacyRegistrationRoute === LegacyRegistrationRoute.Reciprocity) ||
        (registrationRoute === RegistrationRoute.OSPAP && (countryQualified === 'Australia' || countryQualified === 'New Zealand' || countryQualified === 'South Africa'))) {
          routeValue = 'Reciprocity';
        }

        return routeValue;
  }

  setQualificationDetails(route) {
    switch(route) {
      case 'UK':
        {
          this.UKdetails = {
            courseName: this.initQualDetail.ukPharmacistQualificationDetail?.courseName || '-',
            assessmentYear: this.initQualDetail.ukPharmacistQualificationDetail?.assessmentYear || '-',
            yearQualified: this.initQualDetail.ukPharmacistQualificationDetail?.yearQualified || '-'
          }
        } 
        break;
        case 'EEA': 
        {
          this.EEADetails = {
            countryQualified: this.initQualDetail.eeaPharmacistQualificationDetail?.countryQualified || '-',
            courseId: this.initQualDetail.eeaPharmacistQualificationDetail?.courseId || '-',
            courseName: this.initQualDetail.eeaPharmacistQualificationDetail?.courseName || '-',
            dateStarted: this.initQualDetail.eeaPharmacistQualificationDetail?.dateStarted || '-',
            datePassed: this.initQualDetail.eeaPharmacistQualificationDetail?.datePassed || '-',
            eeaDirectiveRoute: this.eeaDirectiveRoute[this.initQualDetail.eeaPharmacistQualificationDetail?.eeaDirectiveRoute] || '-',
            educationTrainingDetails: this.initQualDetail.eeaPharmacistQualificationDetail?.educationTrainingDetails || '-',
            countryFirstRecognized: this.initQualDetail.eeaPharmacistQualificationDetail?.countryFirstRecognized || '-',
            nameOfUniversity: this.initQualDetail.eeaPharmacistQualificationDetail?.nameOfUniversity || '-'
          }
        }
        break;
        case 'NorthernIreland':
          {
            this.NIDetails = {
              courseId: this.initQualDetail?.irelandPharmacistQualificationDetail?.courseId || '-',
              courseName : this.initQualDetail?.irelandPharmacistQualificationDetail?.courseName || '-',
              yearObtained: this.initQualDetail?.irelandPharmacistQualificationDetail?.yearObtained || '-'
            }
          }
        break;
        case 'OSPAP' : {
          this.setQualDetails();
        }
        break;
        case 'Reciprocity' : {
          this.setQualDetails();
        }
        break;
    }
  }

  setQualDetails(){
    this.OSPAPDetails = {
      ospapCountryQualified: this.initQualDetail?.ospapPharmacistQualificationDetail?.ospapCountryQualified || '-',
      ospapCourseId: this.initQualDetail?.ospapPharmacistQualificationDetail?.ospapCourseId || '-',
      ospapCourseName: this.initQualDetail?.ospapPharmacistQualificationDetail?.ospapCourseName || '-',
      degreeName: this.initQualDetail?.ospapPharmacistQualificationDetail?.degreeName || '-',
      universityName: this.initQualDetail?.ospapPharmacistQualificationDetail?.universityName || '-',
      yearObtained: this.initQualDetail?.ospapPharmacistQualificationDetail?.yearObtained || '-',
      ospapDateQualified: this.initQualDetail?.ospapPharmacistQualificationDetail?.ospapDateQualified || '-',
      assessmentYear: this.initQualDetail?.ospapPharmacistQualificationDetail?.assessmentYear || '-'
    }
  }

  get isUserPharmacist() {
    return this.application.applicantDetails?.registrantType === RegistrantType.Pharmacist;
  }

  isCVUploadRequired(): boolean {
    return this.eeaDirectiveRoute[this.isEEARegulatorSelected && this.initQualDetail?.eeaPharmacistQualificationDetail?.eeaDirectiveRoute] === 'Article23' ||
    this.eeaDirectiveRoute[this.initQualDetail?.eeaPharmacistQualificationDetail?.eeaDirectiveRoute] === 'GS';
  }

  get isEEARegulatorSelected() {
    const EEARegulatorSelected = this.eeaCountries.includes(this.selectedApplication?.form?.professionalStandingDetail.country);
    return EEARegulatorSelected;
  }

  get isRegistered() {
    return this.registrationStatus === RegistrantStatus.Registered;
  }

  get isSuspended() {
    return this.registrationStatus===RegistrantStatus.Suspended;
  }

  get isNotRegisteredOrSuspended() {
    return (this.registrationStatus === RegistrantStatus.Erased || 
    this.registrationStatus === RegistrantStatus.RemovedFailedToRenew ||
    this.registrationStatus === RegistrantStatus.RemovedVoluntary || 
    this.registrationStatus === RegistrantStatus.RemovedCPDNonCompliance ||
    this.registrationStatus === RegistrantStatus.RemovedRevalNonCompliance);
  }

  getObjectLength(obj) {
    return Object.keys(obj).length;
  }
  private concat(item: string) {
    return item ? item + ', ' : '';
  }
  
  toString(address) {
    return this.concat(address.line1) +
    this.concat(address.line2) +
    this.concat(address.line3) +
    this.concat(address.town) +
    this.concat(address.county) +
    address.country + ' ' +
    address.postcode;

  }
}