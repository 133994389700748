import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Address } from './model/Address';

@Component({
  selector: 'app-address-edit',
  templateUrl: './addressEdit.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressEditComponent),
      multi: true
    }
  ]
})
export class AddressEditComponent {
  address: Address;
  @Input() manualInput = false;
  editing = false;
  lineOneFocussed = false;
  @Input() touched = false;
  reselect() {
    this.address = undefined;
    this.propagateChange(this.address);
    this.manualInput = false;
    this.editing = true;
    this.lineOneFocussed = false;
  }

  enableManualInput() {
    this.manualInput = true;
    this.lineOneFocussed = true;
  }

  writeValue(value: any) {
    if (value) {
      this.address = value;
    }
  }
  propagateChange = (model: any) => {};
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() {}

  onSelect(address) {
    this.address = address;
    this.lineOneFocussed = true;
    this.propagateChange(address);
  }

}
