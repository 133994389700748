import { Component, Input, OnInit } from '@angular/core';
import { PreregApplicationService } from './service/prereg.service';

@Component({
    selector: 'app-application-details',
    templateUrl: './applicationDetails.component.html'
})
export class ApplicationDetailsComponent implements OnInit {
    @Input() application;
    trainingSites = [];

    constructor(
        private service: PreregApplicationService
    ) { }

    ngOnInit() {
        const hasPlacements = !!this.application.placements;
        if (hasPlacements) {
            const hasMoreThanOnePlacement = this.application.placements.length > 1;
            const placements = this.application.placements;
            (hasMoreThanOnePlacement) ? this.sortPlacements(placements) : this.trainingSites.push(placements[0].trainingSite);
        }
    }

    sortPlacements(placements) {
        placements.sort((placement1, placement2) => {
            return new Date(placement1.endDate).getTime() - new Date(placement2.endDate).getTime();
        }).forEach(placement => {
            this.trainingSites.push(placement.trainingSite);
        });
    }


}

