import { Injectable, EventEmitter } from '@angular/core';
import { Log } from '../model/Log';
import { LogLevel } from '../model/LogLevel';

@Injectable()
export class LogService {


  public onNewError = new EventEmitter<Log>();

  logs: Array<Log> = [];
  newError: Log;
  newRouteBlock: Log;
  isOffline = false;
  notifyIsOffline = false;

  offline(isOffline) {
    this.notifyIsOffline = isOffline;
    this.isOffline = isOffline;
  }

  clear() {
    this.logs = [];
  }
  clearNew() {
    this.newError = undefined;
    // this.isOffline = false;
  }
  clearRouteBlock() {
    this.newRouteBlock = undefined;
  }

  private add(text: string, level: LogLevel, data: any) {
    const log: Log = { text, level, data, date: new Date() };
    this.logs.push(log);
    this.logs = this.logs.slice(this.logs.length - 100, this.logs.length);
    return log;
  }

  info(text: string, ...data: any[]) {
    this.add(text, LogLevel.info, data);
  }

  routeBlock(message: string, path: string) {
    const log = this.error(message);
    log.routeBlock = true;
    log.routeBlocked = path;
    this.newRouteBlock = log;
  }

  error(text: string, ...data: any[]) {
     const log = this.add(text, LogLevel.error, data);
     if (data && data.length && data[0].exceptionMessage) {
      log.exception = data[0];
     }
     return log;
  }

  flag(log) {
    this.newError = log;
    this.onNewError.emit(this.newError);
  }

  warn(text: string, ...data: any[]) {
     this.add(text, LogLevel.warn, data);
  }

  critical(text: string, ...data: any[]) {
     this.add(text, LogLevel.critical, data);
  }

}
