import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from '../core/service/auth.service';

@Injectable()
export class LoggedInOnlyGuard implements CanActivate {
  constructor(private auth: AuthService,
  private router: Router) {}

  canActivate() {
    if (this.auth.loggedIn()) {
      return true;
    } else {
      this.router.navigate(['/signin']);
      return false;
    }
  }
}
