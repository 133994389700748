import { CrmSubmissionStatus } from "../../queries/model/crmSubmissionStatus";
import { Address } from "../../../app/accounts/model/Address";
import { AnswerType } from "../../../app/dynamic/model/AnswerType";
import { CrmRemediationStatus } from "../../queries/model/crmRemediationStatus";
import { CrmNoirAndNorStatus } from "../../queries/model/crmNoirAndNorStatus";
import { RevalidationStatus } from "../../revalidation/model/RevalidationStatus";
import { CrmReviewAndFeedbackStatus } from "../../revalidation/model/CrmReviewAndFeebackStatus";

export interface ReturnToRegisterDetails {
  form: {
    isOverallDeclarationAcknowledged: boolean;
    returnToRegisterDetail: {
      confirmUserNameChange: boolean;
      title: {
        name: string;
        id: string;
      };
      forenames: string;
      surname: string;
      middleName: string;
      englishCertificateOption: string;
      oetCandidateNo: string;
      confirmAccessOETPortal: string;
      hasConfirmedRevalidationSubmission: boolean;
      revalidationRejectDateTime: string;
    };
    letterOfGoodStanding: {
      regulatoryBody: string;
      registrationNumber: string;
      isRequested: string;
      hasRegistered: boolean;
    };
    appDeclaration: {
      isQ1Confirmed: boolean;
      isQ2Confirmed: boolean;
      isQ3Confirmed: boolean;
    };
    equalityDiversity: {
      ethnicity: number;
      ethnicityOther: string;
      nationality: string;
      religion: number;
      religionOther: string;
      disabled: number;
      disabilityDetails: string;
      gender: number;
      sexualOrientation: number;
    };
    declarations: [
      {
        dynamicFormId: string;
        answers: [
          {
            questionId: string;
            answer: string;
            answerText: string;
          }
        ];
      }
    ];
    restorationDeclarations: RestorationDeclarationQuestion[];
    isRestorationFeePaymentAvailable: boolean;
    id: string;
    formStatus: number;
    step: number;
    scope: number;
    attachments: [];
    countersignatures: [];
    createdAt: string;
    dateApplicationSubmitted: string;
  };
  personalDetails: {
    title: {
      name: string;
      id: string;
    };
    forenames: string;
    surname: string;
    middleName: string;
    dateOfBirth: string;
    address: {
      line1: string;
      line2: string;
      line3: string;
      town: string;
      county: string;
      postcode: string;
      country: string;
      homeNation: number;
      latitude: string;
      longitude: string;
      countryCode: string;
    };
    contact: {
      email: string;
      telephone1: string;
      mobilePhone: string;
    };
    registration: {
      registrationNumber: string;
      registrationStatus: number;
      independentPrescriberStatus: number;
      voluntaryRemovalReason: string;
      registrationRoute: string;
      contactType: number;
      isRequiredEnglishCertificate: boolean;
      isRequiredRevalidationSubmission: boolean;
    };
  };
  ftpQuestions: [
    { type: AnswerType; title: string; answer: string; body: string }
  ];
  revalidationDetails: {
    id: string,
    gPhCId: string,
    earliestSubmissionDate: string,
    renewalDate: string,
    status: RevalidationStatus,
    submissionDate: string,
    submissionStatus: CrmSubmissionStatus,
    revalidationStage: 1,
    reviewAndFeedbackStatus: CrmReviewAndFeedbackStatus,
    remediationStatus: CrmRemediationStatus,
    remediationIssuedOn: string,
    noirAndNorStatus: CrmNoirAndNorStatus,
    noirIssuedOn: string,
    norIssuedOn: string,
    noIRSupplimentaryOn: string,
    isSubmitted: boolean,
    isFullSubmission: boolean,
    expectation: {
        submissionDeadline: string,
        expectedCpds: number,
        maxUnplanned: number,
        expectedPeerDiscussions: number,
        expectedReflectiveAccounts: number,
        expectedTotalCount: number
    },
    entries: EntryItem,
    exceptionalCircumstance: []
}
}

export interface EntryItem {
    id: string,
    type: number,
    dynamicFormId: string,
    title: string,
    isCompleted: boolean,
    isSubmitted: boolean,
    lastUpdatedOn: string,
    form: {
        dynamicFormId: string,
        type: number,
        title: string,
        questionGroups: [
            {
                title: null,
                body: null,
                questions: [
                    {
                        type: 256,
                        title: string,
                        answer: string,
                        body: string,
                        referenceNumber: string
                    }
                ]
            }
        ]
    }
}

export interface RestorationDeclarationQuestion{
  questionName: string;
  isRegistered: boolean;
  isInvestigated: boolean;
  caseReferenceNo: string;
  investigationDate: string;
  titleUsed: string;
  employerName: string;
  howWhereUsedIt: string;
  employerAddress: Address;
  titleUsedFrom: string;
  titleUsedUntil: string;
}

export enum EnglishQualificationType {
  IELTS = 1,
  OET,
  EmployerReference,
}

export enum RegistrationRoute
{
    UK = 717750000,
    UKPlusOverseas = 717750001,
    OSPAP = 717750002,
    EEA = 717750003,
    NorthernIreland = 717750004,
    Legacy = 717750005
}