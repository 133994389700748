// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #e5f1eb;*/
.messages {
  padding-left: 0;
  margin-top: 50px;
  margin-bottom: 50px;
  list-style-type: none;
}

.messages li {
  color: #553c73;
  font-size: 1.2em;
  margin-top: 20px;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/style/variables.scss","webpack://./src/app/accounts/userLookup.scss"],"names":[],"mappings":"AAG0B,YAAA;ACDxB;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,qBAAA;AAAJ;;AAEE;EACE,cDPU;ECQV,gBAAA;EACA,gBAAA;EACA,iBAAA;AACJ","sourcesContent":["$gphc-blue: #00759b;\r\n$gphc-aqua: darken(#58b896,2.5%);\r\n$gphc-purple: #553c73;\r\n$gphc-background: #d9e8e1;/* #e5f1eb;*/\r\n$light-grey: #ececec;\r\n$grey: #dedede;\r\n$very-light-grey: #f7f7f7;\r\n\r\n$gphc-accent-fushia: #bc1d63;\r\n$gphc-accent-pink: #e94870;\r\n$gphc-accent-orange: #ef7b0f;\r\n$gphc-accent-yellow: #fcc340;\r\n\r\n$banner-background: $gphc-aqua;\r\n\r\n$error: #c52e2e;\r\n\r\n$navbar-height: 58;\r\n\r\n$legacy-modal-text-color: #000000de;\r\n$legacy-modal-padding: 24px;\r\n$legacy-negative-modal-padding: -$legacy-modal-padding;"," @import '../style/variables';\r\n\r\n  .messages {\r\n    padding-left: 0;\r\n    margin-top:50px;\r\n    margin-bottom: 50px;\r\n    list-style-type: none;\r\n  }\r\n  .messages li {\r\n    color: $gphc-purple;\r\n    font-size:1.2em;\r\n    margin-top:20px;\r\n    font-weight: bold;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
