import { Component, Input} from '@angular/core';
import { AttachmentType } from './model/attachmentType';
import { PreregApplicationService } from './service/prereg.service';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-reg-application',
    templateUrl: './regApplication.component.html',
    styleUrls: ['./application.scss']
})
export class RegApplicationComponent {
    @Input() application;

    constructor(
        private service: PreregApplicationService
    ) {}

}

