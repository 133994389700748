// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card.card-non h3.card-header {
  font-family: "Open sans", sans-serif !important;
  padding-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/dynamic/declarationQuestion.scss"],"names":[],"mappings":"AAAA;EACE,+CAAA;EACA,gBAAA;AACF","sourcesContent":[".card.card-non h3.card-header  {\r\n  font-family: 'Open sans', sans-serif !important;\r\n  padding-top:5px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
