import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ExtCircService } from '../service/extCirc.service';
import { ExtCirc } from '../model/ExtCirc';
import { ReviewStatus } from '../model/ReviewStatus';
import { LayoutService } from '../../core/service/layout.service';
import { UtcDatePipe } from '../../shared/pipe/UtcDate.pipe';


export interface SearchParams {
  regNumber: string;
  status: string
}
@Component({
  selector: 'app-ext-circ',
  moduleId: module.id,
  templateUrl: './extCircList.component.html',
  styleUrls: ['../list.scss', './extCirc.scss']
})
export class ExtCircListComponent implements OnInit , OnDestroy{


  loading = false;
  items: Array<ExtCirc>;
  searchVisible;
  assigningItem;
  ReviewStatus = ReviewStatus;
  searchCriteria: any = {
    regNumber: '',
    date: undefined,
    status: undefined,
    renewalDate: undefined
  };


  @ViewChild('regNumber') regNumberRef: ElementRef;

  uniqueRenewalDates: Date[];
  dateSelectedByUser: any;
  tempItems: any = [];
  filterParams: number;

  constructor(private service: ExtCircService,
    private utcDatePipe: UtcDatePipe,
    public layout: LayoutService) { }

  ngOnInit() {
    let data = localStorage.getItem('ecList');
    data = data ? JSON.parse(data) : {};
    this.filterParams = Object.keys(data).length;
    this.searchCriteria = this.filterParams ? data : this.searchCriteria;
    console.log(this.searchCriteria);
    this.loadAll();
  }

  filter(status: ReviewStatus) {
    this.searchCriteria.status = status;
    this.items = this.tempItems.filter((renewal) => {
      return renewal.reviewStatus == status;
    });
    this.searchCriteria.renewalDate = undefined;
    localStorage.setItem('ecList', JSON.stringify(this.searchCriteria));
  
  }

  loadAll() {
    this.loading = true;
    this.service.getAll().subscribe(items => {
      this.tempItems = items.sort((a, b) => {
        return new Date(a.submittedAt) > new Date(b.submittedAt) ? -1 : 1;
      });
      this.uniqueRenewalDates = this.tempItems
        .map(s => s.renewalDate)
        .filter((s, i, a) => a.indexOf(s) == i);
      this.items = this.tempItems;
      this.filterList();
      if (this.filterParams === 0) {
        this.clearSearch();
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  toggleSearch() {
    this.searchVisible = !this.searchVisible;
    if (this.searchVisible) {
      setTimeout(() => {
        this.regNumberRef.nativeElement.focus();
      });
    }
  }

  search() {
    this.loading = true;
    this.service.search(this.searchCriteria.regNumber).subscribe((uniqueRegNumber: any) => {
      this.items = [...uniqueRegNumber];
    });
    localStorage.setItem('ecList', JSON.stringify(this.searchCriteria));
    this.loading = false;
  }
  clearSearch() {
    localStorage.removeItem('ecList');
    this.searchCriteria.regNumber = '';
    this.searchCriteria.renewalDate = undefined;
    this.searchCriteria.status = undefined;
    this.items = this.tempItems;
  }

  dateChange(date) {
    const dateSelectedFromDatePicker = this.utcDatePipe.transform(date);
    this.items = this.tempItems.filter((item) => {
      return this.utcDatePipe.transform(item.renewalDate).includes(dateSelectedFromDatePicker);
    });
  }
  onRenewaldateSelected(date) {
    this.dateSelectedByUser = this.utcDatePipe.transform(date);
    this.items = this.tempItems.filter((item) => {
      const renewalSelectedDate = this.utcDatePipe.transform(item.renewalDate).includes(this.dateSelectedByUser);
      return this.searchCriteria.status ? (item.reviewStatus == this.searchCriteria.status) && renewalSelectedDate : renewalSelectedDate;
    });
    localStorage.setItem('ecList', JSON.stringify(this.searchCriteria));
  }

  filterList() {
    this.items = this.tempItems.filter((item) => {
      return this.searchCriteria.regNumber == item.registrationNumber ||
        this.searchCriteria.status == item.reviewStatus ||
        this.searchCriteria.renewalDate == item.renewalDate;
    });
  }
ngOnDestroy(){
this.filterList();
}
}
