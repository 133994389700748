export enum ReviewView {
  ExternalReview = 1,

  QualityAssurance = 2,

  QualityAssuranceCompleted = 3,

  QualityAssuranceManagerCompleted = 4,

  CoreCriteriaNotMet = 5,

  FeedbackSent = 6,

  ExcludedFromPublish = 7
}
