import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  moduleId: module.id,
  template: `<mat-spinner  [color]="color" [diameter]="diameter"></mat-spinner>`
})
export class SpinnerComponent implements OnInit {

   @Input() light = false;
   @Input() small = false;
   @Input() tiny = false;
   @Input() miniscule = false;
   color = 'accent';
   @Input() diameter = 100;

   ngOnInit() {
     if (this.light) {
       this.color = 'primary';
     }
     if (this.small) {
       this.diameter = 50;
     }
     if (this.tiny) {
      this.diameter = 30;
    }
    if (this.miniscule) {
      this.diameter = 20;
    }
   }


}
