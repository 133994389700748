// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .metadata-item {
  display: flex;
  max-width: 600px;
}
:host .metadata-item i {
  float: none;
}
:host ::ng-deep h4 {
  margin-top: 15px;
  margin-bottom: 15px;
  max-width: 60%;
  padding-bottom: 10px;
  border-bottom: 5px solid #f5f5f5;
}
:host ::ng-deep .limit-width {
  max-width: 600px;
}`, "",{"version":3,"sources":["webpack://./src/app/prereg/workExperience/workExperience.scss"],"names":[],"mappings":"AAEE;EAEE,aAAA;EACA,gBAAA;AAFJ;AAAI;EAAI,WAAA;AAGR;AAEI;EACE,gBAAA;EACA,mBAAA;EACA,cAAA;EACA,oBAAA;EACA,gCAAA;AAAN;AAEI;EACE,gBAAA;AAAN","sourcesContent":[":host {\r\n\r\n  .metadata-item {\r\n    i { float: none;}\r\n    display: flex;\r\n    max-width: 600px;\r\n  }\r\n  ::ng-deep {\r\n    h4 {\r\n      margin-top: 15px;\r\n      margin-bottom: 15px;\r\n      max-width: 60%;\r\n      padding-bottom: 10px;\r\n      border-bottom: 5px solid #f5f5f5;\r\n    }\r\n    .limit-width {\r\n      max-width: 600px;\r\n    }\r\n  }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
