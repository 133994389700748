// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
}
:host .carousel-buttons {
  height: 100%;
  display: flex;
  align-items: center;
}
:host .carousel-buttons button {
  height: 100%;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/carousel-nav.scss"],"names":[],"mappings":"AAAA;EAEE,YAAA;AAAF;AACE;EACE,YAAA;EACA,aAAA;EACA,mBAAA;AACJ;AAAI;EACE,YAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,6BAAA;AAEN","sourcesContent":[":host {\r\n\r\n  height: 100%;\r\n  .carousel-buttons {\r\n    height: 100%;\r\n    display:flex; \r\n    align-items: center;\r\n    button {\r\n      height: 100%;\r\n      padding: 15px;\r\n      display:flex;\r\n      flex-direction: column;\r\n      justify-content: space-evenly;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
