// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #e5f1eb;*/
.upload-status {
  text-align: center;
  vertical-align: middle;
}
.upload-status i {
  font-size: 36px;
  vertical-align: middle;
}
.upload-status i:not(.completed) {
  color: #00759b;
}
.upload-status i.error {
  color: #c52e2e;
}

.well {
  background: #f7f7f7;
  width: 100%;
  height: 80px;
  padding: 10px;
}

.my-drop-zone {
  border: dotted 3px #ececec;
}

.nv-file-over {
  border: dotted 3px #553c73;
} /* Default class applied to drop zones on over */
.another-file-over-class {
  border: dotted 3px #4fb490;
}`, "",{"version":3,"sources":["webpack://./src/app/style/variables.scss","webpack://./src/app/shared/fileUpload.scss"],"names":[],"mappings":"AAG0B,YAAA;ACA1B;EACE,kBAAA;EACA,sBAAA;AADF;AAEE;EAIE,eAAA;EACA,sBAAA;AAHJ;AADI;EACE,cDRM;ACWZ;AACI;EACE,cDEE;ACDR;;AAKA;EACE,mBDdgB;ECehB,WAAA;EACA,YAAA;EACA,aAAA;AAFF;;AAIA;EACI,0BAAA;AADJ;;AAIA;EACE,0BAAA;AADF,EAEE,gDAAA;AACF;EACE,0BAAA;AADF","sourcesContent":["$gphc-blue: #00759b;\r\n$gphc-aqua: darken(#58b896,2.5%);\r\n$gphc-purple: #553c73;\r\n$gphc-background: #d9e8e1;/* #e5f1eb;*/\r\n$light-grey: #ececec;\r\n$grey: #dedede;\r\n$very-light-grey: #f7f7f7;\r\n\r\n$gphc-accent-fushia: #bc1d63;\r\n$gphc-accent-pink: #e94870;\r\n$gphc-accent-orange: #ef7b0f;\r\n$gphc-accent-yellow: #fcc340;\r\n\r\n$banner-background: $gphc-aqua;\r\n\r\n$error: #c52e2e;\r\n\r\n$navbar-height: 58;\r\n\r\n$legacy-modal-text-color: #000000de;\r\n$legacy-modal-padding: 24px;\r\n$legacy-negative-modal-padding: -$legacy-modal-padding;","@import \"../style/variables\";\r\n\r\n\r\n.upload-status {\r\n  text-align:center;\r\n  vertical-align:middle;\r\n  i {\r\n    &:not(.completed) {\r\n      color: $gphc-blue;\r\n    }\r\n    font-size:36px;\r\n    vertical-align:middle;\r\n    &.error {\r\n      color: $error;\r\n    }\r\n  }\r\n}\r\n\r\n\r\n.well {\r\n  background: $very-light-grey;\r\n  width: 100%;\r\n  height: 80px;\r\n  padding: 10px;\r\n}\r\n.my-drop-zone { \r\n    border: dotted 3px $light-grey;\r\n   }\r\n  \r\n.nv-file-over { \r\n  border: dotted 3px $gphc-purple;\r\n} /* Default class applied to drop zones on over */\r\n.another-file-over-class { \r\n  border: dotted 3px $gphc-aqua;\r\n}\r\n "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
