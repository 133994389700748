import { RegistrantType } from './RegistrantType';
import { RegistrantStatus } from './RegistrantStatus';

export class Registrant {
  registrationNumber: string;
  status: RegistrantStatus;
  type: RegistrantType;
  postalTown: string;
  prescriberStatuses: string;
  superIntendentPositions: Array<string>;

  title: string;
  forenames: string;
  surname: string;

  expiryDate: string;
  joinedDate: string;

  constructor(data) {
    Object.assign(this, data);
  }


  toString() {
    return this.title +
    ' ' + this.forenames +
    ' ' + this.surname;
  }

}
