import { Component, Input, OnInit, AfterContentInit, ViewChild, ElementRef } from '@angular/core';
import { LogService } from '../core/service/log.service';

@Component({
  selector: 'app-form-layout',
  templateUrl: './formLayout.component.html',
  styleUrls: ['./formLayout.scss']
})
export class FormLayoutComponent implements OnInit, AfterContentInit {
  @Input() bodyWidth = 8;
  @Input() spread = false;
  @ViewChild('formHeader',) formHeader: ElementRef;
  buttonsWidth = 5;
  showHeader = false;

  ngOnInit() {
    this.buttonsWidth = 12 - this.bodyWidth;
  }

  ngAfterContentInit() {
    this.showHeader = this.formHeader.nativeElement.children.length > 0;
  }

}
