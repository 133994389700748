import { Component, Input, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Review } from '../../model/Review';
import { RevalidationItemTypeConfig } from '../../model/RevalidationItemTypeConfig';
import { revalidationItemTypes } from '../../model/revalidationItemTypes';
import { ReviewService } from '../../service/review.service';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService } from '../../../core/service/user.service';
import { CaseSplitPipe } from '../../../shared/pipe/CaseSplit.pipe';
import { ReviewStage } from '../../model/ReviewStage';
import { ReviewerRole } from '../../model/ReviewerRole';
import { Decision } from '../../model/Decision';
import { FeedbackConfirmation } from '../../model/FeedbackConfirmation';
import { ReviewView } from '../../model/ReviewView';
declare const require;

@Component({
  selector: 'app-review',
  moduleId: module.id,
  templateUrl: './review.component.html',
  styleUrls: ['../review.scss', './reviewDetail.scss']
})
export class ReviewComponent implements OnInit {
  reviewId: string;
  review: Review;
  RevalidationItemTypeConfig;
  sections: Array<RevalidationItemTypeConfig>;
  revalidationItemTypes = revalidationItemTypes;
  selected: RevalidationItemTypeConfig;
  ReviewerRole = ReviewerRole;
  selectedFeedback;

  selectedIndex = 0;
  helpVisible = false;
  items = [];
  chatVisible = false;
  chatOffset = -400;
  loading = false;
  approving = false;
  rejecting = false;
  excluding = false;
  shouldexcludedFromPublish;
  saving = false;
  title = '';
  readonly = false;
  showHelp = false;
  @ViewChild('rejectionReasonRef',) rejectionReasonRef: ElementRef;
  stage;
  renewalDate;
  cohortId;
  isQA;
  isQAManager;
  subHeading;
  panel;
  rejection;
  showRejection;
  rejectionReason;
  userId;
  managerApproved;
  navigatedFromGlobalSearch: any;
  user;
  revalidationReviewQAManager: boolean;
  ReviewView = ReviewView;
  ReviewStage = ReviewStage;


  constructor(private service: ReviewService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private caseSplit: CaseSplitPipe) {
    this.reviewId = route.snapshot.params.id;
    this.stage = route.snapshot.queryParams.stage;
    this.cohortId = route.snapshot.queryParams.cohortId;
    this.navigatedFromGlobalSearch = route.snapshot.queryParams.globalSearch;
  }

  ngOnInit() {

    this.getUserInReview();
    this.load();

  }

  getUserInReview() {
    return this.userService.getUser().subscribe(user => {
      this.user = user;
      if (user) {
        this.userId = user.objectID;
        this.isQA = user.roles.indexOf('RevalidationReviewQA') > -1;
        this.isQAManager = user.roles.indexOf('RevalidationReviewQAManagers') > -1;
      }
    });
  }

  setexcludedFromPublish(shouldExclude) {
    this.excluding = true;
    this.service.excludedFromPublish(this.reviewId, shouldExclude).subscribe(() => {
      this.excluding = false;
      this.review.excludedFromPublish = shouldExclude;
      this.panel = undefined;
    });
  }

  togglePanel(panel) {
    // if (this.panel === panel) {
    //   this.panel = undefined;
    // } else {
      this.panel = panel;
      if (panel === 'reject') {
        setTimeout(() => { this.rejectionReasonRef.nativeElement.focus(); });
      }
    // }

  }

  get role() {
    if (this.isQAManager) {
      return 'QA Manager';
    }
    if (this.isQA) {
      return 'QA';
    }
    return 'Customer services';
  }


  get currentUserIsFeedbackAuthor() {
    return this.review.feedback.author.userId === this.userId;
  }


  load() {

    this.loading = true;
    this.service.getReview(this.reviewId).subscribe(data => {
      this.review = data;

      console.log("user role is", this.role);
      this.rejection = this.review.feedback.feedbackApprovals
        .find(approval => approval.feedbackReviewDecision === FeedbackConfirmation.Rejected
          && approval.feedbackReviewerRole === ReviewerRole.QualityAssuranceManager);
      this.showRejection = !!this.rejection;

      this.managerApproved = this.review.feedback.feedbackApprovals
        .find(approval => approval.feedbackReviewDecision === FeedbackConfirmation.Confirmed
          && approval.feedbackReviewerRole === ReviewerRole.QualityAssuranceManager);

      this.review.isFeedbackEditable = !this.managerApproved && (
        (this.review.stage === ReviewStage.AwaitingFeedbackApprovalByQualityAssurer &&
          (this.isQA || this.isQAManager)) ||
        (this.review.stage === ReviewStage.QualityAssuranceCompleted && (this.isQAManager)) ||
        (this.review.stage === ReviewStage.CoreCriteriaNotMet && (this.isQAManager) && !this.review.inRemediation)
      );

      console.log("review.stage", this.review.stage);
      this.subHeading = this.caseSplit.transform(this.review.stageAsString);
      if (this.managerApproved) {
        this.subHeading += ' (Manager approved)';
      }

      this.shouldexcludedFromPublish = this.review.excludedFromPublish;
      this.selectedFeedback = this.review.feedback;
      this.loading = false;

    });
  }

  accept() {
    if (this.review.hasEdits) {
      this.saving = true;
      this.service.saveFeedback(this.reviewId, this.review.feedbackEdit).subscribe(data => {
        this.review.feedbackEdit.author.userId = this.userId;
        this.review.feedback = this.review.feedbackEdit;
        this.review.feedback.id = data;
        this.review.hasEdits = false;
        this.saving = false;
      }, error => {
        this.saving = false;
      });
    } else {
      this.approving = true;
      this.service.approveFeedback(this.review.id, this.review.feedback.id).subscribe(() => {
        this.approving = false;
        this.exit();
      }, error => { this.approving = false; });
    }


  }


  reject() {
    this.rejecting = true;
    this.service.rejectFeedback(this.review.id, this.review.feedback.id, this.rejectionReason).subscribe(() => {
      this.rejecting = false;
      this.exit();
    }, error => { this.rejecting = false; });
  }

  toggleChat() {
    this.chatVisible = !this.chatVisible;
    if (this.chatVisible) {
      this.chatOffset = 0;
    } else {
      this.chatOffset = -400;
    }
  }



  toggleHelp() {
    this.helpVisible = !this.helpVisible;
  }


  exit(filter: any = {}) {
    if (this.navigatedFromGlobalSearch) {
      this.router.navigate([`./revalidation/review/`]);
    } else if ((filter.excludedFromPublish) ||
      (filter.reviewView === 2) || (filter.reviewView === 3) || (filter.reviewView === 5) || (filter.reviewView === 6)) {
      this.router.navigate(['/revalidation/review/qaCohort', this.cohortId], { queryParams: filter });
      // this.router.navigate([`./revalidation/review/${this.stage}Cohort`, this.cohortId], { queryParams: filter });
    }
    else {
      this.router.navigate([`./revalidation/review/${this.stage}Cohort`, this.cohortId]);
    }

  }

  onSelectedSection(section) {
    this.selected = section;
    if (this.selected) {
      if (section.isFeedback) {
        this.items = this.review.entries.filter(entry => entry.type === section.type);
      } else {
        this.items = this.review.entries;
      }

      this.selectedIndex = 0;
    } else {
      this.items = this.review.entries;
      //   this.items = [];
    }

  }



}

