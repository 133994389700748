import { Component, Input, OnInit } from '@angular/core';
import { AttachmentType } from './model/attachmentType';
import { IndependentPrescriber } from './model/IndependentPrescriber';
import { PreregApplicationService } from './service/prereg.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-independent-prescriber-application',
  templateUrl: './independentPrescriberApplication.component.html',
  styleUrls: ['./application.scss']
})
export class IndependentPrescriberApplicationComponent implements OnInit {
  @Input() application;
  attachmentType = AttachmentType;
  name: string;
  preregNumber: number;
  submittedAt: any;
  registrationNumber: any;
  mentorRegistrationNumber: any;
  dateLessthanSixMonths = false;
  downloading = false;
  downloadClickCount: number;
  IpData: any;
  constructor(private service: PreregApplicationService) { }

  ngOnInit() {
    this.name = this.application.prescriberDetails ? this.application.prescriberDetails.title + ' ' + this.application.prescriberDetails.forenames + ' ' + this.application.prescriberDetails.surname : null;    
    this.IpData = this.application.form;
  }


  IsAwardedDateMoreThanSixMonths() {
    this.dateLessthanSixMonths = true;
    if (this.application.courseDetails.dateObtained) {
      const dateAwarded = new Date(this.application.courseDetails.dateObtained);
      const sixMonthsGreaterthanAwardedDate = new Date(dateAwarded.setMonth(dateAwarded.getMonth() + 6));
      if (new Date(this.application.courseDetails.dateObtained).getDate() !== sixMonthsGreaterthanAwardedDate.getDate()) {
        sixMonthsGreaterthanAwardedDate.setDate(1);
      }
      if (new Date() >= sixMonthsGreaterthanAwardedDate) {
        this.dateLessthanSixMonths = true;
      } else {
        this.dateLessthanSixMonths = false;
      }
    }
  }

  getIcon(file) {
    const bits = file.filename.split('.');
    const extension = bits[bits.length - 1];
    switch (extension) {
      case 'pdf':
        return 'fa fa-file-pdf-o';
      case 'png':
      case 'jpg':
      case 'bmp':
      case 'gif':
        return 'fa fa-file-image-o';
      case 'doc':
      case 'docx':
        return 'fa fa-file-word-o';
      case 'zip':
      case 'rar':
        return 'fa fa-file-archive-o';
      default:
        return 'fa fa-file-o';
    }
  }

  download(file): void {
    this.downloading = true;
    this.downloadClickCount += 1;
    this.service.download(environment.api.root + '/' + file.downloadUrl).subscribe(blob => {
      this.downloading = false;
      setTimeout(() => {
        this.downloadClickCount -= 1;
      }, 2000);
      const url = URL.createObjectURL(blob);
      const element = document.createElement('a');
      element.href = url;
      element.setAttribute('download', file.filename);
      document.body.appendChild(element); // Append the element to work in firefox
      element.click();
    });
  }

}