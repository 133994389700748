// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sticky {
  position: sticky;
  top: 0;
  z-index: 9999;
}
.sticky.fixed {
  position: fixed;
  width: 360px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/sticky.scss"],"names":[],"mappings":"AAAA;EACG,gBAAA;EACC,MAAA;EACA,aAAA;AACJ;AAAI;EACE,eAAA;EACA,YAAA;AAEN","sourcesContent":[".sticky {\r\n   position: sticky;\r\n    top: 0;\r\n    z-index: 9999;\r\n    &.fixed {\r\n      position: fixed;\r\n      width: 360px;\r\n    }\r\n  \r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
