// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #e5f1eb;*/
.min-height {
  min-height: 2.3em;
}

h3.header {
  border-left: 10px solid #026f92;
  margin-bottom: 20px !important;
  padding-left: 20px;
  font-size: 20px;
  transition: border-color 200ms linear;
}

.messageStatus {
  max-height: 49px;
  text-align: center !important;
  line-height: 2.5;
}
.messageStatus b {
  margin-left: 0.2em;
}

app-form-section ::ng-deep section.form-section {
  margin-top: 0px !important;
}

.error {
  margin-top: 20px;
  color: white;
  background: #c52e2e;
  font-weight: bold;
}

.message-acive {
  background: green !important;
}`, "",{"version":3,"sources":["webpack://./src/app/style/variables.scss","webpack://./src/app/core/maintenance.scss"],"names":[],"mappings":"AAG0B,YAAA;ACD1B;EACI,iBAAA;AAAJ;;AAGA;EACI,+BAAA;EACA,8BAAA;EACA,kBAAA;EACA,eAAA;EACA,qCAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,6BAAA;EACA,gBAAA;AAAJ;AACI;EACI,kBAAA;AACR;;AAIG;EACI,0BAAA;AADP;;AAKA;EACI,gBAAA;EACA,YAAA;EACA,mBDjBI;ECkBJ,iBAAA;AAFJ;;AAKA;EACI,4BAAA;AAFJ","sourcesContent":["$gphc-blue: #00759b;\r\n$gphc-aqua: darken(#58b896,2.5%);\r\n$gphc-purple: #553c73;\r\n$gphc-background: #d9e8e1;/* #e5f1eb;*/\r\n$light-grey: #ececec;\r\n$grey: #dedede;\r\n$very-light-grey: #f7f7f7;\r\n\r\n$gphc-accent-fushia: #bc1d63;\r\n$gphc-accent-pink: #e94870;\r\n$gphc-accent-orange: #ef7b0f;\r\n$gphc-accent-yellow: #fcc340;\r\n\r\n$banner-background: $gphc-aqua;\r\n\r\n$error: #c52e2e;\r\n\r\n$navbar-height: 58;\r\n\r\n$legacy-modal-text-color: #000000de;\r\n$legacy-modal-padding: 24px;\r\n$legacy-negative-modal-padding: -$legacy-modal-padding;","@import \"../style/variables\";\r\n\r\n.min-height {\r\n    min-height: 2.3em;\r\n}\r\n\r\nh3.header {\r\n    border-left: 10px solid #026f92;\r\n    margin-bottom: 20px!important;\r\n    padding-left: 20px;\r\n    font-size: 20px;\r\n    transition: border-color 200ms linear;\r\n}\r\n\r\n.messageStatus {\r\n    max-height: 49px;\r\n    text-align: center !important;\r\n    line-height: 2.5;\r\n    b {\r\n        margin-left: .2em;\r\n    }\r\n}\r\n\r\napp-form-section {\r\n   ::ng-deep section.form-section {\r\n       margin-top: 0px!important;\r\n   }\r\n}\r\n\r\n.error {\r\n    margin-top: 20px; \r\n    color: white;\r\n    background: $error;\r\n    font-weight: bold;\r\n}\r\n\r\n.message-acive {\r\n    background: green !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
