import { Injectable } from '@angular/core';
import { ServiceBase } from './service.base';
import { AuthService } from './auth.service';
import { LogService } from './log.service';
import { CustomErrorHandler } from './CustomErrorHandler';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable()
export class DevService extends ServiceBase {

  constructor(http: HttpClient, auth: AuthService, log: LogService, errorHandler: CustomErrorHandler) {
    super(http, auth, log, errorHandler);
  }

  testApi() {
    return this.get('api/values');
  }

}
