// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* #e5f1eb;*/
/* #e5f1eb;*/
::ng-deep .spinner-container {
  text-align: center;
  color: #00759b;
  font-size: 18px;
}
::ng-deep mat-spinner {
  margin: 0 auto;
}

.dialog-buttons {
  background: #00759b;
  margin: -24px;
  padding: 30px;
}
.dialog-buttons button {
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
  transition: 200ms linear all;
}
.dialog-buttons button:disabled {
  background: #00759b !important;
  opacity: 0.5;
}

textarea {
  height: 150px;
}

.error {
  color: #c52e2e;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/style/variables.scss","webpack://./src/app/style/dialog.scss","webpack://./src/app/revalidation/exceptionalCircumstances/extCircApproveRejectDialog.scss"],"names":[],"mappings":"AAG0B,YAAA;AAAA,YAAA;ACAtB;EACE,kBAAA;EACA,cDLM;ECMN,eAAA;ACAN;ADGI;EACG,cAAA;ACDP;;ADME;EACE,mBDhBQ;ECiBR,aAAA;EACA,aAAA;ACHJ;ADKI;EACE,aAAA;EACA,qBAAA;EACA,sBAAA;EACA,mBAAA;EAEA,4BAAA;ACJN;ADKM;EACE,8BAAA;EACA,YAAA;ACHR;;AAvBE;EACE,aAAA;AA0BJ;;AAvBE;EACE,cFOI;EENJ,iBAAA;AA0BJ","sourcesContent":["$gphc-blue: #00759b;\r\n$gphc-aqua: darken(#58b896,2.5%);\r\n$gphc-purple: #553c73;\r\n$gphc-background: #d9e8e1;/* #e5f1eb;*/\r\n$light-grey: #ececec;\r\n$grey: #dedede;\r\n$very-light-grey: #f7f7f7;\r\n\r\n$gphc-accent-fushia: #bc1d63;\r\n$gphc-accent-pink: #e94870;\r\n$gphc-accent-orange: #ef7b0f;\r\n$gphc-accent-yellow: #fcc340;\r\n\r\n$banner-background: $gphc-aqua;\r\n\r\n$error: #c52e2e;\r\n\r\n$navbar-height: 58;\r\n\r\n$legacy-modal-text-color: #000000de;\r\n$legacy-modal-padding: 24px;\r\n$legacy-negative-modal-padding: -$legacy-modal-padding;"," @import '../style/variables';\r\n\r\n  ::ng-deep {\r\n    .spinner-container {\r\n      text-align: center; \r\n      color: $gphc-blue;\r\n      font-size:18px;\r\n    }\r\n\r\n    mat-spinner {\r\n       margin: 0 auto;\r\n     }\r\n  }\r\n\r\n\r\n  .dialog-buttons {\r\n    background: $gphc-blue;\r\n    margin: -24px;\r\n    padding: 30px;\r\n\r\n    button {\r\n      display:flex;\r\n      align-content:center;\r\n      flex-direction: column;\r\n      align-items: center;\r\n\r\n      transition: 200ms linear all;\r\n      &:disabled {\r\n        background: $gphc-blue !important;\r\n        opacity: 0.5;\r\n      }\r\n    }\r\n\r\n}\r\n"," @import '../../style/variables';\r\n @import '../../style/dialog';\r\n \r\n  textarea {\r\n    height: 150px;\r\n  }\r\n\r\n  .error {\r\n    color: $error;\r\n    font-weight: bold;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
