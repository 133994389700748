
import { fromEvent as observableFromEvent, Observable } from 'rxjs';

import { debounceTime } from 'rxjs/operators';
import { Component, Input, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Review } from '../../model/Review';
import { Message } from '../../model/Message';
import { ReviewService } from '../../service/review.service';





function mapMessage(message) {
  if (message.dateParsed) {
    if (message.date) { return; }
    message.ticks = message.dateParsed.getTime();
    message.date = new Date(message.ticks).setHours(0, 0, 0, 0);
    message.time = message.dateParsed.getHours() + ':' + message.dateParsed.getMinutes();
    return message;
  }
  const date = message.dateTime.split(' ')[0];
  const time = message.dateTime.split(' ')[1];
  const hour = parseInt(time.split(':')[0], 10);
  const min = parseInt(time.split(':')[1], 10);
  const sec = parseInt(time.split(':')[2], 10);
  const day = parseInt(date.split('/')[0], 10);
  const month = parseInt(date.split('/')[1], 10) - 1;
  const year = parseInt(date.split('/')[2], 10);
  const dateParsed = new Date(year, month, day, hour, min, sec || 0, 0);
  message.ticks = dateParsed.getTime();
  message.dateParsed = new Date(message.ticks);
  message.date = dateParsed.setHours(0, 0, 0, 0);
  message.time = time.split(':').slice(0, 2).join(':');

  return message;
}

@Component({
  selector: 'app-discussion',
  moduleId: module.id,
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.scss']
})
export class DiscussionComponent implements AfterViewInit, OnInit, OnDestroy {

  @Input() review: Review;
  @Input() set isOpen(value) {
    this.open = value;
    if (value) {
      setTimeout(() => {
        this.sendboxRef.nativeElement.focus();
      }, 250);
    }
  }
  helpVisible = false;
  scrolling = false;
  open = false;
  hasFocus = false;
  @ViewChild('messageRef',) messagesRef: ElementRef;
  @ViewChild('sendboxRef',) sendboxRef: ElementRef;
  messages: Message[] = [];
  groupedByDate = [];
  sending = false;
  refreshIntervalSeconds = 5;
  refreshTimer;
  lastMessageLoad: Date;


  constructor(private service: ReviewService) { }


  ngOnInit() {
    this.concatMessages(this.review.messages);
    // .sort((a,b) => {
    //  return a.dateTime > b.dateTime ? -1 : 1;
    // });
    observableFromEvent(window, 'resize').pipe(
      debounceTime(100))
      .subscribe(this.scroll.bind(this));

    this.lastMessageLoad = new Date();

  }

  concatMessages(newMessages) {
    this.messages = this.messages.concat(newMessages.map(mapMessage));
    this.makeDateGrouping();
  }

  makeDateGrouping() {
    const distinctDates = this.messages.reduce((acc, message) => {
      if (acc.indexOf(message.date) === -1) {
        acc.push(message.date);
      }
      return acc;
    }, []);

    this.groupedByDate = distinctDates.reduce((acc, date) => {
      acc.push({ date, messages: this.messages.filter(message => message.date === date) });
      return acc;
    }, []);
  }

  ngAfterViewInit() {
    setTimeout(this.scroll.bind(this));
  }

  ngOnDestroy() {
    clearTimeout(this.refreshTimer);
  }

  scroll() {
    this.messagesRef.nativeElement.scrollTop = this.messagesRef.nativeElement.scrollHeight;
  }


}
