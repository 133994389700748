import { Component, Input, AfterViewInit, ViewChildren, forwardRef } from '@angular/core';
import { Address } from './model/Address';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-address',
  moduleId: module.id,
  templateUrl: './address.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressComponent),
      multi: true
    }
  ]
})
export class AddressComponent implements ControlValueAccessor  {
  address: Address = new Address();
  @Input() touched = false;
  @Input('address') set setAddress(value) {
    this.address = value || {};
  }
  manualInput = false;
  @Input('manualInput') set setManualInput(manualInput) {
    this.manualInput = manualInput;
  }
  @Input() lineOneEditable = false;
  @Input() set lineOneFocussed(value) {
    if (value) {
      this.setFocus();
    }
  }

  @ViewChildren('focus') vc;


  setFocus() {
    setTimeout(function () {
      if (this.vc.first && this.vc.first.nativeElement) {
        this.vc.first.nativeElement.focus();
      }
    }.bind(this), 300);
  }


  propagate() {
    this.propagateChange(this.address);
  }

  writeValue(value: any) {
    if (value) {
      this.address = value;
    }
  }
  propagateChange = (model: any) => {};
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() {}

}
