// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .card.card-non {
  border: none;
  margin-left: 0;
  padding-left: 0;
}
:host ::ng-deep .form-control {
  max-width: 600px;
}`, "",{"version":3,"sources":["webpack://./src/app/prereg/applicationFtp.scss"],"names":[],"mappings":"AACE;EACE,YAAA;EACA,cAAA;EACA,eAAA;AAAJ;AAEE;EACE,gBAAA;AAAJ","sourcesContent":[":host {\r\n  ::ng-deep .card.card-non {\r\n    border: none;\r\n    margin-left: 0;\r\n    padding-left: 0;\r\n  }\r\n  ::ng-deep .form-control {\r\n    max-width: 600px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
