import { Component, Input, OnInit } from '@angular/core';
import { CpdEntry } from '../../../model/CpdEntry';
import { revalidationItemTypes } from '../../../model/revalidationItemTypes';
import { RevalidationItemTypeConfig } from '../../../model/RevalidationItemTypeConfig';

@Component({
  selector: 'app-cpd-entry',
  moduleId: module.id,
  templateUrl: './cpdEntry.component.html',
  styleUrls: ['./cpdEntry.scss']
})
export class CpdEntryComponent implements OnInit {

  @Input() cpdEntry: CpdEntry;
  @Input() typeVisible = true;
  @Input() annotationsVisible = false;

  type: RevalidationItemTypeConfig;

  ngOnInit() {
    this.type = revalidationItemTypes.filter(t => t.type === this.cpdEntry.type)[0];
  }

}
