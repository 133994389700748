// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results { max-height: 200px; overflow-y: auto}
  tr.ineligible { background: #f5f5f5; color: #b7b7b7; }
  `, "",{"version":3,"sources":["webpack://./src/app/prereg/workExperience/selectRegistrant.component.ts"],"names":[],"mappings":"AAAA,WAAW,iBAAiB,EAAE,gBAAgB;EAC5C,gBAAgB,mBAAmB,EAAE,cAAc,EAAE","sourcesContent":[".results { max-height: 200px; overflow-y: auto}\n  tr.ineligible { background: #f5f5f5; color: #b7b7b7; }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
