import { Directive, OnInit, OnDestroy, Input, ElementRef, HostListener, HostBinding, ViewContainerRef } from '@angular/core';
import { TooltipService } from './tooltip.service';
import { Tooltip } from './Tooltip';


function isTooltip(tooltip: string | Tooltip): tooltip is Tooltip {
  return tooltip && (<Tooltip>tooltip).content !== undefined;
}

@Directive({ selector: '[appTooltip]' })
export class TooltipDirective implements OnInit, OnDestroy {

  @Input() appTooltip;
  tooltip: Tooltip;
  hovering = false;
  showDelay = 500;
  initialised = false;
  @Input() solo = false;
  @HostBinding('attr.aria-label') ariaLabel;

  constructor(private _view: ViewContainerRef,
    private element: ElementRef, private service: TooltipService) { }

  ngOnInit() {
    if (!this.appTooltip) { return; }


    // if tooltip is supplied as string only, construct a tooltip object
    if (!isTooltip(this.appTooltip)) {
      const content: string = <string>this.appTooltip;
      const wordCount = content.split(' ').length;
      this.tooltip = {
        content,
        placement: 'top',
        showOnHover: true,
        width: wordCount < 3 ? 120 : 180,
        solo: true
      };
    } else {
      this.tooltip = Object.assign({}, this.appTooltip);
    }

    const solo = this.tooltip.solo !== undefined ? this.tooltip.solo : this.solo;
    const id = this.tooltip.id || Math.random();

    this.tooltip = Object.assign(this.tooltip,
      {
        id,
        solo,
        source: this.element,
        visible: false,
        width: this.tooltip.width || 180
      }
    );

    this.service.add(this.tooltip);

    this.ariaLabel = this.removeTags(this.tooltip.content);


  }

  removeTags(content): string {
    if (content === undefined) { return ''; }
    let withoutTags = content.replace(/<[^>]*>/g, ' ');
    withoutTags = withoutTags.replace(/\s+/g, ' ');
    return withoutTags.trim();
  }

  @HostListener('click', ['$event']) onClick($event) {
    if (this.tooltip && this.tooltip.showOnClick) {
      this.toggle();
    }
  }

  @HostListener('mouseenter', ['$event']) onMouseEnter($event) {
    if (this.tooltip && this.tooltip.showOnHover) {
      this.hoverShow();
    }

  }
  @HostListener('focus', ['$event']) onFocus($event) {
    if (this.tooltip && this.tooltip.showOnFocus) {
      this.show();
    }
  }

  @HostListener('mouseleave', ['$event']) onMouseLeave($event) {
    if (this.tooltip && this.tooltip.showOnHover) {
      this.hide();
    }
  }

  @HostListener('blur', ['$event']) onBlur($event) {
    if (this.tooltip && this.tooltip.showOnFocus) {
      this.hide();
    }
  }

  hoverShow() {
    if (!this.tooltip || this.tooltip.notSolo) { return; }
    if (this.service.soloTip === this.tooltip) { return; }
    if (this.service.activeContext) { return; }
    this.hovering = true;
    setTimeout(() => {
      if (this.service.activeContext) { return; }
      if (this.hovering && !(this.tooltip.isHidden && this.tooltip.isHidden())) {
        this.service.showTooltip(this.tooltip);
      }
    }, this.showDelay);
  }

  toggle() {
    if (this.tooltip && this.service.soloTip === this.tooltip) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    if (!this.tooltip || this.tooltip.notSolo) { return; }
    if (this.service.soloTip === this.tooltip) { return; }
    if (this.service.activeContext) { return; }
    this.service.showTooltip(this.tooltip);
  }

  hide() {

    this.hovering = false;
    this.service.hideTooltip(this.tooltip);
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  destroy(): void {
    if (!this.tooltip) { return; }
    this.service.remove(this.tooltip);
    this.hovering = false;
    this.tooltip = undefined;
    this.service.soloTip = undefined;
  }
}
