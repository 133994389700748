
export class Address {
  line1: string;
  line2: string;
  line3: string;
  town: string;
  county: string;
  postcode: string;
  country: string;

  constructor(data?: any) {
    Object.assign(this, data);
  }

  private concat(item: string) {
    return item ? item + ', ' : '';
  }

  toString() {
    return this.concat(this.line1) +
    this.concat(this.line2) +
    this.concat(this.line3) +
    this.concat(this.town) +
    this.concat(this.county) +
    this.country + ' ' +
    this.postcode;

  }
}


