import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { LayoutService } from '../../core/service/layout.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReviewService } from '../service/review.service';

@Component({
  templateUrl: './sampling.component.html',
  selector: 'app-sampling',
  styleUrls: ['../list.scss', './review.scss']
})
export class SamplingComponent implements OnInit {

  constructor(private router: Router, public layout: LayoutService, private service: ReviewService) { }

  generating = false;
  renewalDate;
  percentage = 2.5;
  @Input()samplings;
  loadingSamplings = false;

  @Output() created = new EventEmitter<string>();

  ngOnInit() {}

  loadSamplings() {
    this.loadingSamplings = true;
    this.service.getSamplings().subscribe(data => {
      this.samplings = data.filter(item => !item.revalidationReviewSamplingId);
      this.loadingSamplings = false;
    });
  }

  generate() {
    this.generating = true;
    this.service.generateSamplings(this.renewalDate, this.percentage).subscribe(() => {
      this.generating = false;
      this.loadSamplings();
      this.created.emit(this.renewalDate);
    });
  }



}
