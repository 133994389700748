import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector:'app-training-details',
    styleUrls:['./trainingDetails.component.scss'],
    templateUrl: './trainingDetails.component.html'
})
export class TrainingDetails implements OnInit {
    @Input() trainingDetails;
    
    constructor(){}

    ngOnInit(): void {}
}