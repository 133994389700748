import { Component, OnInit, OnChanges, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { PreregApplicationService } from './service/prereg.service';
import { RegApplication } from './model/RegApplication';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.scss']
})
export class ApplicationsComponent implements
  AfterViewInit, OnInit {
  searchInput: string;
  searchRegInput: string;
  searchExamInput: string;
  searchFinalDeclarationInput: string;
  searchAssessmentInput: string;
  searchTechInput: string;
  searchPrescriberInput: string;
  searchVRApplicant: string;
  searchRTRApplicant: string;
  searchCCPSApplicant: string;
  application: any;
  searching = false;
  noResults = false;
  touched = false;
  _type = 'prereg';
  isPrereg = false;
  isProgressReport = false;
  isExam = false;
  isFinalDeclaration = false;
  isPrescriber = false;
  userIDOfApplicationToDelete;
  eventSubject: Subject<string> = new Subject<string>();
  progressReport;
  finalDeclaration;
  progressReportIsNUll = false;
  preregList = [{title: 'Pre-reg',
                type:'prereg'},
                {title:'Registration',
                type:'reg'},
                {title:'Technician',
                type:'technician'},
                {title:'Progress report',
                type:'assessment-report'},
                {title:'Exam',
                type:'exam'},
                {title:'Final Declaration',
                type:'finalDeclaration'}];
  registrantList = [{title:'Independent prescriber', type:'independentPrescriber'},
  {title:'Voluntary Removal',type:'vr'},
  {title:'Restorations',type:'rtr'},
  {title:'Certificate of current professional status & FtP history', type:'ccps'}];
  selectedIndex = 0;
  tabSelected = 'prereg';

  @ViewChild('focus',) focusElement: ElementRef;
  @ViewChild('searchForm',) form: NgForm;
  validationErrors = [];
  independentPrescriber: boolean;
  constructor(private service: PreregApplicationService) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.focusElement.nativeElement.focus();
    }, 100);
  }

  get type() {
    return this._type;
  }

  set type(type) {
    this._type = type;
    this.noResults = false;
    this.application = false;
    this.progressReport = false;
    this.finalDeclaration = false;
    this.searchInput = '';
    this.searchExamInput = '';
    this.searchFinalDeclarationInput = '';
    this.searchAssessmentInput = '';
    this.searchTechInput = '';
    this.searchRegInput = '';
    this.searchPrescriberInput = '';
    this.searchVRApplicant = '';
    this.searchRTRApplicant = '';
    this.searchCCPSApplicant = '';
  }

  setIndex(index,user){
    this.selectedIndex = index;
    this.type = user.type;
  }

  resetFocus(val) {
    this.selectedIndex = 0;
    this.tabSelected = val;
    if(this.tabSelected === 'prereg') this.type = this.preregList[0].type;
    if(this.tabSelected === 'registrant') this.type = this.registrantList[0].type;
  }

  searchInputChanged() {
    this.noResults = false;
    this.application = false;
  }

  search() {
console.log("search");
    this.touched = true;
    if (this.form.invalid) { return; }
    //this.searching = true;
    this.noResults = false;   
    switch (this._type) {
      case 'prereg':
        this.service.getPreregApplication(this.searchInput).subscribe(data => {
          this.assignApplication(data);
        }, error => {
          this.assignError(error);
        });
        break;
      case 'reg':
        this.service.getRegApplication(this.searchRegInput).subscribe(data => {
          this.assignApplication(data);
        }, error => {
          this.assignError(error);
        });
        break;
      case 'technician':
        this.service.getTechnicianApplication(this.searchTechInput).subscribe(data => {
          this.assignApplication(data);
        }, error => {
          this.assignError(error);
        });

        break;
      case 'assessment-report':
        this.service.getAssessmentApplication(this.searchAssessmentInput).subscribe(data => {          
          this.assignApplication(data);
          this.progressReport = this.application.progressReport;          
        }, error => {
          this.assignError(error);
        });
        break;
      case 'exam':
        this.service.getExamApplication(this.searchExamInput).subscribe(data => {
          this.assignApplication(data);
        }, error => {
          this.assignError(error);
        });

        break;

      case 'finalDeclaration':
        this.service.getFinalDeclarationApplication(this.searchFinalDeclarationInput).subscribe(data => {
          this.assignApplication(data);
        }, error => {
          this.assignError(error);
        });

        break;

        case 'independentPrescriber':
        this.service.getPrescriberApplication(this.searchPrescriberInput).subscribe(data => {
          this.assignApplication(data);
        }, error => {
          this.assignError(error);
        });

        break;

        case 'vr':
        this.service.getVRApplication(this.searchVRApplicant).subscribe(data => {
          this.assignApplication(data);
        }, error => {
          this.assignError(error);
        });

        break;

        case 'rtr':
        this.service.getRTRApplication(this.searchRTRApplicant).subscribe(data => { 
          this.assignApplication(data);
        }, error => {
          this.assignError(error);
        });

        break;

        case 'ccps':
        this.service.getCCPSApplication(this.searchCCPSApplicant).subscribe(data => { 
          this.assignApplication(data);
        }, error => {
          this.assignError(error);
        });
    }

  }

  assignApplication(data):void {
    this.application = data;
    this.searching = false;
    this.touched = false;
  }

  assignError(error):void {
    this.searching = false;
    this.touched = false;
    this.noResults = (error.status === 400 || error.status === 404 || error.status === 500);
    console.error(error);
  }

  clearsearch(): void {
    this.searchInput = '';
    this.searchExamInput = '';
    this.searchFinalDeclarationInput = '';
    this.searchAssessmentInput = '';
    this.searchTechInput = '';
    this.searchRegInput = '';
    this.noResults = false;
    this.touched = false;
    this.searching = false;
    this.application = false;
    this.progressReport = false;
    this.finalDeclaration = false;
    this.validationErrors = [];
    this.searchPrescriberInput = '';
    this.independentPrescriber = false;
    this.searchVRApplicant = '';
    this.searchRTRApplicant='';
    this.searchCCPSApplicant = '';
  }

  deleteApplication() {
    this.eventSubject.next(this.searchVRApplicant);
    //this.service.deleteVRApplication(this.searchVRApplicant);
  }

  onVRapplicationDelete(value: boolean) {
    if(value) {
      this.application = false;
      this.clearsearch();
    }
  }

  get applicationStatus() {
    // vr form
    const formStatus = this.application ? (this.application.form ? this.application.form.formStatus : null ) : null;
    return formStatus;
  }
} 