import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { LayoutService } from '../../core/service/layout.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RevalidationStatus } from '../model/RevalidationStatus';
import { ExtCircService } from '../service/extCirc.service';
import { ReviewService } from '../service/review.service';
import { CohortStatus } from '../model/CohortStatus';
import { DatePipe } from '@angular/common';
import { ReviewStage } from '../model/ReviewStage';
import { PerformanceIndicator } from '../model/PerformanceIndicator';
import { CaseSplitPipe } from '../../shared/pipe/CaseSplit.pipe';
import { PagerComponent } from '../../shared/pager.component';
import { FriendlyBooleanPipe } from '../../shared/pipe/FriendlyBoolean.pipe';
import { ReviewView } from '../model/ReviewView';

@Component({
  templateUrl: './qaCohort.component.html',
  styleUrls: ['../list.scss', './review.scss']
})
export class QaCohortComponent implements OnInit, AfterViewInit {

  loading = false;
  data = [];
  CohortStatus = CohortStatus;
  cohortId;
  ReviewStage = ReviewStage;
  cohort: any = {};
  PerformanceIndicator = PerformanceIndicator;
  filterParams;
  activeFilterParams;
  activeFilterParamsInfo = [];
  panel;
  heading;
  reviewers = [];
  assigningItem;
  @ViewChild(PagerComponent,) pager: PagerComponent;

  reviewView;
  ReviewView = ReviewView;

  editing: {
    id?;
    lead?;
    secondary?;
    row?;
    isValid?;
    validationMessage?;
  } = {};

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public layout: LayoutService, public service: ReviewService,
    private datePipe: DatePipe, private caseSplitPipe: CaseSplitPipe, private friendlyBooleanPipe: FriendlyBooleanPipe) {
    this.cohortId = activatedRoute.snapshot.params['cohortId'];
    this.filterParams = this.emptyFilterParams();
    this.activeFilterParams = this.emptyFilterParams();

    const params = Object.assign({}, activatedRoute.snapshot.queryParams);

    this.reviewView = params.reviewView;
    delete params.reviewView;

    for (const key in params) {

      if (params.hasOwnProperty(key)) {
        if (params[key] === 'true') {
          params[key] = true;
        }
        if (params[key] === 'false') {
          params[key] = false;
        }
      }
    }
    Object.assign(this.filterParams, params);
  }

  ngOnInit() {
    this.service.getReviewers().subscribe(data => {
      this.reviewers = data;
    });
  }

  ngAfterViewInit() {
    this.load();
  }

  load() {
    this.pager.load(Object.assign({ id: this.cohortId, reviewView: this.reviewView }, this.filterParams));
  }



  emptyFilterParams() {
    return {
      reviewStage: undefined,
      reviewer: undefined,
      finalAssessment: undefined,
      excludedFromPublish: undefined,
      qaManagerApproved: undefined,
      qaApproved: undefined
    };
  }

  returnToSummary() {
    this.router.navigate(['./revalidation/review']);
  }

  onLoad(data) {
    this.data = data.reviews;
    this.cohort = data.cohortStatistics;
    this.setActiveFilterParams();
    this.loading = false;
  }

  toggleEdit(row) {
    if (row.id === this.editing.id) {
      this.editing = { id: undefined, lead: undefined, secondary: undefined, row: undefined };
    } else {
      this.editing = {
        row,
        id: row.id,
        lead: row.leadReviewerUserId,
        secondary: row.secondaryReviewerUserId,
        isValid: true
      };
    }
  }

  cancelEdit() {
    this.editing = { id: undefined, lead: undefined, secondary: undefined, row: undefined };
  }


  setActiveFilterParams() {
    this.activeFilterParams = Object.assign({}, this.filterParams);

    const formatParamValue = (key, value) => {
      switch (key) {
        case 'excludedFromPublish':
        case 'qaManagerApproved':
        case 'qaApproved':
          return this.caseSplitPipe.transform(key) + ': ' + this.friendlyBooleanPipe.transform(value);
        case 'reviewStage':
          return this.caseSplitPipe.transform(ReviewStage[value]);
        case 'finalAssessment':
          return this.caseSplitPipe.transform(PerformanceIndicator[value]);
        case 'reviewer':
          return this.reviewers.find(reviewer => reviewer.userId === value).name;
        default:
          return this.caseSplitPipe.transform(key) + ': ' + value;
      }
    };

    if (this.data.length) {
      this.panel = undefined;
    }

    this.activeFilterParamsInfo = Object.keys(this.activeFilterParams).map(key => {
      if (this.activeFilterParams[key] !== undefined) {
        return { key, value: formatParamValue(key, this.activeFilterParams[key]) };
      }
      return false;
    }).filter(v => !!v);
  }

  openReview(row) {
    this.router.navigate(['./revalidation/review', row.id], {
      queryParams: { stage: 'qa', cohortId: this.cohortId }
    });
  }

  togglePanel(panel) {
    if (this.panel === panel) {
      this.panel = undefined;
    } else {
      this.panel = panel;
    }
  }


  clearAllFilters() {
    this.filterParams = this.emptyFilterParams();
    if (this.hasActiveFilterParams) {
      this.load();
    }

  }

  clearFilter(filter) {
    this.filterParams[filter] = undefined;
    if (this.hasActiveFilterParams) {
      this.load();
    }
  }



  get filterParamsAreActive() {
    if (!this.hasActiveFilterParams) {
      return false;
    }
    for (const key in this.filterParams) {
      if (this.filterParams.hasOwnProperty(key)) {
        if (this.filterParams[key] !== undefined && this.activeFilterParams[key] === undefined) {
          return false;
        }
        if (this.activeFilterParams[key] !== undefined && this.filterParams[key] === undefined) {
          return false;
        }
        if (this.activeFilterParams[key] !== this.filterParams[key]) {
          return false;
        }
      }
    }
    return true;
  }

  get hasActiveFilterParams() {
    for (const key in this.activeFilterParams) {
      if (this.activeFilterParams[key] !== undefined && this.activeFilterParams[key] !== '') {
        return true;
      }
    }
    return false;
  }

  get hasFilterParams() {
    for (const key in this.filterParams) {
      if (this.filterParams[key] !== undefined && this.filterParams[key] !== '') {
        return true;
      }
    }
    return false;
  }

}

