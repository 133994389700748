import { NgModule, ErrorHandler } from '@angular/core';
import { CustomErrorHandler } from '../core/service/CustomErrorHandler';
import { ApplicationsComponent } from './applications.component';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { PreregApplicationService } from './service/prereg.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegApplicationComponent } from './regApplication.component';
import { PreregApplicationComponent } from './preRegApplication.component';
import { ApplicationSupportingDocsComponent } from './applicationSupportingDocs.component';
import { ApplicationDetailsComponent } from './applicationDetails.component';
import { ApplicationFtpComponent } from './applicationFtp.component';
import { DynamicModule } from '../dynamic/dynamic.module';
import { TechnicianApplicationComponent } from './technicianApplication.component';
import { ApplicationEducationDetailsComponent } from './educationDetails/application-education-details.component';
import { WorkExperienceComponent } from './workExperience/workExperience.component';
import { SelectRegistrantComponent } from './workExperience/selectRegistrant.component';
import { SelectPremisesComponent } from './workExperience/selectPremises.component';
import { TechnicianService } from './service/technician.service';
import { AssessmentReportApplicationComponent } from './assessmentReport.component';
import { AssessmentRegistrationApplicationComponent } from './assessmentRegistration.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FinalDeclarationApplicationComponent } from './finalDeclarationApplication.component';
import { IndependentPrescriberApplicationComponent } from './independentPrescriberApplication.component';
import { VoluntaryRemovalComponent } from './voluntaryRemovalApplication.component';
import { ReturnToRegisterComponent } from './returnToRegisterApplication.component';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import { CCPSComponent } from './CCPSApplication.component';
import { TrainingDetails } from './trainingDetails.component';

@NgModule({
    imports: [
        SharedModule,
        CoreModule,
        MatAutocompleteModule,
        FormsModule,
        ReactiveFormsModule,
        DynamicModule,
        CdkAccordionModule
    ],
    declarations: [
        ApplicationsComponent,
        RegApplicationComponent,
        PreregApplicationComponent,
        ApplicationSupportingDocsComponent,
        ApplicationDetailsComponent,
        ApplicationFtpComponent,
        TechnicianApplicationComponent,
        AssessmentReportApplicationComponent,
        ApplicationEducationDetailsComponent,
        WorkExperienceComponent,
        SelectRegistrantComponent,
        SelectPremisesComponent,
        AssessmentRegistrationApplicationComponent,
        FinalDeclarationApplicationComponent,
        IndependentPrescriberApplicationComponent,
        VoluntaryRemovalComponent,
        ReturnToRegisterComponent,
        CCPSComponent,
        TrainingDetails
    ],
    providers: [
        PreregApplicationService,
        TechnicianService,
        { provide: ErrorHandler, useClass: CustomErrorHandler }
    ]
})
export class PreregModule { }
