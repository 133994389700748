import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../core/service/layout.service';
import { Router } from '@angular/router';
import { RevalidationStatus } from '../model/RevalidationStatus';
import { ExtCircService } from '../service/extCirc.service';
import { ReviewService } from '../service/review.service';
import { CohortStatus } from '../model/CohortStatus';
import { TooltipService } from '../../core/tooltip/tooltip.service';
import { DatePipe } from '@angular/common';
import { ReviewView } from '../model/ReviewView';
import { ReviewStage } from '../model/ReviewStage';
import { PerformanceIndicator } from '../model/PerformanceIndicator';

const colours = [
  //  'rgba(239, 100, 57,0.5)',
  'rgba(19, 179, 136)',
  'rgba(0, 147, 220)',
  'rgba(206, 182, 0)',
  'rgba(255, 36, 171)',
  'rgba(255, 130, 62)',
  'rgba(127, 100, 239)',
  'rgba(19, 179, 136)',
  'rgba(0, 147, 220)',
  'rgba(206, 182, 0)',
  'rgba(255, 36, 171)',
  'rgba(255, 130, 62)',
  'rgba(127, 100, 239)',
  'rgba(19, 179, 136)',
  'rgba(0, 147, 220)',
  'rgba(206, 182, 0)',
  'rgba(255, 36, 171)',
  'rgba(255, 130, 62)',
  'rgba(127, 100, 239)'

];

@Component({
  templateUrl: './reviewSummary.component.html',
  styleUrls: ['./reviewSummary.scss', '../list.scss']
})
export class ReviewSummaryComponent implements OnInit {

  loadingBacklog = false;
  loadingCohorts = false;
  loadingSamplings = false;
  loadingGlobalSearchResult = false;
  searching = false;
  searchCriteria = {
    uniqueName: ''
  };
  uniqueNameResults;
  data;
  ReviewStage = ReviewStage;
  PerformanceIndicator = PerformanceIndicator;
  cohortId;



  reviewCohorts = [];
  backlogItems = [];
  totalSubmissions;
  CohortStatus = CohortStatus;
  panel;
  samplings;
  samplingsList;

  samplingRenewalDate;
  allocationRenewalDate;

  ReviewView = ReviewView;

  constructor(private router: Router, public layout: LayoutService, private service: ReviewService,
    private tooltip: TooltipService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.load();
  }

  openReview(row) {
    this.router.navigate(['./revalidation/review', row.id], {
      queryParams: { stage: 'qa', cohortId: this.cohortId, globalSearch: true }
    });
  }

  togglePanel(panel) {
    if (this.panel === panel) {
      this.panel = undefined;
    } else {
      this.panel = panel;
    }
  }
  get cohortStatusIcon() {
    return 'fa-clock-o';
  }
  /* showQaCohort(row, filter = {}) {
     this.router.navigate(['/revalidation/review/qaCohort', row.cohortId]);
   }
 
   showExternalReviewCohort(row, filter = {}) {
     this.router.navigate(['/revalidation/review/externalReviewCohort', row.cohortId]);
   }*/
  showQaCohort(row, filter: any = {}) {
    if ((filter.excludedFromPublish && !!row.excludedFromPublish) ||
      (filter.reviewView === 2 && !!row.inQualityAssuranceStage) ||
      (filter.reviewView === 3 && !!row.qualityAssuranceCompleted) ||
      (filter.reviewView === 4 && !!row.qualityAssuranceManagerCompleted) ||
      (filter.reviewView === 5 && !!row.coreCriteriaNotMet) ||
      (filter.reviewView === 6 && !!row.published) ||
      row.inQualityAssuranceStage) {
      this.router.navigate(['/revalidation/review/qaCohort', row.revalidationReviewCohortId], { queryParams: filter });
    }

  }

  showExternalReviewCohort(row, filter = {}) {
    if (row.status !== CohortStatus.PendingCommit && !!row.inExternalReviewStage) {
      this.router.navigate(['/revalidation/review/externalReviewCohort',
        row.revalidationReviewCohortId]);
    }

  }

  toggleSearch() {
    this.searching = !this.searching;
  }

  load() {

    this.loadCohortSummary();
    this.loadBacklogSummary();
    this.loadSamplings();
  }

  loadSamplings() {

    this.loadingSamplings = true;
    this.service.getSamplings().subscribe(data => {
      const unselected = data.filter(item => !item.revalidationReviewSamplingId);
      this.samplingsList =  data.filter(item => !item.revalidationReviewSamplingId);
      const highest = unselected.reduce((acc, item) => {
        if (item.revalidationCount > acc) {
          acc = item.revalidationCount;
        }
        return acc;
      }, 0);
      this.samplings = unselected.map((item, i) => {
        item.width = Math.round(item.revalidationCount * 100 / highest) + '%';
        item.opacity = (unselected.length - i + 5) / unselected.length;
        return item;
      });
      this.loadingSamplings = false;
    });

  }


  loadCohortSummary() {
    this.loadingCohorts = true;
    this.service.getCohortSummary().subscribe(data => {
      this.reviewCohorts = data.map(row => {
        row.managerTenPercent = Math.ceil((row.total - row.excludedFromReview) / 10);

        row.publishable = row.total > 0 &&
          row.qualityAssuranceManagerApproved >= row.managerTenPercent &&
          (row.total - row.excludedFromReview <= row.qualityAssuranceManagerApproved + row.qualityAssurerApproved) &&
          (row.published + row.excludedFromPublish < row.total);

        row.completed = (row.published === row.total);
        row.pending = row.status === CohortStatus.PendingCommit;
        return row;
      }).sort((a, b) => {

        if (a.completed && !b.completed) {
          return 1;
        }
        if (new Date(a.reviewDeadline) > new Date(b.reviewDeadline)) { return 1; }
        return -1;
      });
      this.loadingCohorts = false;
    });

  }

  publish(row) {
    row.publishing = true;
    this.service.publishFeedback(row.revalidationReviewCohortId).subscribe(() => {
      row.publishing = false;
    }, error => {
      row.publishing = false;
    });
  }

  loadBacklogSummary() {
    this.loadingBacklog = true;
    this.service.getBacklogSummary().subscribe(data => {
      this.renderBacklog(data);
      this.loadingBacklog = false;
    });
  }

  cohortCreated() {
    this.panel = undefined;
    this.service.getBacklogSummary().subscribe(data => {
      this.renderBacklog(data);
    });
    this.service.getCohortSummary().subscribe(data => {
      this.reviewCohorts = data;
    });
  }

  openPendingCommit(row) {
    if (row.status === CohortStatus.PendingCommit) {
      this.router.navigate(['/revalidation/review/externalReviewCohort',
        row.revalidationReviewCohortId], { queryParams: { pendingCommit: true } });
    }

  }

  openAllocation(item) {
    this.allocationRenewalDate = item.renewalDate;
    this.panel = 'cohort';
  }
  createSampling(item) {
    this.samplingRenewalDate = item.renewalDate;
    this.panel = 'sampling';
  }

  samplingCreated() {
    this.panel = undefined;
    this.samplingRenewalDate = undefined;
    this.service.getBacklogSummary().subscribe(data => {
      this.renderBacklog(data);
    });
  }

  openBacklog(renewalDate) {
    this.router.navigate(['/revalidation/review/backlog'], { queryParams: { renewalDate } });
  }
  renderBacklog(data) {

    this.totalSubmissions = data.reduce((acc, item) => {
      return acc + item.revalidationSubmissionCount;
    }, 0);

    const highest = data.reduce((acc, item) => {
      return item.revalidationSubmissionCount > acc ? item.revalidationSubmissionCount : acc;
    }, 0);

    this.backlogItems = data.map((chunk, i) => {
      return Object.assign(chunk, {
        proportionalWidth: ((chunk.revalidationSubmissionCount * 100) / this.totalSubmissions) + '%',
        width: ((chunk.revalidationSubmissionCount * 100) / highest) + '%',
        colour: colours[i],
        opacity: (data.length - i + 5) / data.length,
        tooltip: {
          id: 'backlog-' + i,
          content: `
          <h5 >Renewal cohort ${this.datePipe.transform(chunk.renewalDate, 'dd/MM/yyyy')}</h5>
          <table class="table text-left mb-0">
          <tr><td>Selected submissions</td><td class="text-right">${chunk.revalidationSubmissionCount}</td></tr>
          <tr><td>In remediation</td><td class="text-right">${chunk.inRemediationThisYear}</td></tr>
          <tr><td>Flagged manually</td><td class="text-right">${chunk.manuallyFlagged}</td></tr>
          <tr><td>Randomly selected</td><td class="text-right">${chunk.random}</td></tr>
          </table>
          `,
          showOnHover: true,
          solo: true,
          width: 290,
          placement: 'top',
          order: i,
          info: true,
          class: 'info'
        }
      });
      /* {
"renewalDate": <renewal date of this sampling>,
"revalidationSubmissionCount": <total count in this sampling>,
"fullYearAfterReturnToPractice": <counts of this sampling type (RP2-36)>,
"afterRestorationDateThreshold": <counts of this sampling type (RP2-36)>,
"ftPCommitteeRestorationRoute": <counts of this sampling type (RP2-36)>,
"historicalQualityIssues": <counts of this sampling type (RP2-36)>,
"inRemediationThisYear": <counts of this sampling type (RP2-36)>,
"inRemediationLastYear": <counts of this sampling type (RP2-36)>,
"grantedExtensionLastYear": <counts of this sampling type (RP2-36)>,
"manuallyFlagged": <count of manually selected>,
"random": <count of randomly selected>
}*/
    });

  }


  search() {
    this.loadingGlobalSearchResult = true;
    this.service.searchUniqueName(this.searchCriteria.uniqueName).subscribe(uniqueReview => {
      this.uniqueNameResults = true;
      this.cohortId = uniqueReview.cohortId;
      this.data = [uniqueReview];
      this.loadingGlobalSearchResult = false;
    }, error => {
      this.uniqueNameResults = true;
      this.loadingGlobalSearchResult = false;
    }
    );
  }

  clearSearch() {
    this.searchCriteria.uniqueName = '';
    this.uniqueNameResults = false;
  }



}
