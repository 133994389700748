import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { Review } from '../../model/Review';
import { CpdEntry } from '../../model/CpdEntry';
import { RevalidationItemType } from '../../model/RevalidationItemType';
import { revalidationItemTypes } from '../../model/revalidationItemTypes';
import { RevalidationItemTypeConfig } from '../../model/RevalidationItemTypeConfig';
import { ReviewStage } from '../../model/ReviewStage';
declare const require;
const cloneDeep = require('lodash.clonedeep');

@Component({
  selector: 'app-feedback-sections',
  templateUrl: './feedbackSections.component.html',
  styleUrls: ['./feedbackSections.scss']
})
export class FeedbackSectionsComponent implements OnInit {

  @Input() review: Review;
  @Output() onSelected = new EventEmitter<RevalidationItemTypeConfig>();

  sections: any[];
  selected: RevalidationItemTypeConfig;
  editing = false;

  ngOnInit() {
    if (!this.review.feedback) {
      this.review.feedback = {};
    }
    if (!this.review.feedbackEdit) {
      this.review.feedbackEdit = cloneDeep(this.review.feedback);
    }
    const types = this.review.entries.reduce(
      (acc: Array<RevalidationItemType>, item: CpdEntry) => {
        if (acc.indexOf(item.type) === -1) {
          acc.push(item.type);
        }
        return acc;
      }, []);
    const sections = types.map(t => {
      const section: any = revalidationItemTypes.filter(r => r.type === t)[0];
      section.isFeedback = true;
      section.editing = false;
      return section;
    });

    const assessmentSection = { isAssessment: true, title: 'Final assessment', feedbackType: 'coreCriteriaFeedback', editing: false };
    if (this.review.stage === ReviewStage.QualityAssuranceCompleted || this.review.stage === ReviewStage.CoreCriteriaNotMet) {
      sections.unshift(assessmentSection);
    } else {
      sections.push(assessmentSection);
    }

    this.sections = sections;
    this.select(this.sections[0]);
  }

  toggleEdit(section) {
    section.editing = !section.editing;
    if (section.editing) {
      this.selected = section;
    }
  }

  edited(section) {
    if (!this.review.feedback[section.feedbackType]) {
      return false;
    }
    return (this.review.feedback[section.feedbackType].replace('<br>', '') !==
    this.review.feedbackEdit[section.feedbackType].replace('<br>', ''));
  }

  undo(section) {
    this.review.feedbackEdit[section.feedbackType] = this.review.feedback[section.feedbackType];
    this.checkHasEdits();
  }

  checkHasEdits() {
    this.review.hasEdits = false;
    this.sections.forEach(section => {

      if (this.review.feedback[section.feedbackType] !== this.review.feedbackEdit[section.feedbackType]) {
        this.review.hasEdits = true;
      }
    });
    if (this.review.feedback.performanceIndicator !== this.review.feedbackEdit.performanceIndicator) {
      this.review.hasEdits = true;
    }
  }

  update(content) {
    this.review.feedbackEdit[this.selected.feedbackType] = content;
    this.checkHasEdits();
  }

  helpClick($event, section) {
    section.helpVisible = !section.helpVisible;
    $event.stopPropagation();

  }


  select(item: RevalidationItemTypeConfig) {
    if (this.selected === item) {
      this.selected = undefined;
    } else {
      this.selected = item;
    }

    setTimeout(() => {
      this.onSelected.emit(this.selected);
    });
  }




}
