// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.carousel-wrapper {
  overflow: hidden;
}

.carousel {
  display: flex;
  transition: transform 1s;
}

/*
.active-carousel-item {
  transition: width 300ms ease-in-out;
}
*/
.controls {
  pointer-events: none;
  position: absolute;
  top: -75px;
  width: 100%;
}

.controls button {
  pointer-events: all;
  background: transparent;
  border: 0;
  position: absolute;
  top: 25px;
}
.controls button.next {
  right: 50px;
}
.controls button.prev {
  right: 110px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/carousel.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EAGA,aAAA;EAGA,uBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EAGI,aAAA;EACA,wBAAA;AACJ;;AACA;;;;CAAA;AAKA;EACI,oBAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;AAEJ;;AACA;EACI,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,kBAAA;EACA,SAAA;AAEJ;AADI;EACE,WAAA;AAGN;AADI;EACE,YAAA;AAGN","sourcesContent":[".carousel-container {\r\n    position: relative;\r\n    display: -webkit-box;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    -webkit-box-pack: center;\r\n    -ms-flex-pack: center;\r\n    justify-content: center;\r\n}\r\n\r\n.carousel-wrapper {\r\n    overflow: hidden;\r\n}\r\n\r\n.carousel {\r\n    display: -webkit-box;\r\n    display: -ms-flexbox;\r\n    display: flex;\r\n    transition: transform 1s, -webkit-transform 1s;\r\n}\r\n/*\r\n.active-carousel-item {\r\n  transition: width 300ms ease-in-out;\r\n}\r\n*/\r\n.controls {\r\n    pointer-events: none;\r\n    position: absolute;\r\n    top: -75px;\r\n    width: 100%;\r\n}\r\n\r\n.controls button {\r\n    pointer-events: all;\r\n    background: transparent;\r\n    border: 0;\r\n    position:absolute;\r\n    top:25px;\r\n    &.next {\r\n      right:50px;\r\n    }\r\n    &.prev {\r\n      right:110px;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
