import { Component, OnInit } from '@angular/core';
import { AuthService } from './service/auth.service';
import { UserService } from './service/user.service';
import { MatDialog } from '@angular/material/dialog';

interface LoginChangeArgs {
  loggedIn: boolean;
  error?: string;
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['login.scss']
})
export class LoginComponent implements OnInit {

  userId: any;
  loading = false;
  error: any;

  constructor(public authService: AuthService, private dialog: MatDialog, private userService: UserService) { }

  ngOnInit() {

    if (this.authService.loggedIn()) {
      this.userService.getUser().subscribe(user => {
        this.authService.redirect();
      });
    }
  }


  signin() {
    this.authService.login();
  }

}

