import { Component, Input, OnInit } from '@angular/core';
import { AttachmentType } from './model/attachmentType';
import { PreregApplicationService } from './service/prereg.service';
import { environment } from '../../environments/environment';
import { WorkExperience } from './model/WorkExperience';

@Component({
    selector: 'app-assessment-report-application',
    templateUrl: './assessmentReport.component.html',
    styleUrls: ['./application.scss']
})
export class AssessmentReportApplicationComponent implements OnInit {
    @Input() application;
    attachmentType = AttachmentType;
    grade: number;
    name: string;
    submittedAt: any;
    progressReport: any;
    countersignerComment: any;
    confirmedTempRegistration: boolean;
    constructor(
        private service: PreregApplicationService
    ) { }

    ngOnInit() {
        this.grade = this.application.grade;
        this.name = this.application.name;
        this.submittedAt = this.application.submittedDate;
        this.progressReport = this.application.progressReport;
        this.confirmedTempRegistration = this.application.confirmedTempRegistration;
        this.countersignerComment = this.progressReport.countersignerComment;
    }

}

