export enum ReviewSelectionStatus {

    /// <summary>

    /// This hasn't been selected for Review.

    /// </summary>

    None = 0,

    /// <summary>

    /// One of the last two years this Registrant was reviewed and got a

    /// PerformanceIndicator.AdequateEngagement or

    /// PerformanceIndicator.SufficientEngagement.

    /// </summary>

    ExemptDueToAdequatePerformanceIndicatorOnHistoricalReviews = 1,

    /// <summary>

    /// This Revalidation is exempt from Review this year since this Registrant is

    /// UnderInvestigation.

    /// </summary>

    ExemptDueToUnderInvestigation = 2,

    /// <summary>

    /// This Revalidation is exempt from Review this year since this Registrant's

    /// RegistrationStatus.Suspended.

    /// </summary>

    ExemptDueToSuspension = 3,

    /// <summary>

    /// This Registrant's Revalidation hasn't got the

    /// SubmissionStatus.FullRecordReceived.

    /// </summary>

    ExemptDueToLackOfFullSubmission = 4,

    /// <summary>

    /// This Registrant's Revalidation has got an extension and will therefore be

    /// Exempt from Reviews this year, but will automatically be selected for next

    /// year.

    /// </summary>

    ExemptDueToExtension = 5,

   

    /// MAKE SURE ALL INCLUDING ReviewSelectionStatuses ARE > Crm. WHEN SELECTING

    /// THE REVALIDATIONS THAT'S UP FOR REVIEW A CHECK THAT THE ReviewSelectionStatus

    /// IS GREATER THAN ReviewSelectionStatus.Crm.



    /// <summary>

    /// Registrants who have completed a full year since re-joining the register

    /// under the "Return to Practise" route during the year. The CRM is responsible

    /// for flagging these up.

    /// </summary>

    FullYearAfterReturnToPractice = 10,

    /// <summary>

    /// Registrants renewing for the first time after a minimum of 16 months have

    /// elapsed since the CRM "Restoration Date". The CRM is responsible for flagging

    /// these up.

    /// </summary>

    AfterRestorationDateThreshold = 11,

    /// <summary>

    /// Registrants who are restoring under the FtP Committee Restoration Route.

    /// The CRM is responsible for flagging these up.

    /// </summary>

    FtPCommitteeRestorationRoute = 12,



    /// <summary>

    /// PerformanceIndicator for last year contained FailedCoreCriteria.

    /// </summary>

    HistoricalQualityIssues = 20,

    /// <summary>

    /// If they failed to submit on time this year, but made a full submission,

    /// they will get automatically selected for review.

    /// </summary>

    InRemediationThisYear = 21,

    /// <summary>

    /// If they failed to submit on time last year, this year they will get

    /// automatically selected for review.

    /// </summary>

    InRemediationLastYear = 22,

    /// <summary>

    /// If they filed for a Extension last year due to Exceptional Circumstances they

    /// will automatically be reviewed this year.

    /// </summary>

    GrantedExtensionLastYear = 23,

    /// <summary>

    /// Revalidation Manually flagged for Review.

    /// </summary>

    ManuallyFlagged = 99,

    /// <summary>

    /// The unlucky 2.5% that did everything by the book but still got screwed by the system.

    /// </summary>

    Random = 100

}