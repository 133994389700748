import { NgModule, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { CustomErrorHandler } from './core/service/CustomErrorHandler';
import { AppRoutingModule } from './app-routing.module';
import { AccountsModule } from './accounts/accounts.module';
import { RevalidationModule } from './revalidation/revalidation.module';
import { PreregModule } from './prereg/prereg.module';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalRedirectComponent } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './app-config';

import 'hammerjs';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AccountsModule,
    RevalidationModule,
    AppRoutingModule,
    CoreModule,
    PreregModule,
    MsalModule
  ],
  bootstrap: [ AppComponent, MsalRedirectComponent ],
  providers: [
  { provide: ErrorHandler, useClass: CustomErrorHandler },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  },
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory
  },
  MsalService,
  MsalGuard,
  MsalBroadcastService
  ]
})
export class AppModule {}

