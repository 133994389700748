export enum ReviewStage {
  Unknown = 0,
  Reviewing = 1,
  Discussion = 2,
  WritingFeedback = 3,
  AwaitingFeedbackApprovalByPeer = 4,
  AwaitingFeedbackApprovalByQualityAssurer = 5,
  QualityAssuranceCompleted = 7,
  CoreCriteriaNotMet = 99,
  FeedbackSent = 100
}
