export enum ExtenuatingCircumstanceReviewDecision {
  Unknown = 0,
  Pending = 1,
  Approved = 2,



  /// <summary>
  /// Approved with modifications; might have rejected some of the reductions,
  /// or changed the Revalidation Deadline in the EC.
  /// </summary>
  PartiallyApproved = 3,

  // https://mygphc.atlassian.net/browse/MAE-15
  NoLongerRequired = 4,



  // https://mygphc.atlassian.net/browse/RP2-2
  AwaitingInfo = 998,



  /// <summary>
  /// Rejected by the reviewer.
  /// </summary>
  Rejected = 999
}