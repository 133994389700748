
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FormTemplate } from '../../dynamic/model/FormTemplate';
import { FormType } from '../../dynamic/model/FormType';
import { ServiceBase } from './service.base';
import { AuthService } from './auth.service';
import { LogService } from './log.service';
import { CustomErrorHandler } from './CustomErrorHandler';



import { HttpClient } from '@angular/common/http';


@Injectable()
export class DynamicService extends ServiceBase {
  forms: any = {};
  templateGroup;

  constructor(http: HttpClient, auth: AuthService, log: LogService, errorHandler: CustomErrorHandler) {
    super(http, auth, log, errorHandler);
  }

  getFormTemplateById(id: string): Observable<FormTemplate> {
   // if (this.forms[id]) {
   //   return Observable.of(Object.assign({}, this.forms[id]));
   // } else {
      return super.get(`v1.0/forms/${id}`).pipe(
        map(data => {
          const form: FormTemplate = <FormTemplate>data[0];
          this.forms[form.dynamicFormId] = form;
          return form;
        }));
   // }
  }

}
