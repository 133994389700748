import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../core/service/layout.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ReviewService } from '../service/review.service';
import { CohortStatus } from '../model/CohortStatus';
import { DatePipe } from '@angular/common';
import { ReviewStage } from '../model/ReviewStage';
import { PerformanceIndicator } from '../model/PerformanceIndicator';
import { CaseSplitPipe } from '../../shared/pipe/CaseSplit.pipe';
import { PagerComponent } from '../../shared/pager.component';
import { ReviewSelectionStatus } from '../model/ReviewSelectionStatus';
import { RegistrantStatus } from '../../accounts/model/RegistrantStatus';
import { RegistrantType } from '../../accounts/model/RegistrantType';

@Component({
  templateUrl: './externalReviewCohort.component.html',
  styleUrls: ['../list.scss', './review.scss']
})
export class ExternalReviewCohortComponent implements OnInit, AfterViewInit {

  loading = false;
  changingReviewers = false;
  initialisingCohort = false;
  data = [];
  CohortStatus = CohortStatus;
  cohortId;
  randomSamplePercent = 2.5;
  ReviewStage = ReviewStage;
  cohort: any = {};
  PerformanceIndicator = PerformanceIndicator;
  filterParams;
  activeFilterParams;
  activeFilterParamsInfo = [];
  RegistrationStatus = RegistrantStatus;
  RegistrantType = RegistrantType;

  filterReviewSelectionStatuses = [];
  panel;
  allowChangeReviewers;
  excluding = false;
  including = false;
  commiting = false;
  reviewerValidationMessage = '';
  @ViewChild(PagerComponent,) pager: PagerComponent;
  reviewView = 1;
  reviewers = [];

  editing: {
    id?;
    lead?;
    secondary?;
    row?;
    isValid?;
    validationMessage?;
  } = {};


  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public layout: LayoutService, public service: ReviewService, private datePipe: DatePipe, private caseSplitPipe: CaseSplitPipe) {
    this.cohortId = activatedRoute.snapshot.params['cohortId'];
    this.allowChangeReviewers = activatedRoute.snapshot.queryParams['pendingCommit'];
    this.filterParams = this.emptyFilterParams();
    this.activeFilterParams = this.emptyFilterParams();

  }




  exclude() {
    this.excluding = true;
    this.service.excludeFromBatch(this.cohortId, this.activeFilterParams).subscribe(() => {
      this.excluding = false;
      this.load();
    }, error => {
      this.excluding = false;
    });
  }

  include() {
    this.including = true;
    this.service.includeInBatch(this.cohortId, this.activeFilterParams).subscribe(() => {
      this.including = false;
      this.load();
    }, error => {
      this.including = false;
    });
  }



  emptyFilterParams() {
    return {
      reviewStage: undefined,
      reviewer: undefined,
      maxDaysLate: undefined,
      renewalDate: undefined,
      inRemediationThisYear: undefined,
      reviewSelectionStatus: undefined,
      registrationNumber: undefined,
      registrationStatus: undefined
    };
  }

  get heading() {

    if (this.allowChangeReviewers) {
      return 'Reviews pending commit';
    }
    return 'Review';

  }
  get subHeading() {
    if (this.allowChangeReviewers) {
      return `When you are happy with the reviewer assignments for this batch,
    click 'commit' to send to the reviewers`;
    }
    return 'Revalidation submissions undergoing review';


  }

  openReview(row) {
    this.router.navigate(['./revalidation/review', row.id], {
      queryParams: { stage: 'externalReview', cohortId: this.cohortId }
    });
  }

  togglePanel(panel) {
    if (this.panel === panel) {
      this.panel = undefined;
    } else {
      this.panel = panel;
    }
  }


  toggleEdit(row) {
    if (row.id === this.editing.id) {
      this.editing = { id: undefined, lead: undefined, secondary: undefined, row: undefined };
    } else {
      this.editing = {
        row,
        id: row.id,
        lead: row.leadReviewerUserId,
        secondary: row.secondaryReviewerUserId,
        isValid: true
      };
    }
  }

  cancelEdit() {
    this.editing = { id: undefined, lead: undefined, secondary: undefined, row: undefined };
  }


  returnToSummary() {
    this.router.navigate(['./revalidation/review']);
  }

  commit() {

    this.commiting = true;
    this.service.commitCohort(this.cohortId).subscribe(() => {
      this.commiting = false;
      this.load();
    });
  }


  getSelectionStatuses() {
    const out = [];
    for (const enumMember in ReviewSelectionStatus) {
      if (!isNaN(parseInt(enumMember, 10))) {
        if (ReviewSelectionStatus[enumMember] !== 'Unknown' && ReviewSelectionStatus[enumMember].toLowerCase().indexOf('exempt') === -1) {
          out.push({
            key: enumMember,
            value: ReviewSelectionStatus[enumMember]
          });
        }
      }
    }
    return out;
  }

  ngOnInit() {
    this.service.getReviewers().subscribe(data => {
      this.reviewers = data.map(reviewer => {

        reviewer.type = reviewer.registrantType ? (reviewer.registrantType === RegistrantType.Pharmacist ? 'Pharm' : 'Tech') : 'Lay';
        return reviewer;
      });

    });
    this.filterReviewSelectionStatuses = this.getSelectionStatuses();

  }

  load() {
    this.pager.load(Object.assign({ id: this.cohortId, reviewView: this.reviewView }, this.filterParams));
  }

  ngAfterViewInit() {
    this.load();
  }

  clearAllFilters() {
    this.filterParams = {
      reviewStage: undefined,
      reviewer: undefined,
      maxDaysLate: undefined,
      renewalDate: undefined,
      inRemediationThisYear: undefined,
      reviewSelectionStatus: undefined,
      registrationNumber: undefined,
      registrationStatus: undefined
    };
    if (this.hasActiveFilterParams) {
      this.load();
    }
  }

  clearFilter(filter) {
    this.filterParams[filter] = undefined;
    if (this.hasActiveFilterParams) {
      this.load();
    }
  }

  get filterParamsAreActive() {
    if (!this.hasActiveFilterParams) {
      return false;
    }
    for (const key in this.filterParams) {
      if (this.filterParams.hasOwnProperty(key)) {
        if (this.filterParams[key] !== undefined && this.activeFilterParams[key] === undefined) {
          return false;
        }
        if (this.activeFilterParams[key] !== undefined && this.filterParams[key] === undefined) {
          return false;
        }
        if (this.activeFilterParams[key] !== this.filterParams[key]) {
          return false;
        }
      }
    }
    return true;
  }

  get hasActiveFilterParams() {
    for (const key in this.activeFilterParams) {
      if (this.activeFilterParams[key] !== undefined && this.activeFilterParams[key] !== '') {
        return true;
      }
    }
    return false;
  }

  get hasFilterParams() {
    for (const key in this.filterParams) {
      if (this.filterParams[key] !== undefined && this.filterParams[key] !== '') {
        return true;
      }
    }
    return false;
  }

  onLoad(data) {
    this.cohort = data.cohortStatistics;
    this.allowChangeReviewers = this.cohort.status === CohortStatus.PendingCommit;
    this.data = data.reviews;
    this.setActiveFilterParams();
  }

  setActiveFilterParams() {
    const params: any = Object.assign({}, this.filterParams);
    delete params.take;
    delete params.skip;
    this.activeFilterParams = params;


    const formatParamValue = (key, value) => {
      switch (key) {
        case 'reviewStage':
          return this.caseSplitPipe.transform(ReviewStage[value]);
        case 'reviewer':
          return this.reviewers.find(reviewer => reviewer.userId === value).name;
        case 'reviewSelectionStatus':
          return this.caseSplitPipe.transform(ReviewSelectionStatus[value]);
        case 'registrationStatus':
          return this.RegistrationStatus[value];
        case 'renewalDate':
          return 'Renewal date: ' + this.datePipe.transform(value, 'dd/MM/yyyy');
        case 'inRemediationThisYear':
          return value ? 'In remediation' : 'Not in remediation';
        case 'maxDaysLate':
          return 'Up to ' + value + ' days late';
        default:
          return this.caseSplitPipe.transform(key) + ': ' + value;
      }
    };

    if (this.data.length) {
      this.panel = undefined;
    }

    this.activeFilterParamsInfo = Object.keys(this.activeFilterParams).map(key => {
      if (this.activeFilterParams[key] !== undefined) {
        return { key, value: formatParamValue(key, this.activeFilterParams[key]) };
      }
      return false;
    }).filter(v => !!v);
  }



}
