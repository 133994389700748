import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'caseSplit' })
export class CaseSplitPipe implements PipeTransform {
  public transform(input: string): string {
    if (!input) {
      return '';
    } else {
      const val = input.split(/(?=[A-Z])/).join(' ');
      return val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
    }
  }
}
