import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ServiceBase } from '../../core/service/service.base';

import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../core/service/auth.service';
import { LogService } from '../../core/service/log.service';
import { CustomErrorHandler } from '../../core/service/CustomErrorHandler';
import { AssessmentRegistration } from '../model/AssessmentRegistration';
import { FinalDeclaration } from '../model/FinalDeclaration';
import { IndependentPrescriber } from '../model/IndependentPrescriber';
import { VRApplication } from '../model/VRApplication';

const mockApplication = { 
    courseProvider: 'ABC pharma',
    submittedAt: '30/08/2021',
    dateAwarded: '30/08/2020',
    clinicalSpecialities: 'Test ASDF',
    isPrescriberRegistered: false,
    prescriberMentorName: 'Mr Test Tutor 1',
    registrationNumber: 1234567,
    UKregulatoryBody: 'General Pharmaceutical Council (Pharmacists)', 
    supportingDocuments: [
        {
            type: 1234,
            expiryDate: 24-6-2022,
            title: 'ms',
            filename:'imgFileName',
            filesize: 30,
            url: '/assets/images/rings-small.png',
          }
    ],
    activeForm: {
        step: 1,
        declaration: {
            isQ1Confirmed: true,
            isQ2Confirmed: false,
            isQ3Confirmed: false,
            isQ4Confirmed: true,
            isQ5Confirmed: false
          }
        
    },
    mentorDetails: {
       0 :
        {
            "tutorGPhCId": "b1875ac5-7aaf-e411-80e6-005056851bfe",
            "name": "Mr Test Tutor 1",
            "registrationNumber": "2044541",
            "startDate": "2019-09-19T00:00:00",
            "endDate": "2020-09-16T00:00:00"
        }
    }
,
    forms: [
        {
            "countersignatures": [],
            "id": "xyz",
            "formStatus": 4,
            "step": 1,
            "declaration":{
            "isQ1Confirmed": true,
            "isQ2Confirmed": false,
            "isQ3Confirmed": false,
            "isQ4Confirmed": true,
            "isQ5Confirmed": false
            }
        }
    ],
    registrant:{
        title: 'mrs',
        forenames: 'Test',
        surname: 'prescriberQ'
    },
    applicationFee: 250
};
@Injectable()
export class PreregApplicationService extends ServiceBase {

    constructor(
        http: HttpClient,
        auth: AuthService,
        log: LogService,
        errorHandler: CustomErrorHandler
    ) {
        super(http, auth, log, errorHandler);
    }

    public getRegApplication(preregNumber): Observable<any> {
        return this.get(`/v1.0/registrationdocument?PreEntryNumber=${preregNumber}`);
    }

    public getPreregApplication(preregNumber): Observable<any> {
        return this.get(`/v1.0/preregdocument?PreEntryNumber=${preregNumber}`);
    }

    public getTechnicianApplication(preentryNumber): Observable<any> {
        return this.get(`v1.0/techniciandocument?PreEntryNumber=${preentryNumber}`);
    }

    public getAssessmentApplication(preentryNumber: string): Observable<any> {
        // return this.get(`v1.0/admin/progressreport/thirtynineweek?PreEntryNumber=4300712`);
        return this.get(`v1.0/admin/progressreport/thirtynineweek?PreEntryNumber=${preentryNumber}`);
    }
    public getExamApplication(preentryNumber): Observable<AssessmentRegistration> {
        return this.get(`v1.0/admin/assessmentregistration?PreEntryNumber=${preentryNumber}`);

    }

    public getFinalDeclarationApplication(preentryNumber): Observable<FinalDeclaration> {
        return this.get(`v1.0/admin/finaldeclaration?preentrynumber=${preentryNumber}`);

    }

    public getPrescriberApplication(registrationNumber){     
      return this.get(`v1.0/admin/independentprescriber?registrationNumber=${registrationNumber}`);

    }

    public getVRApplication(registrationNumber): Observable<VRApplication>{
        return this.get(`v1.0/admin/voluntaryremoval?RegistrationNumber=${registrationNumber}`);
    }

    public deleteVRApplication(registrationNumber): Observable<any>{
        return this.post(`v1.0/admin/voluntaryremoval`,{RegistrationNumber: registrationNumber});
    }

    public getRTRApplication(registrationNumber): Observable<any>{
        return this.get(`v1.0/admin/returntoregister?registrationnumber=${registrationNumber}`);
    }

    public getCCPSApplication(registrationNumber): Observable<any>{
        return this.get(`v1.0/admin/professionalstandingapp?registrationnumber=${registrationNumber}`);
    }

    download(url: string) {
        return this.getFile(url);
    }
}
