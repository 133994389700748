import { CpdEntry } from './CpdEntry';
import { Feedback } from './Feedback';
import { ReviewStage } from './ReviewStage';
import { FeedbackConfirmation } from './FeedbackConfirmation';
import { PerformanceIndicator } from './PerformanceIndicator';
import { Message } from './Message';

export class Review  {
  id: string;
  submissionDate: string;
  reviewerConfirmation: FeedbackConfirmation;
  stage: ReviewStage;
  title: string;
  discussionStatus;
  isFeedbackEditable: boolean;
  isLeadReviewer: boolean;
  messages: Array<Message>;
  entries: CpdEntry[];
  feedback: Feedback;
  feedbacks: Array<Feedback> = [];
  feedbackEdit: Feedback;
  leadReviewerAssessment: PerformanceIndicator;
  reviewerAssessment: PerformanceIndicator;
  stageAsString: string;
  hasEdits = false;
  excludedFromPublish: boolean;
  currentUserIsFeedbackAuthor: boolean;
  inRemediation: boolean;

  assignedAt;
  assignedTo;

  constructor(data) {
    Object.assign(this, data);
    if (!this.feedbacks[0]) {
      this.feedback = { };
    } else {
      this.feedback = this.feedbacks[0];
    }
    if (!this.feedback.feedbackApprovals) {
      this.feedback.feedbackApprovals = [];
    }

  }
  isCoreCriteriaFeedbackRequired() {
    return +this.feedback.performanceIndicator === +PerformanceIndicator.CoreCriteriaNotMet ||
      +this.feedback.performanceIndicator === +PerformanceIndicator.ReviewNextYear;
  }

  getIsCompleted() {
    return false;
  }

  getTotal() {
    switch (this.stage) {
      case ReviewStage.Reviewing:
        // an annotation for each entry
        // currentReviewerAssessment
        return this.entries.length + 1;
      case ReviewStage.WritingFeedback:
        // a feedback entry for each section
        // final assessment
        return this.isLeadReviewer ? 5 : 0;
    }
  }


}
