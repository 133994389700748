import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Address } from '../accounts/model/Address';
import { RegistrantStatus } from '../accounts/model/RegistrantStatus';
import { LogService } from '../core/service/log.service';
import { EnglishQualificationType, RegistrationRoute, RestorationDeclarationQuestion, ReturnToRegisterDetails } from './model/ReturnToRegisterDetails';
import { PreregApplicationService } from './service/prereg.service';

@Component({
  selector: 'app-return-to-register-application',
  templateUrl: './returnToRegisterApplication.component.html',
  styleUrls: ['./application.scss']
})
export class ReturnToRegisterComponent implements OnInit {

  @Input()application:ReturnToRegisterDetails; 
  englishCertificateOption: string;
  registrationStatus: string;
  Q10:RestorationDeclarationQuestion;
  Q11:RestorationDeclarationQuestion;
  q8Title="Question 8"
  q9Title="Question 9"
  registration: any;
  registrationNumber: any;
  Q10Address: string;
  Q11Address: string;  
  requestedTittle:string;
  requestedFirstName:string;
  requestedMiddleName:string;
  requestedLastName:string;

  constructor(private dialog: MatDialog,
    private log: LogService,
    private preregService: PreregApplicationService) { }

    get fullName(){
      let first,middle,last;
      first=this.application.personalDetails.forenames!=null?this.application.personalDetails.forenames:"";
      middle=this.application.personalDetails.middleName!=null?this.application.personalDetails.middleName:"";
      last=this.application.personalDetails.surname!=null?this.application.personalDetails.surname:"";
      if(first=="" && middle=="" && last=="" ){
        return "-";
      }
      return first +" "+middle +" "+last;
     }
   
  ngOnInit(): void {      
    this.application.form.attachments.forEach((doc:any)=>{
      doc.url=doc.downloadUrl;// adding url proprty to attachments array
    });
    this.englishCertificateOption=EnglishQualificationType[this.application?.form?.returnToRegisterDetail?.englishCertificateOption];  
    this.registration=this.application.personalDetails.registration;
    if(this.registration){
     this.registrationNumber = this.registration.registrationNumber;      
     this.registrationStatus =RegistrantStatus[this.registration.registrationStatus] || "-";
    }
   this.application.form.restorationDeclarations.forEach(element => {
    if(element.questionName=="Q10"){
        this.Q10=element;     
        this.Q10Address=element.employerAddress!=null?this.toString(element.employerAddress):"-";       
    }
    else{
        this.Q11=element;
        this.Q11Address=element.employerAddress!=null?this.toString(element.employerAddress):"-";
    }
   
      this.requestedTittle=this.application?.form?.returnToRegisterDetail?.title?.name;
      this.requestedFirstName=this.application?.form?.returnToRegisterDetail?.forenames;
      this.requestedMiddleName=this.application?.form?.returnToRegisterDetail?.middleName;
      this.requestedLastName=this.application?.form?.returnToRegisterDetail?.surname;
   });
  }

  getObjectLength(obj) {
    return Object.keys(obj).length;
  }
  private concat(item: string) {
    return item ? item + ', ' : '';
  }
  
  toString(address) {
    return this.concat(address.line1) +
    this.concat(address.line2) +
    this.concat(address.line3) +
    this.concat(address.town) +
    this.concat(address.county) +
    address.country + ' ' +
    address.postcode;

  }

  get isRouteEEA(): boolean {
    return this.registration.registrationRoute === RegistrationRoute.EEA;
  }
}