import { Address } from "../../../app/accounts/model/Address";
import { AnswerType } from "../../../app/dynamic/model/AnswerType";

export interface ReturnToRegisterDetails {
  form: {
    isOverallDeclarationAcknowledged: boolean;
    returnToRegisterDetail: {
      confirmUserNameChange: boolean;
      title: {
        name: string;
        id: string;
      };
      forenames: string;
      surname: string;
      middleName: string;
      englishCertificateOption: string;
      oetCandidateNo: string;
      confirmAccessOETPortal: string;
      hasConfirmedRevalidationSubmission: boolean;
    };
    letterOfGoodStanding: {
      regulatoryBody: string;
      registrationNumber: string;
      isRequested: string;
      hasRegistered: boolean;
    };
    appDeclaration: {
      isQ1Confirmed: boolean;
      isQ2Confirmed: boolean;
      isQ3Confirmed: boolean;
    };
    equalityDiversity: {
      ethnicity: number;
      ethnicityOther: string;
      nationality: string;
      religion: number;
      religionOther: string;
      disabled: number;
      disabilityDetails: string;
      gender: number;
      sexualOrientation: number;
    };
    declarations: [
      {
        dynamicFormId: string;
        answers: [
          {
            questionId: string;
            answer: string;
            answerText: string;
          }
        ];
      }
    ];
    restorationDeclarations: RestorationDeclarationQuestion[];
    isRestorationFeePaymentAvailable: boolean;
    id: string;
    formStatus: number;
    step: number;
    scope: number;
    attachments: [];
    countersignatures: [];
    createdAt: string;
    dateApplicationSubmitted: string;
  };
  personalDetails: {
    title: {
      name: string;
      id: string;
    };
    forenames: string;
    surname: string;
    middleName: string;
    dateOfBirth: string;
    address: {
      line1: string;
      line2: string;
      line3: string;
      town: string;
      county: string;
      postcode: string;
      country: string;
      homeNation: number;
      latitude: string;
      longitude: string;
      countryCode: string;
    };
    contact: {
      email: string;
      telephone1: string;
      mobilePhone: string;
    };
    registration: {
      registrationNumber: string;
      registrationStatus: number;
      independentPrescriberStatus: number;
      voluntaryRemovalReason: string;
      registrationRoute: string;
      contactType: number;
      isRequiredEnglishCertificate: boolean;
      isRequiredRevalidationSubmission: boolean;
    };
  };
  ftpQuestions: [
    { type: AnswerType; title: string; answer: string; body: string }
  ];
}

export interface RestorationDeclarationQuestion{
  questionName: string;
  isRegistered: boolean;
  isInvestigated: boolean;
  caseReferenceNo: string;
  investigationDate: string;
  titleUsed: string;
  employerName: string;
  howWhereUsedIt: string;
  employerAddress: Address;
  titleUsedFrom: string;
  titleUsedUntil: string;
}

export enum EnglishQualificationType {
  IELTS = 1,
  OET,
  EmployerReference,
}

export enum RegistrationRoute
{
    UK = 717750000,
    UKPlusOverseas = 717750001,
    OSPAP = 717750002,
    EEA = 717750003,
    NorthernIreland = 717750004,
    Legacy = 717750005
}